import { createI18n } from 'vue-i18n'
import fr from './fr'
import nl from './nl'

export default function withI18n(app) {
  let i18n = createI18n({
    globalInjection: true,
    legacy: true,
    allowComposition: true,
    locale: localStorage.getItem('localeSelected') ? localStorage.getItem('localeSelected') : 'fr',
    availableLocales: ['fr', 'nl'],
    messages: {
      fr,
      nl
    }
  })
  app.use(i18n)
  return app
}
