const BASE_URL = import.meta.env.VUE_APP_SW_V2_SERVER_URL + '/'
const REQUEST_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Api-Token': import.meta.env.VUE_APP_LOCAL_SW_SERVER_API_TOKEN
    ? import.meta.env.VUE_APP_LOCAL_SW_SERVER_API_TOKEN
    : 'xxx'
  /** This field seems to not be needed since it's not used backend to identify the user */
}

export const requiredDocuments = {
  async index(contractRequestUuid) {
    const url = new URL(`contract_requests/${contractRequestUuid}/documents`, BASE_URL)
    const response = await fetch(url.toString(), {
      credentials: 'include',
      method: 'GET',
      headers: REQUEST_HEADERS
    })

    return await this.parseResponse(response)
  },
  async removeDocumentFile({ id: documentUuid, document_file_id: documentFileId }) {
    const url = new URL(`documents/${documentUuid}/files/${documentFileId}`, BASE_URL)
    const response = await fetch(url.toString(), {
      credentials: 'include',
      method: 'DELETE',
      headers: REQUEST_HEADERS
    })

    return await this.parseResponse(response)
  },
  async uploadDocumentFile(data, contractRequestUuid) {
    const formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }

    const url = new URL(`contract_requests/${contractRequestUuid}/documents`, BASE_URL)
    const response = await fetch(url.toString(), {
      credentials: 'include',
      method: 'POST',
      headers: {
        /** We're manually setting headers cause browser will automatically set as multipart/form-data with bound */
        Accept: 'application/json',
        'X-Api-Token': import.meta.env.VUE_APP_LOCAL_SW_SERVER_API_TOKEN
          ? import.meta.env.VUE_APP_LOCAL_SW_SERVER_API_TOKEN
          : 'xxx'
      },
      body: formData
    })

    return await this.parseResponse(response)
  },
  async sendUploadNotification({ contractRequestUuid, medium, type }) {
    const url = new URL(`contract_requests/${contractRequestUuid}/documents/notifications`, BASE_URL)
    const response = await fetch(url.toString(), {
      credentials: 'include',
      method: 'POST',
      headers: REQUEST_HEADERS,
      body: JSON.stringify({ medium, type })
    })

    return await this.parseResponse(response)
  },
  /**
   * Parse the response from the server and handle errors
   * @param {Response}   response
   * @returns {Promise<{success: boolean, errors: string[], data: Record<string, any>}>}
   */
  async parseResponse(response) {
    /** Handle something else than JSON returned */
    if (!response.headers.get('Content-Type')?.includes('application/json')) {
      const responseText = await response.text()
      const formattedResponseText = responseText.includes('<!DOCTYPE html>')
        ? `[${response.status}] `.concat(responseText.slice(0, 200))
        : `[${response.status}] `.concat(responseText)
      return {
        success: false,
        errors: [formattedResponseText]
      }
    }

    try {
      const fetchResult = await response.json()

      /** Handle HTTP code of response out of range 200-299  */
      if (!response.ok) {
        return {
          success: false,
          errors: fetchResult.errors
        }
      }

      /** Handle No Content HTTP code */
      if (response.status === 204) {
        return {
          success: true
        }
      }

      /** Handle successful response (finally :)) */
      return {
        success: true,
        data: fetchResult
      }

      /** Handle unable to parse body stream as JSON */
    } catch (error) {
      return {
        success: false,
        errors: [error.message]
      }
    }
  }
}
