export default {
  actions: {
    add_belonging: 'Ajouter un objet de risque',
    add_document: 'Ajouter un document',
    add_green_card: "Ajouter un certificat d'assurance",
    archive: 'Archiver',
    cancel: 'Annuler',
    cancelled: 'Action annulée.',
    confirm: 'Confirmer',
    confirmation_disclaimer: 'Êtes-vous sûr(e) de vouloir effectuer cette action ?',
    offer_closure_confirmation: "Avant de valider cette simulation, voulez-vous clôturer l'offre associée ?",
    copy_link: 'Copier le lien de la signature',
    complete_life_procedure: 'Compléter la procédure vie',
    delete: 'Supprimer',
    delete_belonging: 'Supprimer (seulement si non lié à un contrat/offre en cours)',
    edit: 'Editer',
    edit_contract: 'Modifier le contrat',
    remove: 'Supprimer',
    rgpd_removal: 'Supprimer le client (RGPD)',
    edit_user_belongings: 'Modifier les objets de risque',
    edit_user_profile: 'Modifier le profil client',
    edition: 'Edition',
    fill_documents: 'Remplir les documents',
    go_to: 'Aller vers...',
    loading: 'Action en cours...',
    mark_as_lost: 'Marquer comme perdu',
    mark_as_paid: 'Marquer comme payé',
    mark_as_waiting_for_contract: 'Marquer comme acté, en attente du contrat',
    pipedrive: 'Deal Pipedrive',
    pipedrive_link: 'Lien Pipedrive du client',
    reactivate: 'Réactiver',
    save: 'Enregistrer',
    see_details: 'Voir les détails',
    see_document: 'Voir le document',
    see_json_details: "Voir les détails techniques de l'objet",
    see_more: 'Voir plus',
    send: 'Envoyer',
    send_for_completion: 'Envoyer pour completion',
    send_for_signature: 'Envoyer pour signature',
    send_reminder: 'Envoyer un rappel au client',
    sending: 'Envoi en cours...',
    signature_reminder: 'Envoyer un rappel de signature',
    solve: 'Marquer comme clôturé/acté',
    solve_for_later_date: 'Marquer comme acté à une autre date',
    solve_for_next_year: 'Échéance plus 1 an',
    success: 'Action effectuée avec succès',
    terminate: 'Résilier',
    unsubscribe: 'Désinscrire',
    upload_reminder: "Envoyer un rappel d'upload des documents",
    add_coupling_primary: 'Ajouter un contrat en base de couplage',
    add_to_full_package_bundle: "Ajouter à l'offre multi-contrat",
    baloise_car_pack_validation_confirmation: 'Je valide les informations du client',
    become: 'Ancien My Yago',
    become_my: 'My Yago',
    cancel_stopping: 'Annuler une résiliation',
    confirmation_label: 'Je confirme',
    couple: 'Coupler le contrat',
    couple_as_motorbike_primary: 'Coupler le contrat',
    create_amendment: 'Enregistrer un nouvel avenant',
    create_contract: 'Valider et créer le contrat',
    create_green_card: 'Ajouter un certificat d’assurance',
    mail: 'Générer un mail',
    reject_for_bad_customer: 'Rejeter pour mauvais client',
    reject_for_bad_input: "Retour à l'upload des documents",
    remove_from_full_package_bundle: "Retirer de l'offre",
    renew_green_card: 'Renouveler le certificat d’assurance',
    reset_password: 'Envoyer mail reset MDP',
    resume_purchase: "Reprendre l'achat",
    stop: 'Résilier / Suspendre / Transfert en défaveur',
    stopping_date: 'Date à laquelle le contrat sera résilié.',
    stopping_reason: 'Quelle en est la raison ?',
    upsell: 'Upseller le contrat',
    validate: 'Valider documents uploadés',
    validate_amendment: "Valider l'avenant",
    verify: 'Approuver contrat (non-fiché)'
  },
  assertions: {
    yes: 'Oui',
    no: 'Non'
  },
  commercial_data: {
    chargebee: 'Chargebee:',
    subscription_link: 'Lien vers la souscription'
  },
  company_symbol: '💼 ',
  claims: {
    add_claim_reference: 'Ajouter une référence assureur',
    reference: 'Référence'
  },
  documents: {
    existing_contract: 'Contrat existant',
    existing_front_document: 'Recto du document existant',
    existing_back_document: 'Verso du document existant',
    existing_invoice_document: "Facture d'achat existante",
    existing_grey_card_front: 'Recto de la carte grise existante',
    existing_grey_card_back: 'Verso de la carte grise existante',
    back: 'Verso',
    claim_statements: 'Attestations de sinistralité:',
    contract_pdf: 'Contrat',
    insurer_policy_document_url: 'Contrat',
    insurer_policy_document_icon_explanation: 'Reçu par message assureur',
    insurer_total_premium_match_explanation: 'Identique au message assureur',
    insurer_total_premium_mismatch_explanation: 'Différente du message assureur',
    missing_contract: '⚠️ Contrat manquant',
    offer_pdf: "Document de l'offre:",
    driving_licence: 'Permis de conduire:',
    first_quittance: 'Première quittance',
    aml_document: "Fiche d'information AML",
    indexing_quittance: 'Quittance {year}',
    front: 'Recto',
    grey_card: 'Carte Grise:',
    identity_card: "Carte d'identité:",
    invoice: "Facture d'achat:",
    last_amendment_pdf: 'PDF du dernier avenant:',
    registration_request: "Demande d'immatriculation:",
    signed: 'Signé:',
    statement: 'Attestation',
    title: 'Documents',
    unsigned: 'Non-signé:',
    upload: {
      amendment: 'Avenant',
      aml: "Fiche d'information AML",
      bank_transfer: 'Virement',
      claim_statement: 'Attestation de sinistralité',
      company_offer: 'Offre de la compagnie',
      contract: 'Contrat',
      green_card: "Certificat d'assurance",
      grey_card_back: 'Carte grise (verso)',
      grey_card_front: 'Carte grise (recto)',
      invoice: "Facture d'achat",
      quittance: 'Quittance',
      registration_request: "Demande d'immatriculation"
    }
  },
  evolve_labels: {
    archived: 'archivé',
    lost: 'perdu',
    reactivated: 'réactivé',
    solved: 'clôturé',
    solved_for_another_date: 'acté pour une autre date',
    waiting_for_contract: 'acté et en attente du contrat'
  },
  false: 'Non',
  false_symbol: '❌',
  forms: {
    couple_form: {
      beneficiary_role: 'Bénéficiaire du couplage',
      formula_question: 'Quelle est la formule de couplage ?',
      primary_role: 'Base du couplage',
      role_question: 'Quel est le rôle de ce contrat dans le couplage ?'
    },
    disclaimers: {
      address:
        "Attention, vous venez de modifier l'adresse. Le client a probablement déménagé. Pouvez-vous vous assurer que : Les adresses soient changées dans chacune des compagnies pour que le client reçoive ses prochaines quittances {addressHasHouseContractMessage}",
      address_has_house_contract_message: 'Le contrat {contractNumber} chez {insurer} du client soit adapté/résilié.',
      confirm: 'Je confirme les points listés ci dessus',
      email:
        "Êtes-vous sûr(e) de vouloir modifier l'email de ce client ? Ne changez un email que si vous savez ce que vous faites !",
      mobile_phone: 'Ceci ne changera pas le num pour les transferts, renons et SEPA en cours !',
      terminations: {
        confirm_another_contract_ready: {
          future_policy_end_date: 'Je confirme avoir mis en place un nouveau contrat qui commence à cette date.',
          past_policy_end_date:
            "Je confirme avoir mis en place un autre contrat qui commence aujourd'hui pour couvrir le risque du client"
        },
        confirm_contract_status: {
          future_policy_end_date:
            "Je suis conscient que le contrat va cesser à l'échéance - soit le {policy_end_date}.",
          past_policy_end_date:
            "Je suis conscient que le contrat va cesser immédiatement après la signature du client - sa date d'échéance étant le {policy_end_date}."
        },
        confirm_sending_to_old_company:
          "J'ai pris conscience que le renon peut être envoyé à l'ancien assureur maximum 3 mois après la date de réception par le client de la nouvelle quittance. Si le client l'a reçu un mois avant l'échéance du contrat, le renon doit donc être envoyé dans les 2 mois maximum."
      },
      unfit:
        "Êtes-vous sûr(e)? Le profil Ardor de ce client sera marqué comme refusé, indiquant à l'ensemble de vos collègues que Yago ne souhaite pas collaborer davantage avec ce client"
    },
    radio: {
      company: 'Indépendant',
      no: 'Non',
      private: 'Particulier',
      yes: 'Oui'
    },
    titles: {
      address: 'Adresse',
      company_address: 'Adresse de la société',
      company_data: 'Informations sur la société',
      contact_data: 'Données de contact',
      edit_belonging: "Modifier l'objet de risque",
      edit_car_vehicle: "Modifier l'auto",
      edit_campaign: 'Modifier la campagne',
      edit_house_belonging: "Modifier l'habitation",
      edit_motorbike_vehicle: 'Modifier la moto',
      edit_target: 'Modifier la target',
      edit_user: "Modifier les données de l'utilisateur",
      payment_data: 'Données de paiement',
      personal_data: 'Données personnelles',
      risk_objects: 'Objets de risque du client'
    },
    tooltips: {
      incomplete_company_client_data:
        'Les données de la société de ce client doivent être complètes pour utiliser ce champ',
      incomplete_data:
        'Les données personelles du client ou celles de sa société doivent être complètes pour utiliser ce champ.',
      incomplete_default_client_data:
        'Les données du profil particulier de ce client doivent être complètes pour utiliser ce champ',
      insurer_email:
        "Si vous ne trouvez pas la compagnie c'est qu'elle n'a pas l'adresse mail correspondante encodée dans Ardor",
      notify: {
        no: "Non: l'utilisateur recevra un mail vers 18h avec d'autres notifications potentiels.",
        yes: "Oui: l'utilisateur recevra directement un mail. ⚠️ Si le document n'est pas signé à 18h, il sera aussi mis dans le mail de notification."
      }
    },
    validations: {
      accidents: {
        end_date: 'La date du sinistre doit être après la date de début de la période',
        start_date: 'La date du sinistre doit être avant la date de fin de la période'
      },
      between: 'Doit être compris entre {min} et {max}',
      birthdate: {
        too_old: 'Doit être inférieur à 100 ans',
        too_young: 'Doit être supérieur à 18 ans'
      },
      chassis_number: {
        invalid: 'Doit être long de 17 caractères alphanumériques'
      },
      contract: {
        wrong_user: 'Le client {user} ne possède pas le contrat {contract}'
      },
      description: 'La description doit être plus longue',
      document: {
        client_card_updated: 'Le pdf est requis car une fiche client va être générée',
        covers_updated: 'Le pdf est requis car les garanties ont été modifiées',
        information_duty_updated: "Le pdf est requis car un devoir d'information va être généré"
      },
      email: "L'email n'est pas valide",
      end_date: "La date d'échéance doit être après la date de prise d'effet",
      green_card: {
        valid_from:
          "La date de début de validité doit être après la date de prise d'effet du contrat ({contractStartDate})",
        valid_until: 'La date de fin de validité doit être après la date de début de validité'
      },
      invalid_date: "La date n'est pas valide",
      invalid_car_bcp_policy_type: "Il n'est pas possible de faire un transfert en Auto BCP",
      invalid_policy_type: 'Cette garantie ne peut pas être sélectionnée dans ce process',
      reference: 'Format invalide. Seuls les chiffres et lettres majuscules sont acceptés.',
      required: 'Ce champ est requis',
      seraphin_email: "L'adresse mail n'est pas conforme au format Yago",
      start_date: "La date de prise d'effet doit être avant la date d'échéance"
    },
    warnings: {
      missing_field: 'Champ manquant ⚠️',
      files: {
        limit_exceeded: "Enlever d'abord le fichier pour pouvoir en ajouter un autre",
        too_large: 'Le fichier uploadé est trop volumineux'
      },
      life_contract_information_duty:
        "Attention, vous encodez un contrat VIE ou ASRD. Merci de vous assurer que le devoir d’information correspondant a bien été émis. Il s'agit d'une obligation légale de la FSMA."
    }
  },
  genders: {
    emoji: {
      female: '👩 ',
      male: '👨 ',
      null: '👤 '
    },
    female: 'Femme',
    male: 'Homme',
    null: 'Inconnu'
  },
  json_details_title: "Détails techniques de l'objet en format JSON 🤓",
  labels: {
    client_type: 'Type de client',
    associated_documents: 'Documents associés',
    address: 'Adresse',
    age: 'Age',
    alarm_installed: 'Alarme',
    alias: 'Dénomination',
    already_insured: 'Déjà assuré',
    amendment_number: "N° de l'avenant",
    amendment_pdf: "Uploader le pdf de l'avenant",
    aml: "Fiche d'information AML",
    amount_due: 'Montant dû',
    assistance_formula: 'Formule assistance',
    attachment: 'Pièce jointe',
    audit_validated: 'Audit validé',
    axa_offer_reference: 'Référence simulation Phoenix(Axa)',
    back_document: 'Verso du document',
    baloise_car_pack_disclaimer: "Ceci est une simulation BCP - n'utilisez pas ces références sur MyBaloise :)",
    baloise_car_pack_label: '~ Baloise Car Pack ~',
    bank_transfer: 'Virement',
    bic: 'BIC de la banque',
    birthdate: 'Date de naissance',
    brand: 'Marque',
    build_year: 'Année de construction',
    bundled_payment: 'Plan de paiement',
    bundled_payment_payment_due_at: 'Echèc de prélèvement le',
    bundled_payments: 'Plans de paiement',
    category: 'Catégorie',
    change_email: "Modifier l'email ?",
    chargebee: 'Chargebee',
    chargebee_subscription_link: 'Lien vers la souscription',
    chassis_number: 'N° de châssis',
    city: 'Ville',
    civil_status: 'Etat civil',
    claims: 'Sinistres',
    claims_statement_declared_years: "Années d'attestation déclarées",
    claims_statement_uploaded_years: "Années d'attestation uploadées",
    client: 'Client',
    client_card: 'Fiche client',
    company: 'Société',
    content_value: 'Montant du contenu',
    contract: 'Contrat',
    contract_number: 'N° de contrat',
    contract_request: 'Simulation',
    country: 'Pays',
    coupled: 'Couplé',
    coupling: 'Couplage',
    coupling_formula: 'Type de couplage',
    coupling_role: 'Rôle de couplage',
    cover: 'Garantie(s)',
    cover_end_date: 'Date de fin de la couverture',
    covers: 'Garanties',
    description: 'Description',
    details: 'Détails',
    document_type: 'Type de document',
    driving_licence_date: 'Date du permis',
    email: 'Email',
    encoded: 'Encodé',
    end_date: "Date d'échéance",
    engine_displacement: 'Cylindrée',
    entry_into_circulation: 'Entrée en circulation',
    family_name: 'Nom de famille',
    finalized: 'Finalisé ?',
    financial_data: 'Données financières',
    first_name: 'Prénom',
    fix_phone: 'Téléphone fixe',
    former_policy_number: 'N° de la police précédente (voir PDF)',
    free_message: 'Message libre',
    front_document: 'Recto du document',
    gender: 'Genre',
    green_card: "Certificat d'assurance",
    green_card_pdf: "Uploader le PDF du certificat d'assurance",
    gross_premium: 'Primes annuelles TTC',
    has_company: 'Le client à-t-il une société ?',
    household_type: 'Ménage',
    iban: 'N° IBAN',
    information_duty: "Devoir d'information IARD",
    insurance_request_delay: 'Urgence besoin',
    insured_risk: 'Risque assuré',
    insurer: 'Compagnie',
    language: 'Langue de contact',
    last_name: 'Nom',
    legal_name: 'Dénomination légale',
    legal_person: 'Personne morale (société)',
    licence_plate: "N° d'immatriculation",
    main_cover: 'Garantie principale',
    main_driver: 'Conducteur principal',
    manufacturer: 'Marque',
    message: 'Message',
    messages: 'Messages',
    mobile_phone: 'Téléphone mobile',
    model: 'Modèle',
    modifications: 'Instructions et précautions',
    month: 'Mois',
    months: 'mois',
    motorbike_formula: 'Formule véhicule',
    my_baloise_proposal_reference: "Référence de l'offre myBaloise",
    my_baloise_references_header: 'Références',
    my_baloise_quote_reference: 'Référence simulation myBaloise',
    name: 'Nom',
    national_registry_number: 'N. registre national',
    natural_person: 'Personne physique (individu)',
    nature: 'Nature',
    new_insurer: 'Au profit de',
    not_applicable: 'N/A',
    not_reviewed: 'Pas encore revu',
    notify: "Notifier l'utilisateur",
    occasional_driver: 'Conducteur occasionel',
    occurred_at: 'Date du sinistre',
    old_insurer: 'Compagnie renoncée',
    on_files: 'Fiché',
    operator: 'Business developer',
    origin: 'Source',
    owner_title: "Fonction au sein de l'entreprise",
    payment_due_at: 'Paiement dû le',
    phone: 'Téléphone',
    place_of_birth: 'Lieu de naissance',
    policy_end_date: "Date d'échéance",
    policy_number: 'N° de police',
    policy_owner: 'Preneur',
    politically_exposed: 'Politiquement exposée',
    pre_tax_price: 'Prix HTVA',
    premium_collection: 'Encaissement',
    price_increase: 'Augmentation tarifaire',
    product: 'Produit',
    profession: 'Profession',
    professional_status: 'Occupation',
    quittance: 'Quittance',
    residence_type: "Type d'habitation",
    residence_value: 'Valeur estimée',
    resident_type: 'Type de résident',
    responsibility: 'Responsabilité',
    risk_object: 'Objet de risque',
    second_hand: 'Seconde main',
    secondary_driver: 'Conducteur secondaire',
    send_email_to_insurer: "Envoyer un email à l'assureur",
    seniority_formula: 'Formule âge',
    splitting: 'Fractionnement',
    standard_message: 'Message type',
    start_date: "Date de prise d'effet",
    status: 'Statut',
    street_box: 'Boîte',
    street_name: 'Rue',
    street_number: 'Numéro',
    subject: 'Sujet',
    sworn_statement: "⚠️ Attestation sur l'honneur",
    target: 'Target mensuelle',
    temporary: 'Certificat d’assurance temporaire ?',
    termination: 'Renon',
    theft_formula: 'Formule vol',
    theft_value: 'Montant vol',
    time: 'Reçu le',
    time_between: 'à',
    time_country: 'fr',
    type: 'Type',
    unfit: 'Client refusé',
    unfit_reason: 'Raison du refus',
    unfit_comment: 'Description',
    unpaid: 'Impayé',
    unpaids_bundled_payment: 'Impayés plan de paiement',
    unpaids_contracts: 'Impayés contrats',
    updated_at: 'Mis à jour',
    upload_document: '{name} (max {max_file_size} Mb)',
    upload_grey_card_back: 'Uploader la carte grise verso (max {max_file_size} Mb)',
    upload_grey_card_front: 'Uploader la carte grise recto (max {max_file_size} Mb)',
    uploaded_on_ardor: 'Uploadée sur Ardor',
    usage: 'Usage',
    valid_from: 'Valide à partir du...',
    valid_until: "Valide jusqu'au...",
    vat_number: 'N° de TVA',
    vehicle: 'Véhicule',
    vivium_quote_reference: "Référence de l'offre Vivium",
    waiting_for_signature: 'Contrat à signer ?',
    will_for_full_package_client: 'Désire formule groupée',
    wins: 'Win(s) associé(s)',
    years: 'ans',
    zip_code: 'Code postal',
    events: 'Événements',
    roles: 'Rôles',
    added_at: 'Ajouté le',
    positions: 'Fonctions',
    title: 'Titre'
  },
  messages: {
    action_cancelled: 'Action annulée.',
    rgpd_removal_disabled: "Il n'est pas autorisé de supprimer un client qui possède des contrats",
    confirm: {
      add: 'Êtes-vous sûr(e) de vouloir ajouter : {object} ?',
      reinstate: 'Êtes-vous sûr(e) de vouloir rétablir le contrat ?',
      delete: 'Êtes-vous sûr(e) de vouloir supprimer : {object} ?',
      delete_client: 'Êtes-vous sur de vouloir supprimer ce client ?<br>Cette action est <b>irréversible</b>',
      email:
        "<b>Êtes-vous sûr(e) de vouloir envoyer ce mail?</b><br>Attention à ne pas spammer l'utilisateur: merci de vérifier au préalable dans ses événements les mails déjà envoyés.<br>Destinataire: <b>{to}</b>",
      status_update: 'Êtes-vous sûr(e) de vouloir marquer ce {object} comme {label} ?',
      terminate:
        '<b>Voulez-vous envoyer une notification de résiliation ?</b><br>Après confirmation, vous serez redirigé(e) vers le profil du client afin de procéder à la résiliation du contrat, qui doit être effectuée manuellement.',
      terminate_bundled_payment:
        "<b>Voulez-vous envoyer une notification d'arrêt du plan de paiement ?</b><br>Après confirmation, vous serez redirigé(e) vers le profil du client afin de procéder à l'arrêt du plan de paiement, qui doit être effectuée manuellement.",
      update: 'Êtes-vous sûr(e) de vouloir modifier : {object} ?',
      rectify: 'Êtes-vous sûr(e) de vouloir rectifier : {object} ?'
    },
    encoding_problem: "Problème dans l'encodage.",
    link_copied: 'Lien copié dans le press-papier',
    loading: {
      add: 'Ajout de : {object} en cours...',
      delete: 'Suppression de : {object} en cours...',
      update: 'Mise à jour de : {object} en cours...',
      rectify: 'Mise à jour de : {object} en cours...'
    },
    removed_redirect: 'La suppression a réussi, vous allez être redirigé(e)',
    missing_user_data:
      "Attention, il faut d'abord remplir les champs: numéro national, occupation et personne politiquement exposée",
    ongoing_sepa_mandate:
      "Attention, il y a un ou plusieurs mandats SEPA qui ne sont pas encore archivés / annulés. Veuillez les archiver avant d'en créer un nouveau",
    send_cancelled: 'Envoi annulé',
    synchronization_error:
      'Une erreur de synchronisation est survenue. Veuillez reloader la page et soumettre à nouveau le formulaire.',
    technical_problem: '🤭 Oops...petit problème technique. Contactez les techies.',
    user_incomplete:
      'Les informations de base du client ne sont pas complètes. Veuillez vérifier si ses infos de base ont bien été complétées (nom, prénom, numéro de téléphone, date de naissance, adresse).',
    user_search_error: "Une erreur s'est produite lors de la recherche de l'utilisateur.",
    remove_disclaimer:
      '⚠️ Êtes-vous certain de vouloir supprimer cet utilisatateur ?<br/> Attention, cette action est irréversible.'
  },
  null: '-',
  null_symbol: '-',
  placeholders: {
    bicycle: 'Inscrire marque + modèle',
    car: 'Inscrire marque + modèle',
    client_search: 'Chercher un client',
    filter: 'Filtrer...',
    house: "Inscrire l'adresse de l'habitation",
    house_rental: "Inscrire l'adresse de l'habitation",
    independent: "Inscrire le nom de l'entreprise",
    motorbike: 'Inscrire marque + modèle',
    motorhome: 'Inscrire marque + modèle',
    search: 'Rechercher',
    select: 'Sélectionner',
    van: 'Inscrire marque + modèle',
    boat: 'Inscrire marque + modèle',
    'push-scooter': 'Inscrire marque + modèle'
  },
  // This part is duplicated in Serverless and SW
  policy_types: {
    '1_toit_2_ages': '1 Dak Twee Leeftijden',
    assistance: 'Assistance',
    bicycle: 'Vélo',
    boat: 'Bateau',
    car: 'Auto',
    car_bcp: 'Auto BCP',
    car_oldtimer: 'Auto ancêtre',
    civil_liability: 'RC Familiale',
    digital_nomad: 'Appareils digitaux',
    driver_safety: 'Sécurité du Conducteur',
    funeral: 'Assurance Obsèque',
    health: 'Santé',
    hospitalisation: 'Hospitalisation',
    house: 'Habitation',
    house_rent_guarantee: 'Loyers impayés',
    hunting: 'RC Chasse',
    independent_work_accident: 'Accidents du travail',
    legal_expenses: 'PJ Familiale',
    administrator_civil_liability: 'RC Administrateurs',
    hospitalization_group_insurance: 'Assurance groupe hospitalisation',
    cyber_insurance: 'Assurance Cyber',
    legal_protection: 'Protection juridique',
    life_cpti: 'Vie - CPTI',
    life_eip: 'Vie - EIP',
    life_investment_fund: "Vie - Fond d'investissement",
    life_non_tax_savings: 'Vie - Épargne non fiscale',
    life_pension_long_term: 'Vie - Épargne long terme',
    life_pension_saving: 'Vie - Épargne pension',
    life_plci: 'Vie - PLCI',
    life_srd: 'Vie - Solde restant dû',
    life_inami: 'Vie - Inami',
    life_group_insurance: 'Vie - Assurance groupe',
    life_bonus_plan: 'Vie - Plan Bonus',
    motorbike: 'Moto',
    motorbike_oldtimer: 'Moto ancêtre',
    motorhome: 'Motorhome',
    organizer_civil_liability: 'RC Organisateur',
    pet: 'Animal',
    personal_liability: 'RC personnelle',
    professional_civil_liability: 'RC Professionnelle civile',
    professional_liability: 'RC Professionnelle',
    professional_legal_expenses: 'PJ Professionnelle',
    objective_liability: 'RC Objective',
    workers_compensation: 'Indemnisation du travailleur'
  },
  splittings: [
    {
      label: 'Annuel',
      value: 'yearly'
    },
    {
      label: 'Mensuel',
      value: 'monthly'
    },
    {
      label: 'Trimestriel',
      value: 'trimestrial'
    },
    {
      label: 'Semi-annuel',
      value: 'half_yearly'
    }
  ],
  premiums_collection_methods: [
    {
      label: 'Compagnie',
      value: 'direct_billing'
    },
    {
      label: 'Yago',
      value: 'broker_billing'
    }
  ],
  statuses: {
    accepted: 'Accepté par le client',
    archived: 'Archivé / annulé',
    finished: 'Résiliés',
    ongoing_purchase: 'Achat en cours',
    in_progress_claims: 'Sinistres en cours',
    terminated_claims: 'Sinistres clôturés',
    processed: 'Acté',
    processed_for_another_date: 'Acté à une autre date',
    solved: 'Payé',
    validated: 'Validé',
    waiting_for_approval: 'Attente compagnie',
    waiting_for_completion: 'En attente de completion',
    waiting_for_confirmation: 'En attente de confirmation des mentions légales',
    waiting_for_contract: 'Attente du contrat',
    waiting_for_payment: 'Attente paiement',
    waiting_for_sending: "Attente d'envoi",
    waiting_for_signature: 'Attente signature client',
    waiting_for_validation: 'Attente de validation',
    written_off: 'Perdu (write-off)',
    reported: 'Nouveau sinistre',
    analysis: 'Analyse et attente ouverture',
    awaiting_expertise: 'Attente réalisation expertise',
    expertise_dispute: 'Contestation expertise',
    awaiting_report: 'Attente PV expertise',
    awaiting_liability: 'Attente responsabilité',
    liability_dispute: 'Contestation responsabilité',
    legal_challenge: 'Contestation judiciaire',
    awaiting_closure: 'Attente clôture',
    voided: 'Annulé',
    closed: 'Clôturé'
  },
  street_box: 'bte {streetBox}',
  tables: {
    columns: {
      accidents: 'Sinistres',
      actions: 'Opérations',
      amount: 'Montant',
      attestation: 'Attestation',
      broker_reference: 'N° compte agent',
      bundled_payment: 'Plan paiement',
      cars: 'Auto',
      category: 'Catégorie',
      cease_and_desist_at: 'Mise en demeure',
      claim_statement: 'Attestation de sinistralité',
      claims: 'Sinistres',
      client: 'Client',
      claim_type: 'Type de sinistre',
      contract_number: 'Numéro de contrat',
      cover: 'Couverture',
      covering_from: 'Début de la période',
      covering_until: 'Fin de la période',
      covers: 'Risques couverts',
      date: 'Date',
      details: 'Détails',
      direction: 'Direction',
      document_type: 'Type de document',
      documents: 'Documents',
      due_at: 'Dû le',
      end_date: 'Echéance',
      enforced_at: 'En application le',
      event: 'Evénement',
      finished_at: 'Date de résiliation',
      generic: 'Objet de risque',
      gross_premium: 'Primes',
      houses: 'Habitation',
      incident_date: 'Date du sinistre',
      iban: 'N° de compte',
      id_card: 'ID',
      insurer: 'Compagnie',
      insurer_references: 'Références sinistres assureurs',
      invoice: 'Facture',
      invoice_document: "Facture d'achat",
      last_reminder_date: 'Date dernier rappel',
      last_reminder_step: 'Etape dernier rappel',
      licence_plate: 'Plaque',
      main: 'Gestion',
      motorbikes: 'Moto',
      name: 'Nom',
      number: 'Numéro',
      operator: 'Business developer',
      operator_short: 'Bizdev',
      owner: 'Owner',
      policy_number: 'N° de police',
      policy_type: 'Type de police',
      price_increase: 'Augmentation tarifaire',
      product: 'Produit',
      reference: 'Référence',
      reminders_count: 'Nombre de rappels',
      risk_object: 'Objet de risque',
      sent_at: 'Envoyé le ',
      sepa: 'Sepa',
      sepa_mandates: 'Mandats SEPA',
      sinistrality: 'Sinistralité',
      start_date: 'Début le',
      statement: 'Attestation',
      status: 'Statut',
      stopping_reason: 'Raison de résiliation',
      stopping_schedule: 'Suspension/résiliation future',
      suspension_date: 'Date de suspension',
      tags: {
        all_statuses: 'Tous les statuts',
        danger: 'En retard',
        success: 'Dans les temps',
        warning: 'Bientôt en retard'
      },
      termination_date: 'Date de résiliation',
      terminations: 'Renons',
      transfers: 'Transferts',
      type: 'Type',
      updated: 'Mis à jour',
      updater: 'Actualisateur',
      validated: 'Validé',
      won_at: 'Gagné le'
    }
  },
  tabs: {
    help: 'Aide',
    new: 'Nouveau',
    ongoing: 'En cours',
    past: 'Passés'
  },
  timeline_activities: {
    detailedAt: 'Détaillé le',
    paidAt: 'Payé le',
    rejectedAt: 'Rejeté le',
    validatedAt: 'Validé le',
    verifiedAt: 'Vérifié le'
  },
  tooltips: {
    search: 'Recherche par nom, prénom, email, téléphone, nom légal, numéro TVA, référence de contrat',
    tiny_search: 'Recherche rapide par nom, prénom, email, téléphone'
  },
  true: 'Oui',
  true_symbol: '✅',
  undefined: '?',
  desired_contract_start: {
    as_soon_as_possible: 'Le plus vite possible',
    in_1_month: "d'Ici 1 mois",
    in_3_months: "d'Ici 3 mois",
    within_the_year: "Dans l'année",
    looking_around: 'Je me renseigne',
    null: '-'
  },
  year: '- | 1 an | {years} ans',
  empty_data: 'Aucune donnée',
  pdf_templates: {
    private_savings_information_duty: "Devoir d'information EP / ELT",
    physical_person_self_certification: 'Self Certification personne physique AG',
    sepa_mandate_life_ag: 'Mandat SEPA Vie AG',
    insurer_questionnaire: 'Questionnaire Assureur AG',
    medical_survey_less_than_30000: 'Questionnaire médical AG si - 30.000€',
    medical_survey_more_than_30000: 'Questionnaire médical AG si + 30.000€',
    sworn_statement: "Attestation sur l'honneur (Aedes / AXA / Vivium )",
    omnium_statement: "Attestation de l'omnium (Aedes)"
  },
  pdf_template_categories: {
    life: 'Vie',
    property_and_casualty: 'IARD'
  },
  warning_empty_chassis_number: "Le numéro de châssis n'a pas été encodé."
}
