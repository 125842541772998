import initialState from './initialState'
import { SET_LOADING, SET_GAMIFICATION } from './constants'
import { gamification } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.meta.loading = payload
    },
    [SET_GAMIFICATION](state, payload) {
      state = Object.assign(state, payload)
    }
  },
  actions: {
    async fetch({ state, commit }) {
      commit(SET_LOADING, true)
      const { success, data } = await gamification.show()
      if (success) {
        commit(SET_GAMIFICATION, data)
      }
      commit(SET_LOADING, false)
      return { data }
    }
  }
}
