import { SET_LOADING, SET_INDEXATION } from './constants'
import api from '@/services/api_v2'

export default {
  namespaced: true,
  state: {
    data: {},
    actions: {},
    loading: false,
    success: false,
    error: ''
  },
  getters: {
    formattedData(state) {
      return state.data
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_INDEXATION](state, payload) {
      state.data = payload
    }
  },
  actions: {
    async fetchIndexation({ state, commit }, params) {
      commit(SET_LOADING, true)
      api
        .fetchIndexation(params.reference)
        .then(({ data }) => {
          commit(SET_INDEXATION, data)
          commit(SET_LOADING, false)
        })
        .catch((error) => {
          commit(SET_INDEXATION, null)
          commit(SET_LOADING, false)
        })
    }
  }
}
