import initialState from '../_helpers/initialState'
import updateList from '../_helpers/updateList'
import { SET_LOADING, SET_CAMPAIGNS, ADD_CAMPAIGN, UPDATE_CAMPAIGN } from './constants'
import { reductionCampaigns } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_CAMPAIGNS](state, payload) {
      state.data = payload
    },
    [ADD_CAMPAIGN](state, payload) {
      state.data.push(payload)
    },
    [UPDATE_CAMPAIGN](state, payload) {
      updateList({ data: state.data, payload, idArg: 'id' })
    }
  },
  actions: {
    fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      reductionCampaigns.index(params).then(({ data }) => {
        commit(SET_CAMPAIGNS, data)
        commit(SET_LOADING, false)
      })
    },
    async save({ state, commit }, { isNew, ...payload }) {
      const { success, message, data } = await (isNew
        ? reductionCampaigns.create(payload)
        : reductionCampaigns.update(payload))
      if (success) commit(isNew ? ADD_CAMPAIGN : UPDATE_CAMPAIGN, data)
      return { success, message }
    }
  }
}
