import initialState from '../_helpers/initialState'
import { SET_LOADING, SET_CLAIMS } from './constants'
import { claims } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_CLAIMS](state, payload) {
      state.data = payload
    }
  },
  actions: {
    async fetch({ commit }, params) {
      commit(SET_LOADING, true)
      const { data } = await claims.index(params)

      commit(SET_CLAIMS, data)
      commit(SET_LOADING, false)

      return { success: true, message: 'Sinistres récupérés avec succès' }
    }
  }
}
