import initialState from '../_helpers/initialState'
import updateList from '../_helpers/updateList'
import { SET_LOADING, SET_TERMINATIONS, ADD_TERMINATION, UPDATE_TERMINATION } from './constants'
import { terminations } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {
    ongoing(state) {
      if (!state.data) return []

      return state.data.filter((u) =>
        ['waiting_for_signature', 'waiting_for_sending', 'waiting_for_approval', 'processed_for_another_date'].includes(
          u.status
        )
      )
    },
    pendingInsurer(state) {
      if (!state.data) return []

      return state.data.filter((u) =>
        ['waiting_for_sending', 'waiting_for_approval', 'processed_for_another_date'].includes(u.status)
      )
    },
    waitingForSignature(state) {
      if (!state.data) return []

      return state.data.filter((u) => u.status === 'waiting_for_signature')
    },
    past(state) {
      if (!state.data) return []

      return state.data.filter((u) => ['archived', 'processed'].includes(u.status))
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_TERMINATIONS](state, payload) {
      state.data = payload
    },
    [ADD_TERMINATION](state, payload) {
      state.data.push(payload)
    },
    [UPDATE_TERMINATION](state, payload) {
      updateList({ data: state.data, payload, idArg: 'uuid' })
    }
  },
  actions: {
    fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      terminations.index(params).then(({ data }) => {
        commit(SET_TERMINATIONS, data)
        commit(SET_LOADING, false)
      })
    },
    async update({ state, commit }, payload) {
      const { success, message, data } = await terminations.update(payload)
      if (success) commit(UPDATE_TERMINATION, data)
      return { success, message }
    },
    async save({ state, commit }, payload) {
      const { success, message, data } = await terminations.create(payload)
      if (success) commit(ADD_TERMINATION, data)
      return { success, message }
    }
  }
}
