import API from './api'

const { VUE_APP_DEFAULT_LOCALE, VUE_APP_AVAILABLE_USER_LOCALES } = import.meta.env

const TEMPLATES_IDS = {
  accidentRecordRequest: {
    fr: 14076931,
    nl: 17354567
  },
  sepaMandateReminder: {
    fr: 12030268,
    nl: 17423260
  },
  terminationReminder: {
    fr: 10806766,
    nl: 17424838
  },
  transferReminder: {
    fr: 10925857,
    nl: 17425567
  },
  fpbOfferEmail: {
    fr: 28387866,
    nl: 28388151
  }
}

export const sendEmail = async ({ locale, template, ...params }) => {
  const templateLocale = VUE_APP_AVAILABLE_USER_LOCALES.includes(locale) ? locale : VUE_APP_DEFAULT_LOCALE
  return API.post('/messages/email', {
    ...params,
    templateId: TEMPLATES_IDS[template][templateLocale]
  })
}
