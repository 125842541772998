import decode from 'jwt-decode'
import router from '../router'
import auth0 from 'auth0-js'
import * as Sentry from '@sentry/vue'

const ID_TOKEN_KEY = 'id_token'
const ACCESS_TOKEN_KEY = 'access_token'
const CALLBACK_ROUTE = 'callback_route'

const DOMAIN_ERROR = 'Access denied. User domain must be part of seraphin.be'

const auth = new auth0.WebAuth({
  clientID: import.meta.env.VUE_APP_AUTH0_CLIENT_ID,
  domain: import.meta.env.VUE_APP_AUTH0_CLIENT_DOMAIN
})

export function login() {
  if (import.meta.env.VUE_NODE_ENV === 'test') return

  auth.authorize({
    responseType: 'token id_token',
    redirectUri: new URL('/callback', window.origin).toString()
  })
}

export function logout() {
  clearIdToken()
  clearAccessToken()
  router.go('/')
}

export function requireAuth(to, from, next) {
  if (!isLoggedIn()) {
    setCallbackRoute(to.path)
    next({ path: '/login' })
  } else {
    next()
  }
}

export function sanitizeAuth0Callbacks(to, from, next) {
  const error = getAuthenticationError()
  if (error.error) {
    // Report errors that don't concern wrong login with other emails than allowed ones
    if (error.description !== DOMAIN_ERROR) Sentry.captureMessage(error.description)
    next({ path: '/login' })
  } else {
    next()
  }
}

function setCallbackRoute(route) {
  localStorage.setItem(CALLBACK_ROUTE, route)
}

export function getCallbackRoute() {
  const route = localStorage.getItem(CALLBACK_ROUTE)
  if (route) {
    localStorage.removeItem(CALLBACK_ROUTE)
    return route
  }
}

export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY)
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY)
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

// Helper function that will allow us to extract the elements from anchors of callback URL
function getParameterByName(name) {
  const match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

export function getAuthenticationError() {
  return {
    error: getParameterByName('error'),
    description: getParameterByName('error_description')
  }
}

// Get and store access_token in local storage
export function setAccessToken() {
  const accessToken = getParameterByName('access_token')
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
}

// Get and store id_token in local storage
export function setIdToken() {
  const idToken = getParameterByName('id_token')
  localStorage.setItem(ID_TOKEN_KEY, idToken)
}

export function isLoggedIn() {
  if (import.meta.env.VUE_NODE_ENV === 'test') return true
  const idToken = getIdToken()
  // Had to modify the Auth0 boilerplate here, as somehow the idToken is
  // cast a the 'null' string instead of being null type the first time
  // before any login happens...
  return idToken !== 'null' && idToken !== null && !isTokenExpired(idToken)
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken)
  if (!token.exp) {
    return null
  }

  const date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}

export function operatorEmail() {
  if (import.meta.env.VUE_NODE_ENV === 'development' || import.meta.env.VUE_NODE_ENV === 'test') {
    return 'hugues.bocquet@yago.be'
  } else {
    try {
      const token = getIdToken()
      return decode(token).email
    } catch (e) {
      return null
    }
  }
}
