import updateList from '../../_helpers/updateList'
import { belonging } from './services'
import { ADD_BELONGING, UPDATE_BELONGING } from './constants'

export default {
  namespaced: true,
  mutations: {
    [ADD_BELONGING](state, payload) {
      if (Array.isArray(state)) {
        state.push(payload)
      } else {
        state = [payload]
      }
    },
    [UPDATE_BELONGING](state, payload) {
      updateList({ data: state, payload, idArg: 'uuid' })
    }
  },
  actions: {
    async save({ state, commit }, { isNew, ...payload }) {
      const { success, message, data } = await (isNew ? belonging.create(payload) : belonging.update(payload))
      if (success) commit(isNew ? ADD_BELONGING : UPDATE_BELONGING, data)
      return { success, message, data }
    },
    async destroy({ state, commit }, data) {
      const { success, message } = await belonging.delete(data)
      return { success, message }
    }
  }
}
