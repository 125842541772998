export default {
  title: 'Opérateurs',
  labels: {
    email: 'Email',
    team: 'Équipe',
    role: 'Rôle',
    expertises: 'Expertises',
    change_team: "Changer l'opérateur d'équipe",
    select_team: 'Filtrer par équipe',
    all_teams: 'Toutes',
    no_team: 'Sans équipe',
    change_expertises: "Modifier les expertises de l'opérateur"
  },
  roles: {
    team_leader: 'Team Leader',
    client_champion: 'Client Champion',
    sales_ninja: 'Sales Ninja',
    claims_manager: 'Claims Manager',
    customer_care_manager: 'CC Manager',
    null: '-'
  },
  categories: {
    individual: 'Particulier',
    self_employed: 'Indépendant',
    b2b: 'B2B'
  },
  expertises: {
    individual_car: 'Auto',
    individual_motorbike: 'Moto',
    individual_house: 'Habitation',
    individual_life: 'Vie',
    individual_hospitalization: 'Hospitalisation',
    self_employed_car: 'Auto',
    self_employed_motorbike: 'Moto',
    self_employed_hospitalization: 'Hospitalisation',
    self_employed_life: 'Vie',
    self_employed_fire: 'Incendie',
    self_employed_work_accident: 'AT',
    self_employed_civil_liability: 'RC',
    self_employed_legal_expenses: 'PJ',
    b2b_corporate_iard: 'IARD',
    b2b_corporate_life: 'Vie'
  }
}
