<template>
  <div class="spinner">
    <img src="../assets/loading.svg" alt="loading" />
  </div>
</template>

<script>
import { setIdToken, setAccessToken, getCallbackRoute, operatorEmail } from '@/services/auth'
import { gamification } from '@/services/gamification'

import * as Sentry from '@sentry/vue'

export default {
  mounted() {
    this.$nextTick(() => {
      setAccessToken()
      setIdToken()
      Sentry.setUser({ email: operatorEmail() })
      gamification({ domain: 'connection', action: 'login' }).then((_) => {
        const route = getCallbackRoute()
        route ? this.$router.push(route) : (window.location.href = '/')
      })
    })
  }
}
</script>

<style lang="scss">
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img {
    height: 150px;
  }
}
</style>
