<template>
  <el-row :gutter="20" style="text-align: center" v-if="medalScores">
    <el-col class="medal" :span="8">
      <font-awesome-icon
        :icon="['fa', 'medal']"
        :class="administratorMedal ? salesMedal : 'locked'"></font-awesome-icon>
      <h2 v-if="administratorMedal">{{ $t(`gamification.administrator_medal.${administratorMedal}`) }}</h2>
    </el-col>
    <el-col class="medal" :span="8">
      <font-awesome-icon :icon="['fa', 'trophy']" :class="salesMedal ? salesMedal : 'locked'"></font-awesome-icon>
      <h2 v-if="salesMedal">{{ $t(`gamification.sales_medal.${salesMedal}`) }}</h2>
    </el-col>
    <el-col class="medal" :span="8">
      <font-awesome-icon
        :icon="['fa', 'crown']"
        :class="customerServiceMedal ? customerServiceMedal : 'locked'"></font-awesome-icon>
      <h2 v-if="customerServiceMedal">{{ $t(`gamification.customer_service_medal.${customerServiceMedal}`) }}</h2>
    </el-col>
    <hr />
  </el-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('gamification', [
      'sepaMandates',
      'contracts',
      'amendments',
      'smsMessages',
      'wins',
      'unpaids',
      'transfers',
      'terminations'
    ]),
    medalScores() {
      const medals = [this.administratorMedal, this.salesMedal, this.customerServiceMedal]
      return medals.map((medal) => (medal === 'bronze' ? 1 : medal === 'silver' ? 2 : medal === 'gold' ? 3 : ''))
    },
    administratorScore() {
      return (
        (this.sepaMandates + this.contracts + this.amendments + this.unpaids + this.transfers + this.terminations) /
        1.25
      )
    },
    salesScore() {
      return this.wins
    },
    customerServiceScore() {
      return this.smsMessages
    },
    administratorMedal() {
      return this.medalType(this.administratorScore)
    },
    salesMedal() {
      return this.medalType(this.salesScore)
    },
    customerServiceMedal() {
      return this.medalType(this.customerServiceScore)
    }
  },
  watch: {
    medalScores: {
      immediate: true,
      handler() {
        this.$emit('updateMedals', this.medalScores)
      }
    }
  },
  methods: {
    medalType(medalScore) {
      switch (true) {
        case medalScore > 200:
          return 'gold'
        case medalScore > 75:
          return 'silver'
        case medalScore > 10:
          return 'bronze'
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/style/element-variables.scss';

.medal {
  font-size: 65px;
  text-align: center;
  padding: 8px;
}
.gold {
  color: $--gold;
}
.silver {
  color: $--silver;
}
.bronze {
  color: $--bronze;
}
</style>
