import API from '@/services/api'

export const amendment = {
  create({ contractUuid, data }) {
    return API.post(`/sw/amendments?contract_uuid=${contractUuid}`, data)
  },
  update({ contractUuid, uuid, data }) {
    return API.put(`/sw/amendments/${uuid}`, data)
  },
  post({ uuid, method }) {
    return API.post(`/sw/amendments/${uuid}/${method}`)
  },
  show({ uuid, format }) {
    return API.get(`/sw/amendments/${uuid}`, { format })
  }
}
