import * as Sentry from '@sentry/vue'

import router from '../router'

export default function withSentry(app) {
  // Disable Sentry to check if it provides any value to the project
  // eslint-disable-next-line no-constant-condition
  if (false && ['staging', 'production'].includes(import.meta.env.VUE_NODE_ENV)) {
    Sentry.init({
      app,
      dsn: 'https://4025ef6d6d714b8ca19d7b8cd4956559@o61191.ingest.sentry.io/1444757',
      environment: import.meta.env.VUE_NODE_ENV,
      // The ResizeObserver error is a benign bug that happens whenever someone
      // resizes the window. It is due to a dependency of element ui,
      // ResizeObserverPolyfill, which cannot resize it efficiently so it triggers a bug.
      // This is related to the use of el-table.
      // As seen here https://github.com/ElemeFE/element/issues/12866, collaborators
      // of elment-ui advise to ignore the bug, which is why we whitelist it in Sentry.
      ignoreErrors: ['[ElementForm]unpected width', 'ResizeObserver loop'],
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          shouldCreateSpanForRequest(currentUrl) {
            return [
              'https://staging-ardor.seraphin.be',
              'https://ardor.seraphin.be',
              'https://staging-ardor.yago.be',
              'https://ardor.yago.be'
            ].some((allowedUrl) => new URL(currentUrl).origin === new URL(allowedUrl).origin)
          }
        })
      ],
      tracesSampleRate: 0.25 // 25% of the transaction events will be forwarded to Sentry
    })
  } else {
    /* eslint-disable no-console */
    console.debug('Not using Sentry, environment is', import.meta.env.VUE_NODE_ENV)
  }
  return app
}
