import API from '@/services/api'

export const contractRequest = {
  post({ uuid, format, method, data = {} }) {
    return API.post(`/sw/contract_requests/${uuid}/${method}?format=${format}`, data)
  },
  create(data) {
    return API.post('/sw/contract_requests?format=tiny', data)
  },
  update(data) {
    return API.put(`/sw/contract_requests/${data.uuid}?format=standard`, data)
  },
  show({ uuid, format }) {
    return API.get(`/sw/contract_requests/${uuid}`, { format })
  }
}
