<template>
  <el-select class="locale-selector" v-model="$i18n.locale" @change="setLocale($i18n.locale)">
    <el-option
      :value="locale"
      v-for="locale in $i18n.availableLocales"
      :key="locale"
      :label="locale"
      style="text-transform: uppercase !important"></el-option>
  </el-select>
</template>

<script setup>
const setLocale = (locale) => {
  localStorage.setItem('localeSelected', locale)
}
</script>

<style>
.locale-selector {
  font-weight: 600;
  transform: translateY(2px);
  margin: 0 !important;
  width: 50px !important;
}
.locale-selector .el-input__wrapper {
  padding: 0 5px 0 6px !important;
}
.locale-selector * {
  text-transform: uppercase !important;
  border: none !important;
  font-weight: 600 !important;
}
</style>
