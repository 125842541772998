import { SET_LOADING, SET_FULL_PACKAGE_BUNDLE } from './constants'
import { fullPackageBundle } from './services'
import initialState from './initialState'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.meta.loading = payload
    },
    [SET_FULL_PACKAGE_BUNDLE](state, payload) {
      state = Object.assign(state, payload)
    }
  },
  actions: {
    async update({ state, commit }, payload) {
      const { success, message, data } = await fullPackageBundle.update(payload)
      if (success) commit(SET_FULL_PACKAGE_BUNDLE, data)
      return { success, message }
    },
    async fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      const { success, data } = await fullPackageBundle.show(params)
      commit(SET_FULL_PACKAGE_BUNDLE, data)
      commit(SET_LOADING, false)
      return { success, data }
    },
    async requestReview({ state, commit }, payload) {
      const { success, message } = await fullPackageBundle.requestReview(payload)
      return { success, message }
    }
  }
}
