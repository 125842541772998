import { documents } from '../relatedDocument/service'
export default {
  namespaced: true,
  state: {
    car: {
      documents: [],
      loading: false,
      errors: []
    },
    motorbike: {
      documents: [],
      loading: false,
      errors: []
    },
    people: {
      documents: [],
      loading: false,
      errors: []
    }
  },
  mutations: {
    SET_LOADING(state, { objectType, loading }) {
      state[objectType].loading = loading
    },
    SET_DOCUMENTS(state, { data, objectType }) {
      state[objectType].documents = data
      state[objectType].loading = false
      state[objectType].errors = []
    },
    CLEAR_DOCUMENTS(state, objectType) {
      state[objectType].documents = []
    },
    SET_ERRORS(state, { objectType, errors }) {
      state[objectType].errors = errors
      state[objectType].loading = false
    },
    UPDATE_DOCUMENT(state, { objectType, payload }) {
      const index = state[objectType].documents.findIndex((_, iteration) => iteration === payload?.index)
      if (index !== -1) {
        const documents = state[objectType].documents.map((document, iteration) =>
          iteration === index ? Object.assign({}, payload.document) : document
        )
        state[objectType].documents = Object.assign([], documents)
      }
      state[objectType].loading = false
    }
  },
  actions: {
    async fetchDocuments({ state, commit }, { url, objectType }) {
      commit('SET_LOADING', { objectType, loading: true })
      const { success, errors, data } = await documents.retrieve(url)
      if (success) {
        commit('SET_DOCUMENTS', { objectType, data })
      } else {
        commit('SET_ERRORS', { objectType, errors })
        commit('CLEAR_DOCUMENTS', objectType)
      }
      return { success, errors }
    },
    async removeDocumentFile({ commit }, { objectType, params }) {
      commit('SET_LOADING', { objectType, loading: true })
      const { success, errors, data } = await documents.removeDocumentFile({
        id: params.id,
        document_file_id: params.document_file_id
      })

      if (success) {
        commit('UPDATE_DOCUMENT', { objectType, payload: { document: data, index: params.document_index } })
      } else {
        commit('SET_ERRORS', { objectType, errors })
      }

      return { success, errors }
    },
    async uploadDocumentFile({ state, commit }, { objectType, params: { document, file, documentIndex, url } }) {
      commit('SET_LOADING', { objectType, loading: true })
      const { success, errors, data } = await documents.uploadDocumentFile(url, {
        file: file,
        subjectId: document.subjectId,
        documentableType: document.documentableType,
        subjectType: document.subjectType,
        contractRequestUuid: state.contractRequestUuid
      })

      if (success) {
        commit('UPDATE_DOCUMENT', { objectType, payload: { document: data, index: documentIndex } })
      } else {
        commit('SET_ERRORS', { objectType, errors })
      }

      return { success, errors }
    }
  }
}
