import { requiredDocuments } from '@/store/requiredDocuments/service'

/**
 * This represents the data structure of a document
 * @typedef {{
 *  contractRequestUuid?: string,
 *  name?: string,
 *   documentType?:
 *        | "DriverLicense"
 *        | "IdentityCard"
 *        | "AccidentRecord"
 *        | "PlateRegistrationCertificate"
 *        | "OdometerPicture"
 *        | "PurchaseInvoice"
 *        | "CurrentContract";
 *
 * subjectId?: string,
 * subjectType?:
 *       | "PolicyOwner"
 *       | "Car"
 *       | "Motorbike"
 *       | "ContractRequest"
 *       | "Contract";
 *
 * requires: {
 *   minFileCount?: number,
 *   mimeTypes?: Array<string>,
 *   validation?: boolean,
 *   signature?: boolean
 * },
 *
 * validations?: Array<{
 *   id?: string,
 *   status?: "validated" | "rejected" | "pending",
 *   errors?: null | string
 * }>,
 *
 * examples?: Array<{
 *   title?: string,
 *   url?: string,
 *   description?: string
 * }>,
 *
 * files?: Array<{
 *   s3Key?: string,
 *   id?: string,
 * }>
 *
 * }} RequiredDocument
 */

export default {
  namespaced: true,
  state: {
    /** @type {RequiredDocument} */
    requiredDocuments: [],
    /** @type {string || null} */
    contractRequestUuid: null,
    /** @type {boolean} */
    loading: false,
    reminderNotifications: [],
    errors: []
  },
  mutations: {
    SET_CONTRACT_REQUEST_UUID(state, contractRequestUuid) {
      state.contractRequestUuid = contractRequestUuid
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ERRORS(state, errors) {
      state.errors = errors
      state.loading = false
    },
    /**
     * Mutate the state with documents
     * @param state
     * @param {RequiredDocument} documents[]
     */
    SET_DOCUMENTS(state, documents) {
      state.requiredDocuments = documents
      state.loading = false
      state.errors = []
    },
    RESET_DOCUMENTS(state) {
      state.requiredDocuments = []
    },
    /**
     * Update a document in the state
     * @param state
     * @param payload
     */
    UPDATE_DOCUMENT(state, payload) {
      const index = state.requiredDocuments.findIndex((_, iteration) => iteration === payload?.index)
      if (index !== -1) {
        const requiredDocuments = state.requiredDocuments.map((document, iteration) =>
          iteration === index ? Object.assign({}, payload.document) : document
        )
        state.requiredDocuments = Object.assign([], requiredDocuments)
      }
    }
  },
  actions: {
    resetStore({ commit }) {
      commit('RESET_DOCUMENTS')
      commit('SET_CONTRACT_REQUEST_UUID', null)
    },
    setContractRequestUuid({ commit }, contractRequestUuid) {
      commit('SET_CONTRACT_REQUEST_UUID', contractRequestUuid)
    },
    async fetchRequiredDocuments({ state, commit }) {
      commit('SET_LOADING', true)
      const { success, errors, data } = await requiredDocuments.index(state.contractRequestUuid)
      if (success) {
        commit('SET_DOCUMENTS', data)
      } else {
        commit('SET_ERRORS', errors)
      }

      return { success, errors }
    },
    async removeDocumentFile({ commit }, params) {
      commit('SET_LOADING', true)
      const { success, errors, data } = await requiredDocuments.removeDocumentFile({
        id: params.id,
        document_file_id: params.document_file_id
      })

      if (success) {
        commit('UPDATE_DOCUMENT', { document: data, index: params.document_index })
      } else {
        commit('SET_ERRORS', errors)
      }

      return { success, errors }
    },
    async uploadDocumentFile({ state, commit }, { document, file, documentIndex }) {
      commit('SET_LOADING', true)
      const { success, errors, data } = await requiredDocuments.uploadDocumentFile(
        {
          file: file,
          subjectId: document.subjectId,
          documentableType: document.documentableType,
          subjectType: document.subjectType,
          contractRequestUuid: state.contractRequestUuid
        },
        state.contractRequestUuid
      )
      if (success) {
        commit('UPDATE_DOCUMENT', { document: data, index: documentIndex })
      } else {
        commit('SET_ERRORS', errors)
      }

      return { success, errors }
    },
    async sendUploadSMSReminder({ state, commit }) {
      const { success, errors } = await requiredDocuments.sendUploadNotification({
        contractRequestUuid: state.contractRequestUuid,
        medium: 'text_message',
        type: 'upload_reminder'
      })

      if (success) {
        commit('SET_LOADING', false)
      } else {
        commit('SET_ERRORS', errors)
      }

      return { success, errors }
    },
    async sendUploadEmailRequest({ state, commit }) {
      const { success, errors } = await requiredDocuments.sendUploadNotification({
        contractRequestUuid: state.contractRequestUuid,
        medium: 'email',
        type: 'upload_request'
      })

      if (success) {
        commit('SET_LOADING', false)
      } else {
        commit('SET_ERRORS', errors)
      }

      return { success, errors }
    },
    async sendUploadEmailReminder({ state, commit }) {
      const { success, errors } = await requiredDocuments.sendUploadNotification({
        contractRequestUuid: state.contractRequestUuid,
        medium: 'email',
        type: 'upload_reminder'
      })

      if (success) {
        commit('SET_LOADING', false)
      } else {
        commit('SET_ERRORS', errors)
      }

      return { success, errors }
    }
  }
}
