export const calculator = {
  methods: {
    percentage(numerator, denominator) {
      return denominator > 0 ? Math.min((100 * numerator) / denominator, 100) : 0
    },
    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx)
    }
  }
}
