import initialState from '../_helpers/initialState'
import { SET_LOADING, SET_CONTRACT_REQUESTS } from './constants'
import { contractRequest } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {},
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_CONTRACT_REQUESTS](state, payload) {
      state.data = payload
    }
  },
  actions: {
    fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      contractRequest.index(params).then(({ data }) => {
        commit(SET_CONTRACT_REQUESTS, data)
        commit(SET_LOADING, false)
      })
    }
  }
}
