export default {
  attention_aid: 'Assistance à la concentration',
  automatic_emergency_braking: "Freinage automatique d'urgence",
  brand: 'Marque',
  catalogue_value: 'Valeur catalogue',
  chassis_number: 'Numéro de chassis',
  convertible: 'Décapotable',
  entry_into_circulation: 'Entrée dans la circulation',
  febiac_id: 'Febiac ID',
  fuel_type: 'Type de carburant',
  fuel_types: {
    diesel: 'Diesel',
    electric: 'Electrique',
    hybrid_petrol_electric: 'Hybride',
    lpg: 'GPL',
    null: '-',
    petrol: 'Essence',
    unknown: 'Inconnu'
  },
  invoice_value: 'Prix facture',
  jeep: 'Jeep',
  lane_departure: 'Correcteur de trajectoire',
  licence_plate: "Plaque d'immatriculation",
  model: 'Modèle',
  oldtimer: 'Voiture ancêtre (+30 ans)',
  parking_aid: 'Assistance au parking',
  blind_spot_monitoring: 'Avertissement d’angle mort',
  adaptative_cruise_control: 'Régulateur de vitesse adaptatif (ou dynamique)',
  pedestrian_detection: 'Assistance anti-collision piétons et cyclistes',
  power: 'Puissance',
  power_categories: {
    '50_to_75_kw': '50kW - 75kW',
    '76_to_100_kw': '76kW - 100kW',
    less_than_50_kw: 'Moins de 50kW',
    more_than_100_kw: '100kW +',
    null: '-',
    unknown: 'Inconnu'
  },
  power_category: 'Catégorie de puissance',
  seats: 'Nombre de sièges (hors conducteur)',
  sport: 'Voiture de sport',
  submodel: 'Sous-modèle',
  title: 'Détails de la voiture',
  version: 'Version'
}
