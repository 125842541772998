import API from '@/services/api'

export const terminations = {
  index(params) {
    const userUuid = params ? params.user_uuid : ''
    return API.get(`/terminations/${userUuid}`)
  },
  create(data) {
    return API.post('/terminations', data)
  },
  update(data) {
    return API.put('/terminations', data)
  }
}
