import API from '@/services/api'

export const sepaMandates = {
  index(params) {
    const userUuid = params ? params.user_uuid : ''
    return API.get(`/sepa-mandates/${userUuid}`)
  },
  create(data) {
    return API.post('/sepa-mandates', data)
  },
  update(data) {
    return API.put('/sepa-mandates', data)
  }
}
