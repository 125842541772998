import API from '@/services/api'

export const accidentRecords = {
  index({ user_uuid: userUuid }) {
    return API.get(`/accident-records/${userUuid}`)
  },
  create(data) {
    return API.post('/accident-records', data)
  },
  update(data) {
    return API.put('/accident-records', data)
  },
  destroy(data) {
    return API.delete('/accident-records', data)
  }
}
