import initialState from '../../_helpers/initialState'
import { SET_NPS, SET_EVENTS_LOADING, POPULATE_EVENTS, RESET_EVENTS } from './constants'
import { nps, emails, calls, sendinblue, twilio } from './services'
import dayjs from 'dayjs'

export default {
  namespaced: true,
  state: {
    nps: [],
    events: { ...initialState }
  },
  getters: {
    history(state) {
      return [...state.events.data].sort((a, b) =>
        a.occurredAt < b.occurredAt ? 1 : b.occurredAt < a.occurredAt ? -1 : 0
      )
    },
    nps(state) {
      if (!state.nps || !state.nps.length) {
        return null
      } else {
        const lastNps = [...state.nps].sort((a, b) =>
          a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
        )[0]
        const { score, updatedAt, userComment } = lastNps

        let type, label
        if (score > 8) {
          type = 'success'
          label = 'Promoteur'
        } else if (score > 6) {
          type = 'primary'
          label = 'Neutre'
        } else {
          type = 'danger'
          label = 'Détracteur'
        }

        const summary = `NPS de ${score} attribué le ${dayjs(updatedAt).format('D/MM/Y')}. Commentaires: ${userComment}`

        return { ...lastNps, type, label, summary }
      }
    }
  },
  mutations: {
    [SET_NPS](state, payload) {
      state.nps = payload
    },
    [SET_EVENTS_LOADING](state, payload) {
      state.events.loading = payload
    },
    [RESET_EVENTS](state) {
      state.events.data = []
    },
    [POPULATE_EVENTS](state, payload) {
      state.events.data.push(...payload)
    }
  },
  actions: {
    async unsubscribeFromSendinblue({ rootState }) {
      if (rootState.user.externalIds.sendinblueId) {
        const { success, message } = await sendinblue.unsubscribe(rootState.user.email)
        return { success, message }
      }
    },
    async fetchSendinblueEmail(state, uuid) {
      const data = await sendinblue.email(uuid)
      return data
    },
    fetchNps({ commit, rootState }) {
      nps(rootState.user).then(({ data }) => {
        const filteredNPS = data.filter((nps) => nps.score)
        commit(SET_NPS, filteredNPS)
      })
    },
    fetchEvents({ commit, rootState }) {
      commit(SET_EVENTS_LOADING, true)
      commit(RESET_EVENTS)
      emails(rootState.user).then(({ data }) => {
        if (data && data.length) {
          data = data.map((e) => ({
            ...e,
            occurredAt: dayjs(e.receivedAt).valueOf(),
            type: 'email',
            title: e.subject,
            direction: 'outbound'
          }))
          commit(POPULATE_EVENTS, data)
        }
        commit(SET_EVENTS_LOADING, false)
      })
      sendinblue.emails(rootState.user).then((data) => {
        if (data && data.transactionalEmails && data.transactionalEmails.length) {
          data = data.transactionalEmails.map((e) => ({
            ...e,
            occurredAt: dayjs(e.date).valueOf(),
            type: 'sendinblueEmail',
            title: e.subject,
            direction: 'outbound',
            uuid: e.uuid
          }))
          commit(POPULATE_EVENTS, data)
        }
        commit(SET_EVENTS_LOADING, false)
      })
      calls(rootState.user).then(({ data }) => {
        if (data && data.length) {
          data = data.map((c) => ({
            ...c,
            occurredAt: c.startedAt * 1000,
            type: 'call',
            direction: c.direction,
            title: `(${c.user ? c.user.name : ''} ${dayjs.utc(c.duration * 1000).format('HH:mm:ss')})`
          }))
          commit(POPULATE_EVENTS, data)
        }
        commit(SET_EVENTS_LOADING, false)
      })
      twilio(rootState.user).then(({ data }) => {
        if (data && data.length) {
          data = data.map((m) => ({
            ...m,
            type: 'sms',
            occurredAt: dayjs(m.dateSent).valueOf(),
            direction: m.direction,
            title: m.body
          }))
          commit(POPULATE_EVENTS, data)
        }
        commit(SET_EVENTS_LOADING, false)
      })
    }
  }
}
