export default {
  bundled_payment_reminders: {
    amount_due: 'Verschuldigd bedrag',
    types: ['Herinnering 1', 'Herinnering 2', 'Herinnering 3', 'Stopzetting van het betalingsplan']
  },
  bundled_payment_reminders_unpaids_doc:
    'Vragen over e-mails en sms\'en in verband met wanbetalingen? Raadpleeg de <a target="_blank" href="https://seraphin.slite.com/api/s/note/4g9DSbAbkAXskUV84AYty1/Impayes-plan-de-paiement">documentatie</a> hierover.',
  doc: 'Vragen over e-mails en sms\'en in verband met wanbetalingen? Raadpleeg de <a target="_blank" href="https://seraphin.slite.com/api/s/note/RkxKwYJJ5PdWQ8bJcwuj57/Impayes">documentatie</a> hierover.',
  edit: {
    title: 'Het onbetaalde bedrag bewerken'
  },
  help: "Je vindt <a href='https://docs.google.com/document/d/1pCnXMefQAFOYBfm61r184F_1jdlos2KDGuTQ9yWcu_s/edit' target='_blank'>hier</a> een beschrijving van alle berichten en acties gerelateerd aan wanbetalingen.",
  new_bundled_payment_unpaid:
    '💸 Nieuwe wanbetaling voor betalingsplan (alleen mogelijk voor Baloise betalingsplannen)',
  new_unpaid: '💸 Nieuw wanbetaling',
  reminders: {
    cease_and_desist_date: 'Datum van ingebrekestelling',
    edit_unpaid: 'Wijzig het verschuldigde bedrag: €{amountDue}',
    message: '<strong>Herinnering nr.{action} verzonden naar de klant</strong><br>{message}',
    title: 'Een nieuwe herinnering versturen',
    types: [
      'Herinnering 1',
      'Herinnering 2',
      'Herinnering 3 - Ingebrekestelling',
      'Herinnering 4 - Opschorting',
      'Opzegging'
    ]
  },
  table: {
    columns: {
      tags: {
        danger: 'Binnenkort opgezegd',
        success: 'Eerste herinneringen',
        warning: 'Contract opgezegd'
      }
    }
  },
  title: 'Wanbetalingen'
}
