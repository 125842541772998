import API from '@/services/api'

export const bundledPaymentUnpaids = {
  index(params) {
    const userUuid = params ? params.user_uuid : ''
    return API.get(`/bundled-payments-unpaids/${userUuid}`)
  },
  create(data) {
    return API.post('/bundled-payments-unpaids', data)
  },
  update(data) {
    return API.put('/bundled-payments-unpaids', data)
  }
}

export const bundledPaymentReminders = {
  create(data) {
    return API.post('/bundled-payments-unpaids/reminders', data)
  }
}
