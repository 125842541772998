export default {
  digital_bag: {
    label: 'Appareils digitaux',
    covers: {
      omnium: 'Omnium'
    }
  },
  van: {
    label: 'Camionnette',
    covers: {
      assistance_to_people: 'Assistance aux personnes',
      assistance: 'Assistance véhicule',
      full_omnium: 'Grande omnium',
      small_omnium: 'Petite omnium',
      legal_expenses: 'Protection juridique',
      pj: 'Protection juridique',
      civil_liabilities: 'RC',
      rc: 'RC',
      driver_safety: 'Sécurité du conducteur (liée au véhicule)'
    }
  },
  family: {
    label: 'Famille',
    covers: {
      pet: 'Assurance chien et chat',
      driver: 'Assurance conducteur',
      heritage: 'Assurance droits de succession',
      civil_liabilities: 'Assurance familiale',
      rc: 'Assurance familiale',
      outpatient_fee: 'Assurance frais ambulatoires',
      baby_sitting: "Assurance garde d'enfants",
      hospitalization: 'Assurance hospitalisation',
      funeral: 'Assurance obsèques',
      guaranteed_income: 'Assurance revenus garantis',
      dental_care: 'Assurance soins dentaires',
      e_reputation: 'E-réputation familiale',
      legal_expenses: 'Protection juridique familiale',
      pj: 'Protection juridique familiale',
      boat_civil_liability: 'Responsabilité civile bateau'
    }
  },
  house_rental: {
    label: 'Bâtiment (location)',
    covers: {
      assistance: 'Assistance locataire',
      content: 'Contenu locataire',
      legal_expenses: 'Protection juridique locataire',
      pj: 'Protection juridique locataire',
      tenant_civil_liability: 'Responsabilité civile locataire',
      theft: 'Vol locataire',
      jewerly: 'Bijoux'
    }
  },
  house: {
    label: 'Bâtiment (propriété)',
    covers: {
      waiver_of_recourse: 'Abandon de recours',
      assistance: 'Assistance',
      indirect_losses: 'Assurance perte indirectes',
      mortgage: 'Assurance solde restant dû',
      construction: 'Assurance tous risques chantiers',
      content: 'Contenu',
      residence: 'Habitation',
      rent_guarantee: 'Loyers impayés',
      garden: 'Pack jardin + bijoux',
      legal_expenses: 'Protection juridique',
      pj: 'Protection juridique',
      rent_guarantee_legal_expenses: 'Protection juridique loyers impayés',
      theft: 'Vol',
      jewerly: 'Bijoux'
    }
  },
  independent: {
    label: 'Professionnel',
    covers: {
      work_accident: 'Assurance accidents du travail',
      hospitalization: 'Assurance hospitalisation',
      construction: 'Assurance tous risques chantiers',
      e_reputation: 'E-reputation professionnelle',
      pj_professional: 'Protection juridique professionnelle',
      rc_exploitation: 'RC exploitation',
      rc_professional: 'RC professionnelle',
      post_delivery_civil_liability: 'Responsabilité civile après livraison',
      business_manager_civil_liability: "Responsabilité civile dirigeant d'entreprise",
      entrusted_goods_civil_liability: 'Responsabilité civile objets confiés',
      events_civil_liability: 'Responsabilité civile événements',
      objective_liability: 'Responsabilité objective',
      business_interruption: 'Pertes d’exploitation',
      volontary_liablity: 'RC volontaires',
      machinery_breakdown: 'Bris de machine',
      decennial_liability: 'RC décennale',
      credit_insurance: 'Assurance crédit',
      own_account_transport: 'Transport pour compte propre',
      third_party_transport: 'Transport pour compte de tiers - CMR',
      container_liability: 'Responsabilité conteneur/semi-remorque',
      cyber: 'Assurance Cyber',
      group_hospitalization: 'Assurance groupe hospitalisation'
    }
  },
  motorbike: {
    label: 'Moto',
    covers: {
      assistance_to_people: 'Assistance aux personnes',
      assistance: 'Assistance véhicule',
      full_omnium: 'Grande omnium',
      small_omnium: 'Petite omnium',
      legal_expenses: 'Protection juridique',
      pj: 'Protection juridique',
      civil_liabilities: 'RC',
      rc: 'RC',
      driver_safety: 'Sécurité du conducteur (liée au véhicule)',
      motorcyclist_equipment: 'Equipement du motard'
    }
  },
  motorhome: {
    label: 'Motorhome',
    covers: {
      assistance_to_people: 'Assistance aux personnes',
      assistance: 'Assistance véhicule',
      full_omnium: 'Grande omnium',
      small_omnium: 'Petite omnium',
      legal_expenses: 'Protection juridique',
      pj: 'Protection juridique',
      civil_liabilities: 'RC',
      rc: 'RC',
      driver_safety: 'Sécurité du conducteur (liée au véhicule)'
    }
  },
  pension: {
    label: 'Pension',
    covers: {
      life_group: 'Assurance groupe vie',
      pension_agreement_self_employed: 'Convention de pension travailleurs indépendants',
      individual_pension_commitment: 'Engagement individuel de pension',
      non_tax_savings: 'Épargne non fiscal',
      pension_savings: 'Épargne pension',
      long_term_savings: 'Épargne à long terme',
      free_supplementary_pension: 'Pension libre complémentaire pour indépendants',
      inami: 'Inami',
      bonus_plan: 'Plan bonus'
    }
  },
  personal: {
    label: 'Vie active',
    covers: {
      foresight: 'Assurance accident',
      incident: 'Assurance accident de la vie privée',
      hunting_legal_expenses: 'Protection juridique chasse',
      hunting_civil_liability: 'Responsabilité civile chasse',
      organizer_civil_liability: 'Responsabilité civile organisateur'
    }
  },
  car: {
    label: 'Voiture',
    covers: {
      assistance_to_people: 'Assistance aux personnes',
      assistance: 'Assistance véhicule',
      full_omnium: 'Grande omnium',
      small_omnium: 'Petite omnium',
      legal_expenses: 'Protection juridique',
      pj: 'Protection juridique',
      civil_liabilities: 'RC',
      rc: 'RC',
      driver_safety: 'Sécurité du conducteur (liée au véhicule)',
      glass_breakage: 'Bris de vitre'
    }
  },
  travel: {
    label: 'Voyages',
    covers: {
      assistance: 'Assistance voyage',
      accident: 'Assurance accidents de voyage',
      cancellation: 'Assurance annulation de voyage',
      luggage: 'Assurance bagages'
    }
  },
  bicycle: {
    label: 'Vélo',
    covers: {
      assistance: 'Assistance',
      damages: 'Dommages',
      incident: 'Individuelle accident',
      theft: 'Vol'
    }
  },
  boat: {
    label: 'Bateau',
    covers: {
      assistance: 'Assistance véhicule',
      full_omnium: 'Grande omnium',
      legal_expenses: 'Protection juridique',
      pj: 'Protection juridique',
      civil_liabilities: 'RC',
      rc: 'RC'
    }
  },
  'push-scooter': {
    label: 'Trottinette',
    covers: {
      assistance: 'Assistance véhicule',
      full_omnium: 'Grande omnium',
      legal_expenses: 'Protection juridique',
      pj: 'Protection juridique',
      civil_liabilities: 'RC',
      rc: 'RC',
      driver_safety: 'Sécurité du conducteur (liée au véhicule)'
    }
  }
}
