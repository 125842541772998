export default {
  title: 'Operator',
  labels: {
    email: 'Email',
    team: 'Team',
    role: 'Rol',
    expertises: 'Expertise',
    change_team: 'Operator van team wijzigen',
    select_team: 'Filteren op team',
    all_teams: 'Alle',
    no_team: 'Geen team',
    change_expertises: 'De expertise van de operator wijzigen'
  },
  roles: {
    team_leader: 'Team Leader',
    client_champion: 'Client Champion',
    sales_ninja: 'Sales Ninja',
    claims_manager: 'Claims Manager',
    customer_care_manager: 'CC Manager',
    null: '-'
  },
  categories: {
    individual: 'Particulier',
    self_employed: 'Zelfstandige',
    b2b: 'B2B'
  },
  expertises: {
    individual_car: 'Auto',
    individual_motorbike: 'Motor',
    individual_house: 'Woning',
    individual_life: 'Leven',
    individual_hospitalization: 'Hospitalisatie',
    self_employed_car: 'Auto',
    self_employed_motorbike: 'Motor',
    self_employed_hospitalization: 'Hospitalisatie',
    self_employed_life: 'Leven',
    self_employed_fire: 'Brand',
    self_employed_work_accident: 'AO',
    self_employed_civil_liability: 'BA',
    self_employed_legal_expenses: 'RB',
    b2b_corporate_iard: 'BOAR',
    b2b_corporate_life: 'Leven'
  }
}
