import initialState from '../_helpers/initialState'
import api from '@/services/api_v2'

const ADMIN_ROLE = 'admin'

export default {
  namespaced: true,
  state: {
    ...initialState,
    operatorIsAdmin: false
  },
  mutations: {
    SET_OPERATOR_IS_ADMIN(state, value) {
      state.operatorIsAdmin = value === ADMIN_ROLE
    },
    SET_OPERATOR(state, payload) {
      state.data = payload
    }
  },
  actions: {
    async fetchOperator({ commit }, params) {
      const response = await api.getOperator(params)
      if (response.data) {
        commit('SET_OPERATOR_IS_ADMIN', response.data.role)
        commit('SET_OPERATOR', response.data)
      }
    }
  }
}
