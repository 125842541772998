<template>
  <div class="leveling_progress_bar">
    <el-row>
      <el-col :span="16">
        <br />
        <h2>
          {{
            $t('gamification.levels.level', {
              currentLevel: currentLevel,
              operatorName: operatorLevelName
            })
          }}
        </h2>
      </el-col>
      <el-col :span="6">
        <br />
        <h2 style="text-align: right">({{ Math.round(totalExp) }}{{ nextLevelExperienceRequired }}) XP</h2>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-progress
        class="leveling_progress_bar_item"
        :stroke-width="18"
        :percentage="Math.round(levelPercentage)"
        :text-inside="true"
        :show-text="true"></el-progress>
    </el-row>
    <br />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import levels from '@/assets/gamificationLevelExp.json'
import modifiers from '@/assets/gamificationExperienceModifiers.json'
import names from '@/assets/levelsNaming.json'
export default {
  props: {
    medalScores: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('gamification', ['logins']),
    experience() {
      const gamification = JSON.parse(JSON.stringify(this.$store.state.gamification))
      delete gamification.meta
      delete gamification.loading
      delete gamification.operator
      Object.keys(gamification).forEach((gamificationField) => {
        gamification[gamificationField] *= modifiers[gamificationField]
      })
      return Object.values(gamification).reduce((total, value) => total + value, 0)
    },
    totalExp() {
      return this.experience * this.baseModifier()
    },
    nextLevel() {
      const levelsAboveCurrentUserLevel = []
      for (const level in levels) {
        if (parseInt(levels[level], 10) > this.totalExp) levelsAboveCurrentUserLevel.push(level)
      }
      if (levelsAboveCurrentUserLevel[0] == null) return 51
      return levelsAboveCurrentUserLevel[0]
    },
    currentLevel() {
      return this.nextLevel - 1
    },
    operatorLevelName() {
      return names[`${this.currentLevel}`]
    },
    nextLevelExperienceRequired() {
      if (this.nextLevel === 51) return ''
      return `/ ${levels[`${this.nextLevel}`]}`
    },
    levelPercentage() {
      if (!levels[this.nextLevel]) return 100
      const totalExperienceForNextLevel = parseInt(levels[this.nextLevel], 10)
      return (this.totalExp * 100) / totalExperienceForNextLevel
    }
  },
  methods: {
    baseModifier() {
      let modifier = 1
      if (!this.medalScores) return 1
      const totalScore = this.medalScores.reduce((total, medalScore) => total + medalScore, 0)
      const allArrayValuesDifferent =
        this.medalScores[0] !== this.medalScores[1] &&
        this.medalScores[0] !== this.medalScores[2] &&
        this.medalScores[1] !== this.medalScores[2]
      const checkValueTwiceInArray = (medalScores, number) => {
        let count = 0
        for (let i = 0; i < medalScores.length; i++) {
          if (medalScores[i] === number) {
            count++
          }
        }
        return count
      }
      if (totalScore >= 3 && totalScore < 6) modifier = 2
      if (totalScore < 6 && this.medalScores.length === 2) modifier = 1
      if (totalScore >= 6 && totalScore < 9) modifier = 5
      if (totalScore === 6 && this.medalScores.length === 2) modifier = 1
      if (allArrayValuesDifferent) modifier = 2
      if (checkValueTwiceInArray(this.medalScores, 3) === 2 && totalScore === 7) modifier = 2
      if (totalScore >= 9) modifier = 10
      const numberOfDaysLoggedIn = (this.logins * 0.01) / 2
      return modifier + numberOfDaysLoggedIn
    }
  }
}
</script>

<style lang="scss">
.leveling_progress_bar {
  margin-left: 20px;
  margin-right: 20px;
}

.leveling_progress_bar_item {
  padding-left: 50px;
  padding-right: 100px;
}
</style>
