export default {
  button: {
    new: 'Nouveau win {user_name}',
    search: 'Chercher les wins'
  },
  contract_request: {
    message: 'Veuillez créer / sélectionner une contract request existante'
  },
  contract_type: {
    label: 'Nature du contrat',
    new: 'Nouveau',
    transfer: 'Transfert',
    endorsement: 'Avenant',
    other: 'Autre'
  },
  type: {
    label: 'Type du contrat',
    individual: 'Particulier',
    self_employed: 'Indépendant & TPE',
    corporate: 'PME & Corporate'
  },
  form: {
    audit: 'Audit',
    placeholder: 'Prénom et nom du bizdev',
    title: {
      edit: 'Modifier un win',
      new: 'Enregistrer un win'
    },
    tooltip:
      "Choisir une simulation ne fera que remplir correctement certains champs de la partie audit, et ne 'liera' pas la simulation au win."
  },
  switch: {
    all_wins: 'Tous mes wins',
    contracts_to_encode: 'Contrats à encoder'
  },
  table: {
    columns: {
      tags: {
        danger: 'Pas encodé',
        late: 'Très en retard',
        success: 'En ordre',
        warning: 'Audit incomplet'
      }
    },
    operator_not_found: 'Pas de win trouvé pour {operator}',
    operator_wins: 'Wins de {operator}'
  },
  target_disclaimer:
    "Seul les bizdevs présents dans les targets s'afficheront dans la liste, mais il est possible de rechercher des bizdevs qui ne le sont pas",
  title: 'Wins'
}
