<template>
  <div>
    <el-header data-test="nav_header">
      <div class="quote">
        <span class="text">{{ quote.text }}</span>
        <br />
        <span class="author">- {{ quote.from }}</span>
      </div>
      <div style="display: flex; justify-content: flex-end; align-items: center">
        <UserSelect
          @select="handleUserSelect"
          :complete="false"
          client-search-ref="clientSearch"
          style="line-height: 60px; width: 300px; margin-right: 10px"
          data-test="nav_user_select"></UserSelect>
        <NewBug style="margin-right: 10px"></NewBug>
        <el-button :icon="CircleClose" @click="handleLogOut" circle="circle" data-test="logout_button"></el-button>
        <!-- <el-button -->
        <!--   :icon="Trophy" -->
        <!--   @click="displayDrawer" -->
        <!--   circle="circle" -->
        <!-- ></el-button> -->
      </div>
      <!--   <el-drawer -->
      <!--     :model-value="gamificationDrawerVisible" -->
      <!--     @close="handleClose" -->
      <!--     size="75%" -->
      <!--     ><template #default><GamificationStats -->
      <!--       v-if="gamificationLoaded" -->
      <!--     ></GamificationStats></template></el-drawer -->
      <!-- > -->
    </el-header>
  </div>
</template>

<script>
import GamificationStats from '@/components/gamification/GamificationStats'
import NewBug from '@/components/helpdesk/NewBug'
import UserSelect from '@/components/inputs/UserSelect'
import { logout } from '@/services/auth'
import getQuote from '@/services/quotes'
import { CircleClose, Trophy } from '@element-plus/icons-vue'

const quote = getQuote()

export default {
  name: 'AppHeader',
  computed: {
    CircleClose() {
      return CircleClose
    },
    Trophy() {
      return Trophy
    }
  },
  components: {
    NewBug,
    UserSelect
  },
  data() {
    return {
      quote,
      user: {
        uuid: null,
        fullName: null,
        email: null,
        mobilePhone: null
      },
      gamificationDrawerVisible: false,
      gamificationLoaded: false
    }
  },
  methods: {
    handleLogOut() {
      logout()
    },
    handleUserSelect(user) {
      const {
        name,
        params: { uuid }
      } = this.$route
      if (name === 'users' && uuid === user.uuid) return
      this.$router.push({ name: 'users', params: { uuid: user.uuid } })
    },
    displayDrawer() {
      this.gamificationDrawerVisible = true
      this.gamificationLoaded = true
    },
    handleClose() {
      this.gamificationDrawerVisible = false
    }
  }
}
</script>

<style lang="scss">
@import '@/style/element-variables.scss';

.quote {
  float: left;
  margin-top: 15px;
  color: lighten($--neutral-dark-bg, 30%);

  .text {
    font-size: 14px;
  }

  .author {
    font-size: 12px;
    margin-top: 15px;
    font-style: italic;
  }
}
</style>
