import { SET_LOADING, SET_CONTRACT_REQUEST } from './constants'
import { contractRequest } from './services'
import initialState from './initialState'

const { VUE_APP_CHARGEBEE_BASE_URL } = import.meta.env

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {
    vehicleDocuments(state) {
      if (!state.details) return
      if (state.details.motorbikeVehicle) return state.details.motorbikeVehicle.documents
      if (state.details.carVehicle) return state.details.carVehicle.documents
    },
    chargebeeLink(state) {
      return `${VUE_APP_CHARGEBEE_BASE_URL}/admin-console/subscriptions/${state.chargebeeSubscriptionId}`
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.meta.loading = payload
    },
    [SET_CONTRACT_REQUEST](state, payload) {
      state = Object.assign(state, payload)
    }
  },
  actions: {
    async fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      const { success, message, data } = await contractRequest.show(params)
      if (success) commit(SET_CONTRACT_REQUEST, data)
      commit(SET_LOADING, false)
      return { success, message }
    },
    async send({ state, commit }, data) {
      const { success, message } = await contractRequest.post(data)
      return { success, message }
    },
    async save({ state, commit }, { isNew, ...payload }) {
      const { success, message } = await (isNew ? contractRequest.create(payload) : contractRequest.update(payload))
      return { success, message }
    }
  }
}
