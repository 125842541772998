import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { getIdToken } from './auth'
import { GAMIFICATION_ENDPOINT, gamification } from './gamification'

const SERVER_URL = import.meta.env.VUE_APP_SERVER_URL

axios.interceptors.request.use(
  function (config) {
    const { url } = config

    // In development environment, route SW requests to local server instance
    // instead of proxying through API gateway.
    if (import.meta.env.VUE_NODE_ENV === 'development' && url.includes('/sw/')) {
      config.baseURL = import.meta.env.VUE_APP_SW_SERVER_URL
      config.headers['X-Api-Token'] = import.meta.env.VUE_APP_LOCAL_SW_SERVER_API_TOKEN
      config.url = url.replace('/sw/', '/api/v1/')
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    postGamification(response)
    return formatResponse(response).data
  },
  function (error) {
    const message = error.response?.data
    return {
      success: false,
      message: message || '🤭 Oops...petit problème technique. Contactez les techies.'
    }
  }
)

const postGamification = (response) => {
  const {
    config: { url, baseURL, method },
    data,
    status
  } = response
  const domain = url.replace(baseURL, '')
  if (status === 200 && data && data.success && domain !== GAMIFICATION_ENDPOINT && method !== 'get') {
    gamification({ domain, action: method })
  }
}

const formatResponse = ({ data: rawData, success, message }) => {
  let data
  if (rawData) {
    if (Array.isArray(rawData)) {
      data = rawData.map((x) => camelCaseKeys(x, { deep: true }))
    } else {
      data = camelCaseKeys(rawData, { deep: true })
    }
  }

  return { data, success, message }
}

const formatRequestData = (data) => {
  // Purge all leading and trailing whitespaces from input values, as this
  // pollutes the DB and can lead to problems in VTL responses.
  // https://stackoverflow.com/questions/33510625/trim-white-spaces-in-both-object-key-and-value-recursively
  const trimmedData = JSON.parse(JSON.stringify(data).replace(/"\s+|\s+"/g, '"'))
  return snakeCaseKeys(trimmedData)
}

class API {
  static headers() {
    return {
      Authorization: `Bearer ${getIdToken()}`,
      'Content-type': 'application/json'
    }
  }

  static async get(endPoint, params = {}, baseUrl = SERVER_URL) {
    const options = {
      baseURL: baseUrl,
      url: endPoint,
      method: 'GET',
      params,
      headers: this.headers()
    }
    return axios(options)
  }

  static async post(endPoint, data = {}) {
    const options = {
      baseURL: SERVER_URL,
      url: endPoint,
      method: 'POST',
      data: formatRequestData(data),
      headers: this.headers(),
      timeout: 30000
    }
    return axios(options)
  }

  static async put(endPoint, data = {}) {
    const options = {
      baseURL: SERVER_URL,
      url: endPoint,
      method: 'PUT',
      data: formatRequestData(data),
      headers: this.headers()
    }
    return axios(options)
  }

  static async delete(endPoint, data = {}) {
    const options = {
      baseURL: SERVER_URL,
      url: endPoint,
      method: 'DELETE',
      data: formatRequestData(data),
      headers: this.headers()
    }
    return axios(options)
  }
}

export default API
