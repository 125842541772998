import API from '@/services/api'

export const belonging = {
  create({ userUuid, riskObjectReference, alias, companyId }) {
    return API.post('/sw/belongings', { userUuid, riskObjectReference, alias, companyId })
  },
  delete({ uuid }) {
    return API.delete(`/sw/belongings/${uuid}`)
  },
  update({ uuid, ...payload }) {
    return API.put(`/sw/belongings/${uuid}`, payload)
  }
}
