import initialState from '../_helpers/initialState'
import updateList from '../_helpers/updateList'
import { SET_LOADING, SET_PDF_PROCEDURES, ADD_PDF_PROCEDURE, UPDATE_PDF_PROCEDURE } from './constants'
import { pdfProcedures } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {
    waitingForUser(state) {
      return state.data.filter((u) => ['waiting_for_completion', 'waiting_for_signature'].includes(u.status))
    },
    ongoing(state) {
      return state.data.filter((u) =>
        ['waiting_for_completion', 'waiting_for_signature', 'waiting_for_validation'].includes(u.status)
      )
    },
    past(state) {
      return state.data.filter((u) => ['archived', 'processed'].includes(u.status))
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_PDF_PROCEDURES](state, payload) {
      state.data = payload
    },
    [ADD_PDF_PROCEDURE](state, payload) {
      state.data.push(payload)
    },
    [UPDATE_PDF_PROCEDURE](state, payload) {
      updateList({ data: state.data, payload, idArg: 'uuid' })
    }
  },
  actions: {
    async fetch({ commit }, params) {
      commit(SET_LOADING, true)
      const { success, message, data } = await pdfProcedures.index(params)
      if (success) {
        commit(SET_PDF_PROCEDURES, data)
        commit(SET_LOADING, false)
      }
      return { success, message }
    },
    async update({ commit }, payload) {
      const { success, message, data } = await pdfProcedures.update(payload)
      if (success) commit(UPDATE_PDF_PROCEDURE, data)
      return { success, message }
    },
    async save({ commit }, payload) {
      const { success, message, data } = await pdfProcedures.create(payload)
      if (success) commit(ADD_PDF_PROCEDURE, data)
      return { success, message }
    }
  }
}
