export default {
  meta: {
    loading: false,
    success: false,
    actions: {},
    error: ''
  },
  link: null,
  contractRequests: [],
  status: 'waiting_for_selection',
  clientComment: null
}
