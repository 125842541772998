import API from '@/services/api'

export const nps = ({ email }) => {
  return API.get('/nps', { email })
}

export const emails = ({ email }) => {
  return API.get('/messages/emails', { to: email })
}

export const calls = ({ mobilePhone }) => {
  return API.get('/aircall/calls', { phone_number: mobilePhone })
}

export const sendinblue = {
  unsubscribe(email) {
    return API.post('/sendinblue/unsubscribe', { email })
  },
  emails({ email }) {
    return API.get('/sendinblue/emails', { email: email })
  },
  email(uuid) {
    return API.get(`/sendinblue/emails/${uuid}`)
  }
}

export const twilio = ({ mobilePhone }) => {
  return API.get('/messages/sms', { to: mobilePhone })
}
