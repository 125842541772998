<script>
import { login } from '@/services/auth'

export default {
  name: 'Login',
  created() {
    login()
  }
}
</script>

<template>
  <div></div>
</template>
