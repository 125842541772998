export default {
  multi_products: {
    accident_record:
      'je suis toujours en attente de votre attestation de sinistralité. Pourriez-vous me la communiquer le plus rapidement possible ?',
    address:
      'j’étais en train d’émettre votre contrat mais je viens de voir que je n’ai pas votre adresse postale (rue, numéro, boite, code postal et ville). Pouvez-vous me la communiquer en réponse à ce SMS ?',
    documents:
      'je suis toujours en attente de vos documents pour mettre en place votre contrat rapidement. Pourriez-vous me les communiquer ?',
    driver_record:
      'je suis toujours en attente de votre attestation d’expérience de conduite. Pourriez-vous me la communiquer le plus rapidement possible ?',
    iban: 'j’étais en train d’émettre votre contrat mais je viens de voir que je n’ai pas encore votre IBAN. Pouvez-vous me le communiquer en réponse à ce SMS ?',
    offer_reminder_today:
      'je viens de vous envoyer l’offre pour vos assurances par mail. Puis-je vous rappeler cet après-midi pour en discuter ?',
    offer_reminder_tomorrow:
      'je viens de vous envoyer l’offre pour vos assurances par mail. Puis-je vous rappeler demain matin pour en discuter ?',
    offer_when_can_we_call_day_1:
      "je viens de vous appeler concernant l'offre envoyée pour votre assurance. Quel moment vous convient pour en discuter ? Belle journée à vous !",
    offer_when_can_we_call_day_5:
      "êtes-vous intéressé par l'offre envoyée pour vos assurances ? Quand puis-je vous appeler pour en discuter ? Belle journée à vous !",
    sepa_mandate:
      'je suis toujours en attente de votre mandat SEPA signé. Pourriez-vous me le communiquer rapidement ?',
    unpaid: 'avez-vous eu le temps de faire le virement pour votre assurance comme discuté ?'
  },
  single_product: {
    case_closed:
      "je viens de vous rappeler mais vous n'étiez pas disponible. Je vais classer votre demande d'assurance {type} sans suite. N'hésitez pas à me contacter au +32 2 808 14 00 si vous êtes encore intéressé par une assurance {type} à un prix très compétitif.",
    offer_reminder_today:
      'je viens de vous contacter pour votre assurance {type}. Pourrais-je vous rappeler cet après midi après 17h ?',
    offer_reminder_tomorrow:
      'je viens de vous contacter pour votre assurance {type}. Pourrais-je vous rappeler demain matin avant 11h ?',
    offer_when_can_we_call:
      "je viens d'essayer de vous joindre. Pouvez-vous m'indiquer quand je peux vous rappeler pour parler de votre assurance {type} ?",
    offer_when_can_we_call_multiple_attempts:
      "j'ai essayé de vous contacter à plusieurs reprises sans succès pour parler de votre assurance {type}. Pourriez-vous m'indiquer quand je peux vous rappeler ?"
  },
  management: {
    amendment_form:
      'suite à notre échange à l\'instant, voici le lien du formulaire pour pouvoir introduire votre demande de modification de contrat: https://amendments.yago.be/form . Vous pouvez également le faire directement depuis le coffre-fort de votre espace client via le bouton "Modifier mon contrat".',
    redirection_ycbm_to_form:
      'nous venons de vous envoyer un email important concernant votre demande de rendez-vous téléphonique, pourriez-vous en prendre connaissance?'
  },
  text: 'Bonjour {honorific}, {template} Merci, {operatorFirstName} de Yago.',
  types: {
    car: 'auto',
    house: 'habitation',
    motorbike: 'moto'
  }
}
