/* eslint-disable camelcase */
import accident_records from './nl/accidentRecords'
import amendments from './nl/amendments'
import bug from './nl/bug'
import bundled_payment from './nl/bundledPayment'
import car_vehicle from './nl/carVehicle'
import claims from './nl/claims'
import reduction_campaigns from './nl/reductionCampaigns'
import contract_requests from './nl/contractRequests'
import contracts from './nl/contracts'
import full_package_bundle from './nl/fullPackageBundle'
import gamification from './nl/gamification'
import green_cards from './nl/greenCards'
import home from './nl/home'
import house from './nl/house'
import insurers from './nl/insurers'
import layout from './nl/layout'
import life_procedures from './nl/lifeProcedures'
import motorbike_vehicle from './nl/motorbikeVehicle'
import pdf_procedures from './nl/pdfProcedures'
import sepa_mandates from './nl/sepaMandates'
import shared from './nl/shared'
import sms from './nl/sms'
import sms_templates from './nl/smsTemplates'
import targets from './nl/targets'
import terminations from './nl/terminations'
import transfers from './nl/transfers'
import unpaids from './nl/unpaids'
import users from './nl/users'
import wins from './nl/wins'
import companies from './nl/companies'
import members from './nl/members'
import riskObjects from './nl/riskObjects'
import operators from './nl/operators'

export default {
  accident_records,
  amendments,
  bug,
  bundled_payment,
  car_vehicle,
  claims,
  reduction_campaigns,
  contract_requests,
  contracts,
  full_package_bundle,
  gamification,
  green_cards,
  home,
  house,
  insurers,
  layout,
  life_procedures,
  motorbike_vehicle,
  pdf_procedures,
  sepa_mandates,
  shared,
  sms,
  sms_templates,
  targets,
  terminations,
  transfers,
  unpaids,
  users,
  wins,
  companies,
  members,
  riskObjects,
  operators
}
