<template>
  <el-aside width="230px">
    <el-menu :default-openeds="['2', '3', '4', '5', '6']">
      <div id="dash">
        <h5
          style="
            font-size: 15px;
            font-weight: 600;
            font-size: 14px;
            margin: 5px 0;
            color: white;
            text-transform: capitalize;
          "
          v-if="operator">
          <UserFilled style="width: 18px; transform: translateY(4px); margin-right: 5px" />{{ getOperatorFirstName() }}
        </h5>
        <LocaleSelector />
      </div>
      <router-link :to="{ name: 'home' }">
        <el-menu-item index="1">
          <template #title>
            <el-icon data-test="home_menu_link">
              <House />
            </el-icon>
            <b>{{ $t('layout.navbar.tabs.home') }}</b>
          </template>
        </el-menu-item>
      </router-link>
      <template #title>
        <el-icon>
          <Search />
        </el-icon>
        <b>{{ $t('layout.navbar.tabs.consultation') }}</b>
      </template>
      <el-sub-menu index="2">
        <template #title>
          <el-icon>
            <Search />
          </el-icon>
          <b>{{ $t('layout.navbar.tabs.consultation') }}</b>
        </template>
        <router-link :to="{ name: 'operators' }" v-if="operatorIsAdmin">
          <el-menu-item index="2-1" data-test="operators">
            <span>👨‍💼 &nbsp;</span>
            {{ $t('operators.title') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'insurers' }">
          <el-menu-item index="2-2" data-test="insurers_menu_link">
            <span>🏛️ &nbsp;</span>
            {{ $t('layout.navbar.subtabs.insurers') }}
          </el-menu-item>
        </router-link>
      </el-sub-menu>
      <el-sub-menu index="3">
        <template #title>
          <el-icon>
            <CircleCheck />
          </el-icon>
          <b>{{ $t('layout.navbar.tabs.actions') }}</b>
        </template>
        <router-link :to="{ name: 'amendments' }">
          <el-menu-item index="3-1" data-test="amendments_menu_link">
            <span>📝 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.amendments') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'transfers' }">
          <el-menu-item index="3-2" data-test="transfers_menu_link">
            <span>📩 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.transfers') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'sepa-mandates' }">
          <el-menu-item index="3-3" data-test="sepa_mandates_menu_link">
            <span>💳 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.sepa_mandates') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'terminations' }">
          <el-menu-item index="3-4" data-test="terminations_menu_link">
            <span>⛔ &nbsp;</span>
            {{ $t('layout.navbar.subtabs.terminations') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'unpaids' }">
          <el-menu-item index="3-5" data-test="unpaids_menu_link">
            <span>💸 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.unpaids') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'all-wins' }">
          <el-menu-item index="3-6" data-test="all_wins_menu_link">
            <span>✌️ &nbsp;</span>
            {{ $t('layout.navbar.subtabs.wins') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'life-procedures' }">
          <el-menu-item
            index="3-7"
            data-test="life-procedures_menu_link"
            style="white-space: break-spaces !important; word-break: break-word; line-height: 1">
            <span>🌱 </span>
            {{ $t('layout.navbar.subtabs.life_procedures') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'pdf-procedures' }">
          <el-menu-item index="3-8" data-test="pdf-procedures_menu_link">
            <span>📄 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.pdf_procedures') }}
          </el-menu-item>
        </router-link>
      </el-sub-menu>
      <el-sub-menu index="4">
        <template #title>
          <el-icon>
            <DocumentChecked />
          </el-icon>
          <b>{{ $t('layout.navbar.tabs.contracts') }}</b>
        </template>
        <router-link :to="{ name: 'car-contract-requests' }">
          <el-menu-item index="4-1" data-test="car_contract_requests_menu_link">
            <span>🚗 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.car_contract_requests') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'motorbike-contract-requests' }">
          <el-menu-item index="4-2" data-test="motorbike_contract_requests_menu_link">
            <span>🏍️ &nbsp;</span>
            {{ $t('layout.navbar.subtabs.motorbike_contract_requests') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'house-contract-requests' }">
          <el-menu-item index="4-3" data-test="house_contract_requests_menu_link">
            <span>🏠 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.house_contract_requests') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'contracts' }">
          <el-menu-item
            index="4-4"
            data-test="contracts_menu_link"
            style="white-space: break-spaces !important; word-break: break-word; line-height: 1">
            <span>✍️️ </span>
            {{ $t('layout.navbar.subtabs.contracts') }}
          </el-menu-item>
        </router-link>
      </el-sub-menu>
      <el-sub-menu index="5">
        <template #title>
          <el-icon>
            <Setting />
          </el-icon>
          <b>{{ $t('layout.navbar.tabs.tools') }}</b>
        </template>
        <router-link :to="{ name: 'sms' }">
          <el-menu-item index="5-1" data-test="sms_menu_link">
            <span>💬 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.messages') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'targets' }">
          <el-menu-item index="5-2" data-test="targets_menu_link">
            <span>🎯‍ &nbsp;</span>
            {{ $t('layout.navbar.subtabs.targets') }}
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'reduction-campaigns' }">
          <el-menu-item index="5-3" data-test="reduction_campaigns_menu_link">
            <span>🧐 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.reduction_campaigns') }}
          </el-menu-item>
        </router-link>
      </el-sub-menu>
      <el-sub-menu index="6">
        <template #title>
          <el-icon>
            <Star />
          </el-icon>
          <b>{{ $t('layout.navbar.tabs.b2b') }}</b>
        </template>
        <router-link :to="{ name: 'companies' }">
          <el-menu-item index="6-1" data-test="companies_menu_link">
            <span>🏢 &nbsp;</span>
            {{ $t('layout.navbar.subtabs.companies') }}
          </el-menu-item>
        </router-link>
      </el-sub-menu>
    </el-menu>
  </el-aside>
</template>

<script>
import { operatorFirstName } from '@/helpers/index.js'
import LocaleSelector from '@/components/shared/LocaleSelector.vue'
import { House, Search, CircleCheck, DocumentChecked, Setting, Star, UserFilled } from '@element-plus/icons-vue'
import { mapState } from 'vuex'

export default {
  name: 'AppMenu',
  components: {
    LocaleSelector
  },
  computed: {
    ...mapState('operator', { operatorIsAdmin: 'operatorIsAdmin', operator: 'data' })
  },
  methods: {
    getOperatorFirstName() {
      if (!this.operator) return
      else return operatorFirstName(this.operator)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/element-variables.scss';

#dash {
  padding: 15px 15px 15px 22px;
  color: white;
  background: $--neutral-dark-bg;
  border-right: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
  display: flex;
  justify-content: space-between;
}
</style>
