export default {
  meta: {
    actions: {},
    error: '',
    loading: false,
    success: false
  },
  address: {
    city: null,
    country: null,
    full: null,
    street_box: null,
    street_name: null,
    street_number: null,
    zip_code: null
  },
  amendments: [],
  availableActions: [],
  bankAccount: {
    bic: null,
    iban: null
  },
  belongings: [],
  birthdate: null,
  civilStatus: null,
  claims: [],
  company: {
    address: {
      city: null,
      country: null,
      full: null,
      street_box: null,
      street_name: null,
      street_number: null,
      zip_code: null
    },
    bankAccount: null,
    commercialName: null,
    complete: true,
    completeWithBankDetails: null,
    documents: {
      claimsStatements: [],
      drivingLicence: null,
      identityCard: null
    },
    legal_name: null,
    owner_title: null,
    vat_number: null
  },
  complete: null,
  completeWithoutPhoneNumber: null,
  completeWithBankDetails: null,
  contractRequests: [],
  contracts: [],
  contractuallyFrozen: false,
  customerJourney: {
    created_at: null,
    lastSignInAt: null,
    signInCount: null
  },
  documents: {
    claimsStatements: [],
    drivingLicence: null,
    identityCard: null
  },
  email: null,
  externalIds: {
    aircallId: null,
    chargebeeIds: [],
    pipedriveId: null,
    sendinblueId: null
  },
  firstName: null,
  fixPhone: null,
  fullName: null,
  gender: null,
  hasFullPackageBundle: false,
  honorific: null,
  journey: {},
  lastName: null,
  locale: null,
  mobilePhone: null,
  nationalRegistryNumber: null,
  operatorComment: null,
  partnerQuoteRequests: null,
  pendingActions: null,
  placeOfBirth: null,
  politicallyExposed: null,
  profession: null,
  professionalStatus: null,
  teamId: null,
  unfit: false,
  uuid: null,
  policyOwner: {
    personUuid: null
  },
  fromBrokerId: null
}
