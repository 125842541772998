export default {
  administrator_medal: {
    bronze: 'Ervaren Beheerder',
    gold: 'Geniale Beheerder',
    silver: 'Volleerde Beheerder'
  },
  badge_labels: {
    accidentRecords: 'Schadeattesten',
    amendments: 'Aanhangsels',
    bicycleContracts: 'Fietscontracten',
    bugs: 'Gerapporteerde bugs',
    carContracts: 'Autocontracten',
    contracts: 'Ingevoerde contracten',
    familyContracts: 'Familialecontracten',
    houseContracts: 'Wooncontracten',
    houseRentalContracts: 'Wooncontracten huurder',
    independentContracts: 'Zelfstandige contracten',
    lockedBadge: '???',
    logins: 'Aantal logins',
    motorbikeContracts: 'Motorcontracten',
    pensionContracts: 'Pensioencontracten',
    personalContracts: 'Persoonlijke contracten',
    sepaMandates: 'SEPA-mandaten',
    smsMessages: 'Verzonden SMS-berichten',
    terminations: 'Opzeggingen',
    transfers: 'Overdrachten',
    travelContracts: 'Reiscontracten',
    unpaids: 'Onbetaald',
    vanContracts: 'Mobilhomecontracten',
    wins: 'win'
  },
  badge_messages: {
    bronze: 'Doel zilveren badge 🥈 : ',
    gold: 'Gefeliciteerd! 🥳',
    silver: 'Doel gouden badge 🥇 : '
  },
  customer_service_medal: {
    bronze: 'Klantrelatie Specialist',
    gold: 'Genie in Klantrelaties',
    silver: 'Expert in Klantrelaties'
  },
  levels: {
    level: 'LEVEL {currentLevel} - {operatorName}'
  },
  sales_medal: {
    bronze: 'Ervaren Verkoper',
    gold: 'Geniale Verkoper',
    silver: 'Volleerde Verkoper'
  },
  title: '🥇 Yagoverwinning 🥇'
}
