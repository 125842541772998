export default {
  title: 'Campagnes',
  labels: {
    utm_source: 'UTM source',
    utm_campaign: 'UTM Campagne',
    label_fr: "Intitulé de l'offre (FR)",
    label_nl: "Intitulé de l'offre (NL)",
    amount: 'Valeur de la réduction',
    operation_technique: 'type',
    active: 'Actif'
  },
  previews: {
    link_profiling_fr:
      "FR: <a href='https://yago-STAGING:deliceltes@staging.yago.be/fr/products/houses/profiling?utm_source={utm_source}&utm_campaign={utm_campaign}' target='_blank'>Avec intitulé de l'offre: '{reduction_label}'</a>",
    link_profiling_nl:
      "NL: <a href='https://yago-STAGING:deliceltes@staging.yago.be/nl/products/houses/profiling?utm_source={utm_source}&utm_campaign={utm_campaign}' target='_blank'>Avec intitulé de l'offre: '{reduction_label}'</a>",
    title: 'Aperçu :'
  },
  operation_techniques: {
    amount: '€',
    percentage: '%'
  },
  typical_percentages: {
    title: 'Pourcentages Types :',
    one_month: '1 mois: 8.33%',
    two_months: '2 mois: 16.66%',
    three_months: '3 mois: 25%'
  }
}
