export default {
  see_green_card: 'Bekijk het verzekeringsbewijs',
  title: 'Verzekeringsbewijs',
  statuses: {
    definitive: 'Definitief',
    temporary: 'Tijdelijk'
  },
  validity: {
    future: 'Toekomstig',
    archived: 'Verlopen',
    valid: 'Geldig'
  }
}
