import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBolt,
  faCrown,
  faExternalLinkAlt,
  faFeatherAlt,
  faMedal,
  faParking,
  faSearch,
  faTrophy
} from '@fortawesome/free-solid-svg-icons'

library.add(faBolt)
library.add(faCrown)
library.add(faExternalLinkAlt)
library.add(faFeatherAlt)
library.add(faMedal)
library.add(faParking)
library.add(faSearch)
library.add(faTrophy)

export default function withFontAwesome(app) {
  app.component('font-awesome-icon', FontAwesomeIcon)
  return app
}
