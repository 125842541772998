export default {
  title: 'Campagnes',
  labels: {
    utm_source: 'UTM-bron',
    utm_campaign: 'UTM-campagne',
    label_fr: 'Titel van het aanbod (FR)',
    label_nl: 'Titel van het aanbod (NL)',
    amount: 'Waarde van de korting',
    operation_technique: 'type',
    active: 'Actief'
  },
  previews: {
    link_profiling_fr:
      "FR: <a href='https://yago-STAGING:deliceltes@staging.yago.be/fr/products/houses/profiling?utm_source={utm_source}&utm_campaign={utm_campaign}' target='_blank'>Met titel van het aanbod: '{reduction_label}'</a>",
    link_profiling_nl:
      "NL: <a href='https://yago-STAGING:deliceltes@staging.yago.be/nl/products/houses/profiling?utm_source={utm_source}&utm_campaign={utm_campaign}' target='_blank'>Met titel van het aanbod: '{reduction_label}'</a>",
    title: 'Voorvertoning :'
  },
  operation_techniques: {
    amount: '€',
    percentage: '%'
  },
  typical_percentages: {
    title: 'Gebruikelijke percentages :',
    one_month: '1 maand: 8.33%',
    two_months: '2 maanden: 16.66%',
    three_months: '3 maanden: 25%'
  }
}
