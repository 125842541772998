import { document } from './services'

export default {
  namespaced: true,
  actions: {
    async save({ state, commit }, payload) {
      const { success, message } = await document.create(payload)
      return { success, message }
    }
  }
}
