import initialState from '../_helpers/initialState'
import updateList from '../_helpers/updateList'
import { SET_LOADING, SET_SEPA_MANDATES, ADD_SEPA_MANDATE, UPDATE_SEPA_MANDATE } from './constants'
import { sepaMandates } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {
    ongoing(state) {
      if (!state.data) return []

      return state.data.filter((u) =>
        ['waiting_for_signature', 'waiting_for_sending', 'waiting_for_approval'].includes(u.status)
      )
    },
    past(state) {
      if (!state.data) return []

      return state.data?.filter((u) => ['archived', 'processed'].includes(u.status))
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_SEPA_MANDATES](state, payload) {
      state.data = payload
    },
    [ADD_SEPA_MANDATE](state, payload) {
      state.data.push(payload)
    },
    [UPDATE_SEPA_MANDATE](state, payload) {
      updateList({ data: state.data, payload, idArg: 'uuid' })
    }
  },
  actions: {
    fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      sepaMandates.index(params).then(({ data }) => {
        commit(SET_SEPA_MANDATES, data)
        commit(SET_LOADING, false)
      })
    },
    async update({ state, commit }, payload) {
      const { success, message, data } = await sepaMandates.update(payload)
      if (success) commit(UPDATE_SEPA_MANDATE, data)
      return { success, message }
    },
    async save({ state, commit }, payload) {
      const { success, message, data } = await sepaMandates.create(payload)
      if (success) commit(ADD_SEPA_MANDATE, data)
      return { success, message }
    }
  }
}
