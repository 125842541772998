import { createRouter, createWebHistory } from 'vue-router'

import Callback from '@/components/Callback'
import Login from './pages/Login'

import { requireAuth, sanitizeAuth0Callbacks } from '@/services/auth'

const router = createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback,
      beforeEnter: sanitizeAuth0Callbacks
    },
    {
      path: '/',
      name: 'home',
      beforeEnter: requireAuth,
      component: () => import('./pages/Home')
    },
    {
      path: '/operators',
      name: 'operators',
      beforeEnter: requireAuth,
      component: () => import('./pages/Operators')
    },
    {
      path: '/users/:uuid',
      name: 'users',
      props: true,
      beforeEnter: requireAuth,
      component: () => import('./pages/User')
    },
    {
      path: '/car-contract-requests',
      name: 'car-contract-requests',
      beforeEnter: requireAuth,
      component: () => import('./pages/ContractRequests'),
      props: { contractRequestType: 'cars' }
    },
    {
      path: '/motorbike-contract-requests',
      name: 'motorbike-contract-requests',
      beforeEnter: requireAuth,
      component: () => import('./pages/ContractRequests'),
      props: { contractRequestType: 'motorbikes' }
    },
    {
      path: '/house-contract-requests',
      name: 'house-contract-requests',
      beforeEnter: requireAuth,
      component: () => import('./pages/ContractRequests'),
      props: { contractRequestType: 'houses' }
    },
    {
      path: '/contracts',
      name: 'contracts',
      beforeEnter: requireAuth,
      component: () => import('./pages/Contracts')
    },
    {
      path: '/amendments',
      name: 'amendments',
      beforeEnter: requireAuth,
      component: () => import('./pages/Amendments')
    },
    {
      path: '/targets',
      name: 'targets',
      beforeEnter: requireAuth,
      component: () => import('./pages/Targets')
    },
    {
      path: '/terminations',
      name: 'terminations',
      beforeEnter: requireAuth,
      component: () => import('./pages/Terminations')
    },
    {
      path: '/transfers',
      name: 'transfers',
      beforeEnter: requireAuth,
      component: () => import('./pages/Transfers')
    },
    {
      path: '/sepa-mandates',
      name: 'sepa-mandates',
      beforeEnter: requireAuth,
      component: () => import('./pages/SepaMandates')
    },
    {
      path: '/unpaids',
      name: 'unpaids',
      beforeEnter: requireAuth,
      component: () => import('./pages/Unpaids')
    },
    {
      path: '/insurers',
      name: 'insurers',
      beforeEnter: requireAuth,
      component: () => import('./pages/Insurers')
    },
    {
      path: '/sms',
      name: 'sms',
      beforeEnter: requireAuth,
      component: () => import('./pages/Sms')
    },
    {
      path: '/all-wins',
      name: 'all-wins',
      beforeEnter: requireAuth,
      component: () => import('./pages/AllWins')
    },
    {
      path: '/life-procedures',
      name: 'life-procedures',
      beforeEnter: requireAuth,
      component: () => import('./pages/LifeProcedures')
    },
    {
      path: '/pdf-procedures',
      name: 'pdf-procedures',
      beforeEnter: requireAuth,
      component: () => import('./pages/PdfProcedures')
    },
    {
      path: '/reduction-campaigns',
      name: 'reduction-campaigns',
      beforeEnter: requireAuth,
      component: () => import('./pages/ReductionCampaigns')
    },
    {
      path: '/companies',
      name: 'companies',
      beforeEnter: requireAuth,
      component: () => import('./pages/Companies')
    },
    {
      path: '/companies/:vatNumber',
      name: 'company',
      beforeEnter: requireAuth,
      component: () => import('./pages/Company')
    },
    {
      path: '/:pathMatch(.*)',
      redirect: '/'
    }
  ]
})

export default router
