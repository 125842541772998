import API from '@/services/api'

export const reductionCampaigns = {
  index(params) {
    return API.get('/sw/reduction_campaigns', params)
  },
  create(data) {
    return API.post('/sw/reduction_campaigns', data)
  },
  update(data) {
    return API.put(`/sw/reduction_campaigns/${data.id}`, data)
  }
}
