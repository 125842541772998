import Vuex from 'vuex'

import accidentRecords from './accidentRecords'
import amendment from './amendment'
import amendments from './amendments'
import bundledPayment from './bundledPayment'
import bundledPaymentUnpaids from './bundledPaymentUnpaids'
import reductionCampaigns from './reductionCampaigns'
import claims from './claims'
import contract from './contract'
import contractRequest from './contractRequest'
import contractRequests from './contractRequests'
import contracts from './contracts'
import requiredDocuments from '././requiredDocuments'
import relatedDocument from './relatedDocument'
import fullPackageBundle from './fullPackageBundle'
import gamification from './gamification'
import insurers from './insurers'
import indexations from './indexations'
import lifeProcedures from './lifeProcedures'
import pdfProcedures from './pdfProcedures'
import products from './products'
import riskObjects from './riskObjects'
import sepaMandates from './sepaMandates'
import terminations from './terminations'
import transfers from './transfers'
import unpaids from './unpaids'
import user from './user'
import wins from './wins'
import operator from './operator'
import operators from './operators'
import company from './company'
import companyContracts from './companyContracts'

let store = () => {
  return new Vuex.Store({
    strict: ['development', 'staging'].includes(import.meta.env.VUE_NODE_ENV),
    modules: {
      accidentRecords,
      amendment,
      amendments,
      bundledPayment,
      bundledPaymentUnpaids,
      reductionCampaigns,
      contract,
      contractRequest,
      contractRequests,
      contracts,
      claims,
      requiredDocuments,
      relatedDocument,
      fullPackageBundle,
      gamification,
      insurers,
      indexations,
      lifeProcedures,
      pdfProcedures,
      products,
      riskObjects,
      sepaMandates,
      terminations,
      transfers,
      unpaids,
      user,
      wins,
      operator,
      operators,
      company,
      companyContracts
    }
  })
}

export default store
export function withStore(app) {
  app.use(store())
  return app
}
