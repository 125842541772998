import initialState from '../_helpers/initialState'
import updateList from '../_helpers/updateList'
import { SET_LOADING, SET_UNPAIDS, ADD_UNPAID, UPDATE_UNPAID } from './constants'
import { unpaids, reminders } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {
    ongoing(state) {
      return state.data.filter((u) => u.status === 'waiting_for_payment')
    },
    past(state) {
      return state.data.filter((u) => u.status !== 'waiting_for_payment')
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_UNPAIDS](state, payload) {
      state.data = payload
    },
    [ADD_UNPAID](state, payload) {
      state.data.push(payload)
    },
    [UPDATE_UNPAID](state, payload) {
      updateList({ data: state.data, payload, idArg: 'uuid' })
    }
  },
  actions: {
    fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      unpaids.index(params).then(({ data }) => {
        commit(SET_UNPAIDS, data)
        commit(SET_LOADING, false)
      })
    },
    async update({ state, commit }, payload) {
      const { success, message, data } = await unpaids.update(payload)
      if (success) commit(UPDATE_UNPAID, data)
      return { success, message }
    },
    async remind({ state, commit }, payload) {
      const { success, message, data } = await reminders.create(payload)
      if (success) commit(UPDATE_UNPAID, data)
      return { success, message }
    },
    async save({ state, commit }, { isNew, ...payload }) {
      const { success, message, data } = await (isNew ? unpaids.create(payload) : unpaids.update(payload))
      if (success) isNew ? commit(ADD_UNPAID, data) : commit(UPDATE_UNPAID, data)
      return { success, message, data }
    }
  }
}
