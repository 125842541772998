export default {
  administrator_medal: {
    bronze: 'Administrateur expérimenté',
    gold: 'Administrateur de génie',
    silver: 'Administrateur accompli'
  },
  badge_labels: {
    accidentRecords: 'Attestations sinistralité',
    amendments: 'Avenants',
    bicycleContracts: 'Contrats vélo',
    bugs: 'Bugs déclarés',
    carContracts: 'Contrats auto',
    contracts: 'Contrats encodés',
    familyContracts: 'Contrats famille',
    houseContracts: 'Contrats habitation',
    houseRentalContracts: 'Contrats habitation (location)',
    independentContracts: 'Contrats indépendants',
    lockedBadge: '???',
    logins: 'Nombre de connections',
    motorbikeContracts: 'Contrats moto',
    pensionContracts: 'Contrats pension',
    personalContracts: 'Contrats ',
    sepaMandates: 'Mandats SEPA',
    smsMessages: 'SMS envoyés',
    terminations: 'Renons',
    transfers: 'Transferts',
    travelContracts: 'Contrats voyage',
    unpaids: 'Impayés',
    vanContracts: 'Contrats motorhome',
    wins: 'Wins'
  },
  badge_messages: {
    bronze: 'Objectif badge argent 🥈 : ',
    gold: 'Félicitations ! 🥳',
    silver: 'Objectif badge or 🥇 : '
  },
  customer_service_medal: {
    bronze: 'Spécialiste en relations client',
    gold: 'Génie des relations client',
    silver: 'Expert en relations client'
  },
  levels: {
    level: 'NIVEAU {currentLevel} - {operatorName}'
  },
  sales_medal: {
    bronze: 'Vendeur expérimenté',
    gold: 'Vendeur de génie',
    silver: 'Vendeur accompli'
  },
  title: '🥇 Succèsraphin 🥇'
}
