export default {
  messages: {
    missing_user_data:
      'Let op, je moet eerst de volgende velden invullen: Rijksregisternummer, beroep en politiek blootgestelde persoon',
    no_iban: 'Geef de IBAN van de gebruiker op',
    no_identity_card: 'De gebruiker moet een identiteitskaart hebben'
  },
  new_life_procedure: '🌱 Nieuwe Levensprocedure',
  title: 'Levensprocedures'
}
