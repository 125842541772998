import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

export default function withMoment(app) {
  dayjs.extend(relativeTime)
  dayjs.extend(utc)
  app.config.globalProperties.$dayjs = dayjs
  app.config.globalProperties.$date = dayjs
  return app
}
