import API from '@/services/api'

export const contract = {
  create(data) {
    return API.post('/sw/contracts?format=tiny', data)
  },
  delete(data) {
    return API.delete(`/sw/contracts/${data.uuid}`, data)
  },
  post({ uuid, format, method, data = {} }) {
    return API.post(`/sw/contracts/${uuid}/${method}?format=${format}`, data)
  },
  show({ uuid, format }) {
    return API.get(`/sw/contracts/${uuid}`, { format })
  },
  update(data) {
    return API.put(`/sw/contracts/${data.uuid}?format=extended`, data)
  },
  rectify(data) {
    return API.post(`/sw/contracts/${data.uuid}/rectify`, data)
  },
  reinstate(data) {
    return API.post(`/sw/contracts/${data.uuid}/reinstate`, data)
  }
}
