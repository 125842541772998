import initialState from '../_helpers/initialState'
import { SET_LOADING, SET_RISK_OBJECTS } from './constants'
import { riskObjects } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {
    formattedData(state) {
      return state.data
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_RISK_OBJECTS](state, payload) {
      state.data = payload
    }
  },
  actions: {
    async fetch({ state, commit }) {
      commit(SET_LOADING, true)
      riskObjects.index().then(({ data }) => {
        commit(SET_RISK_OBJECTS, data)
        commit(SET_LOADING, false)
      })
    }
  }
}
