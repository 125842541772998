import { amendment } from './services'
import { SET_AMENDMENT, SET_LOADING } from './constants'
import initialState from './initialState'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  mutations: {
    [SET_AMENDMENT](state, payload) {
      state = Object.assign(state, payload)
    },
    [SET_LOADING](state, payload) {
      state.meta.loading = payload
    }
  },
  actions: {
    async save({ state, commit }, { isNew, ...payload }) {
      const { success, message } = await (isNew ? amendment.create(payload) : amendment.update(payload))
      return { success, message }
    },
    async send({ state, commit }, payload) {
      const { success, message } = await amendment.post(payload)
      return { success, message }
    },
    fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      amendment.show(params).then(({ data }) => {
        commit(SET_AMENDMENT, data)
        commit(SET_LOADING, false)
      })
    }
  }
}
