export default {
  messages: {
    missing_user_data:
      "Attention, il faut d'abord remplir les champs: numéro national, occupation et personne politiquement exposée",
    no_iban: "Veuillez indiquer l'iban de l'utilisateur",
    no_identity_card: "L'utilisateur doit avoir une carte d'identité"
  },
  new_life_procedure: '🌱 Nouvelle procédure Vie',
  title: 'Procédures Vie'
}
