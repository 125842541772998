import API from '@/services/api'

export const user = {
  delete(data) {
    return API.delete(`/sw/users/${data.uuid}`, data)
  },
  post({ uuid, format, method, data = {} }) {
    return API.post(`/sw/users/${uuid}/${method}?format=${format}`, data)
  },
  show({ uuid, format }) {
    return API.get(`/sw/users/${uuid}`, { format })
  },
  search({ queryString, format = '' }) {
    return API.get(`/sw/users/search?q=${encodeURIComponent(queryString)}&format=${format}`)
  },
  update({ uuid, data }) {
    return API.put(`/sw/users/${uuid}`, data)
  }
}
