export default {
  accidents: {
    add: 'Een schadegeval toevoegen',
    at_fault: 'Schadegeval in <b>fout</b>',
    finalized: {
      false: 'nog niet afgerond',
      true: 'afgerond'
    },
    not_at_fault: 'Schadegeval in <b>recht</b>',
    radio: {
      at_fault: 'In fout',
      not_at_fault: 'In recht',
      undetermined: 'Niet bepaald'
    },
    remove: 'Het schadegeval verwijderen',
    undetermined: 'Schadegeval met <b>onbepaalde</b> aansprakelijkheid'
  },
  delete: 'Verwijderen',
  edit: 'Het schadeattest wijzigen',
  five_latest_years: '{accidentRecordYearUploadedTotal} jaar van geüploade attesten voor de laatste 5 jaren',
  label: {
    accident_record: '✅ Schadeattest',
    sworn_statement: '⚠️ Verklaring op eer'
  },
  new_accident_record: 'Een schadeattest invoeren',
  new_sworn_statement: 'Een verklaring op eer invoeren',
  no_accident: 'Geen schadegevallen in deze periode',
  no_attestation: '<b>Attest ontbreekt voor deze periode ({diffInMonth} maanden)</b>',
  request: {
    title: 'Schadeattestaten van de klant aanvragen',
    yearsInsured: {
      contract: 'Hoeveel jaren van bewezen schadehistoriek zijn aangegeven in het contract?',
      missing: 'Hoeveel jaren ontbreken?',
      proved: 'Hoeveel jaren zijn al bewezen?'
    }
  }
}
