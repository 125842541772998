import * as Sentry from '@sentry/vue'

import { sendEmail } from '@/services/email'

export const handlers = {
  methods: {
    async handleSave({ confirm = true, form, action, object, payload }) {
      const {
        params: { uuid }
      } = this.$route
      const validUuid = !uuid || !payload.userUuid || uuid === payload.userUuid
      return new Promise((resolve) => {
        form.validate((valid) => {
          if (!valid) {
            this.$message({
              message: this.$t('shared.messages.encoding_problem'),
              type: 'error',
              duration: 10000
            })
            resolve({ success: false })
          } else if (!validUuid) {
            this.$message({
              message: this.$t('shared.synchronization_error'),
              type: 'error',
              duration: 10000
            })
            Sentry.captureException(`Mismatched user uuid between ${uuid} and ${payload.userUuid}`)
            resolve({ success: false })
          } else {
            ;(confirm
              ? this.$confirm(this.$t(`shared.messages.confirm.${action}`, { object: object }))
              : new Promise((resolve) => {
                  resolve()
                })
            )
              .then(() => {
                this.$message({
                  message: this.$t(`shared.messages.loading.${action}`, { object: object }),
                  type: 'success'
                })
                this.loading = true
                this.save(payload)
                  .then(({ success, message, data }) => {
                    this.$message({
                      message,
                      type: success ? 'success' : 'error',
                      duration: 10000
                    })
                    resolve({ success, data })
                  })
                  .catch((message) => {
                    // When this is called? Because in api.js the errors are turned into responses and go in the then statement above.
                    this.$message({
                      message: message || this.$t('shared.messages.technical_problem'),
                      type: 'error',
                      duration: 10000
                    })
                    resolve({ success: false })
                  })
              })
              .catch(() => {
                this.$message({
                  message: this.$t('shared.messages.action_cancelled'),
                  type: 'info'
                })
                resolve({ success: false })
              })
          }
        })
      })
    },
    async handleSend(payload) {
      return new Promise((resolve) => {
        this.$confirm(this.$t('shared.actions.confirmation_disclaimer'))
          .then(() => {
            this.$message({
              message: this.$t('shared.actions.loading'),
              type: 'success'
            })
            this.loading = true
            this.send(payload)
              .then(({ success, message }) => {
                this.$message({
                  message,
                  type: success ? 'success' : 'error',
                  duration: 10000
                })
                if (payload.data.newTab) {
                  window.open(payload.data.newTab, '_blank')
                }
                resolve({ success })
              })
              .catch((message) => {
                this.$message({
                  message: message || this.$t('shared.messages.technical_problem'),
                  type: 'error',
                  duration: 10000
                })
                resolve({ success: false })
              })
          })
          .catch(() => {
            this.$message({
              message: this.$t('shared.messages.action_cancelled'),
              type: 'info'
            })
            resolve({ success: false })
          })
      })
    },
    async handleSendMail({ template, locale, templateData, to, from, tag, attachments }) {
      return new Promise((resolve) => {
        this.$confirm(this.$t('shared.messages.confirm.email', { to }), {
          confirmButtonText: this.$t('shared.actions.send'),
          cancelButtonText: this.$t('shared.actions.cancel'),
          dangerouslyUseHTMLString: true
        })
          .then(async () => {
            const { success, message } = await sendEmail({ template, locale, templateData, to, from, tag, attachments })
            if (success) {
              this.$message({
                message,
                duration: 10000,
                showClose: true,
                type: 'success'
              })
            } else {
              this.$message({
                message,
                type: 'error',
                duration: 10000
              })
            }
            resolve({ success })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('shared.messages.send_cancelled')
            })
            resolve({ success: false })
          })
      })
    },
    async handleDestroy({ object, payload }) {
      return new Promise((resolve) => {
        this.$confirm(this.$t('shared.messages.confirm.delete', { object: object }))
          .then((_) => {
            this.$message({
              message: this.$t('shared.messages.loading.delete', { object: object }),
              type: 'success'
            })
            this.destroy(payload)
              .then(({ success, message }) => {
                this.$message({
                  message,
                  type: success ? 'success' : 'error',
                  duration: 10000
                })
                resolve({ success })
              })
              .catch((message) => {
                this.$message({
                  message: message || this.$t('shared.messages.technical_problem'),
                  type: 'error',
                  duration: 10000
                })
                resolve({ success: false })
              })
          })
          .catch((_) => {
            this.$message({
              message: this.$t('shared.messages.action_cancelled'),
              type: 'info'
            })
            resolve({ success: false })
          })
      })
    },
    async handleReinstate({ payload }) {
      return new Promise((resolve) => {
        this.$confirm(this.$t('shared.messages.confirm.reinstate'))
          .then((_) => {
            this.$message({
              message: this.$t('shared.messages.loading.update', { object: 'Contrat' }),
              type: 'success'
            })
            this.reinstate(payload)
              .then(({ success, message }) => {
                this.$message({
                  message,
                  type: success ? 'success' : 'error',
                  duration: 10000
                })
                resolve({ success })
              })
              .catch((message) => {
                this.$message({
                  message: message || this.$t('shared.messages.technical_problem'),
                  type: 'error',
                  duration: 10000
                })
                resolve({ success: false })
              })
          })
          .catch((_) => {
            this.$message({
              message: this.$t('shared.messages.action_cancelled'),
              type: 'info'
            })
            resolve({ success: false })
          })
      })
    },
    async handleUpdate({ confirm, object, label, payload }) {
      return new Promise((resolve) => {
        ;(confirm
          ? this.$confirm(this.$t('shared.messages.confirm.status_update', { label: label, object: object }))
          : new Promise((resolve) => {
              resolve()
            })
        )
          .then((_) => {
            this.update(payload)
              .then(({ success, message }) => {
                this.$message({
                  message,
                  type: success ? 'success' : 'error',
                  duration: 10000
                })
                resolve({ success: true })
              })
              .catch((message) => {
                this.$message({
                  message: message || this.$t('shared.messages.technical_problem'),
                  type: 'error',
                  duration: 10000
                })
                resolve({ success: false })
              })
          })
          .catch((_) => {
            this.$message({
              message: this.$t('shared.messages.action_cancelled'),
              type: 'info'
            })
            resolve({ success: false })
          })
      })
    },
    handleResetForm() {
      if (this.$refs[this.formName]) this.$refs[this.formName].resetFields()
    },
    async handleOtherMethods({ confirm, form, method, payload }) {
      return new Promise((resolve) => {
        form.validate((valid) => {
          if (!valid) {
            this.$message({
              message: this.$t('shared.messages.encoding_problem'),
              type: 'error',
              duration: 10000
            })
            resolve({ success: false })
          } else {
            ;(confirm
              ? this.$confirm(confirm.message, confirm.title)
              : new Promise((resolve) => {
                  resolve()
                })
            )
              .then(() => {
                this.loading = true
                method(payload)
                  .then(({ success, message }) => {
                    this.$message({
                      message,
                      type: success ? 'success' : 'error',
                      duration: 10000
                    })
                    resolve({ success })
                  })
                  .catch((message) => {
                    this.$message({
                      message: message || this.$t('shared.messages.technical_problem'),
                      type: 'error',
                      duration: 10000
                    })
                    resolve({ success: false })
                  })
              })
              .catch(() => {
                this.$message({
                  message: this.$t('shared.messages.action_cancelled'),
                  type: 'info'
                })
                resolve({ success: false })
              })
          }
        })
      })
    }
  }
}
