<template>
  <div>
    <el-row :gutter="1" style="text-align: center">
      <el-col :span="4" v-for="(badge, index) in badgesList" :key="index">
        <div class="badge" v-if="hasBadge(badge)">
          <el-popover
            placement="top-start"
            :title="badgeLabel(badge)"
            width="230"
            trigger="click"
            :content="popOverContent(badge)">
            <template #reference>
              <div>
                <i class="icon" :class="[badgeCurrentLevel(badge), badgeIcon(badge)]"></i>
                <div class="icon-name">{{ badgeLabel(badge) }}</div>
              </div>
            </template>
          </el-popover>
        </div>
        <div class="badge locked" v-else>
          <i class="el-icon-question"></i>
          <div class="icon-name">{{ $t(`gamification.badge_labels.lockedBadge`) }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import badgeConfig from '@/assets/gamificationBadges.json'

export default {
  computed: {
    ...mapState('gamification', [
      'accidentRecords',
      'amendments',
      'bicycleContracts',
      'bugs',
      'carContracts',
      'civilLiabilityContracts',
      'contracts',
      'familyContracts',
      'hospitalizationContracts',
      'houseContracts',
      'houseRentalContracts',
      'independentContracts',
      'legalExpansesContracts',
      'lifeContracts',
      'logins',
      'motorbikeContracts',
      'pensionContracts',
      'personalContracts',
      'sepaMandates',
      'smsMessages',
      'stoppedContracts',
      'terminations',
      'transfers',
      'travelContracts',
      'unpaids',
      'vanContracts',
      'wins'
    ]),
    badgesList() {
      return badgeConfig.map((b) => b.type)
    }
  },
  methods: {
    hasBadge(badge) {
      return this[badge] >= this.targetForNextLevel(badge, 'bronze')
    },
    badgeCurrentLevel(badge) {
      if (this[badge] >= this.targetForNextLevel(badge, 'gold')) {
        return 'gold'
      } else if (this[badge] >= this.targetForNextLevel(badge, 'silver')) {
        return 'silver'
      } else {
        return 'bronze'
      }
    },
    badgeIcon(badge) {
      return badgeConfig.filter((obj) => {
        return obj.type === badge
      })[0].icon
    },
    badgeLabel(badge) {
      return this.$t(`gamification.badge_labels.${badge}`)
    },
    targetForNextLevel(badge, level) {
      return badgeConfig.filter((obj) => {
        return obj.type === badge
      })[0][level]
    },
    popOverContent(badge) {
      const currentLevel = this.badgeCurrentLevel(badge)
      let nextLevel = null
      if (currentLevel === 'bronze') {
        nextLevel = 'silver'
      } else {
        nextLevel = 'gold'
      }
      const motivationGuidance = this.$t(`gamification.badge_messages.${currentLevel}`)
      return `${motivationGuidance} ${this[badge]} / ${this.targetForNextLevel(badge, nextLevel)}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/element-variables.scss';

.badge {
  text-align: center;
  margin: 20px 0px;
  width: 70px;
  height: 80px;
  cursor: pointer;
}

i {
  font-size: 36px;
  margin-bottom: 12px;
}

.icon-name {
  font-size: 12px;
  font-weight: bold;
  color: #606266;
}

.el-icon-question {
  padding: 8px;
}

.icon {
  border-radius: 50%;
  padding: 8px;
  opacity: 0.8;

  &:hover {
    padding: 10px;
  }
}

.gold {
  background-color: $--gold-bg;
  border: 1px solid $--gold;
  color: $--gold;
}

.silver {
  background-color: $--silver-bg;
  border: 1px solid $--silver;
  color: $--silver;
}

.bronze {
  background-color: $--bronze-bg;
  border: 1px solid $--bronze;
  color: $--bronze;
}
</style>
