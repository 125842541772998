export default {
  footer: '© Yago S.A. {year} - Tous droits réservés.',
  helpdesk: {
    field_name: {
      audience: 'Audience',
      client: 'Client',
      description: 'Description',
      domain: 'Domaine',
      link: 'Lien',
      priority: 'Urgence'
    },
    placeholders: {
      client: 'Laisser vide si pas de client spécifique',
      description:
        "Merci d'être aussi précis que possible: quel était le but poursuivi, quel est le message d'erreur ?",
      link: "Copiez-coller l'URL si pertinent",
      select: 'Selectionner'
    },
    send_report: 'Envoyer le rapport',
    title: 'Déclarer un bug',
    unavailable:
      'IT Helpdesk se refait une beauté et sera de retour prochainement !<br><br>En attendant, veuillez poster les demandes directement dans Slack (en utilisant le template approprié).'
  },
  navbar: {
    subtabs: {
      amendments: 'Avenants',
      car_contract_requests: 'Contrats auto',
      reduction_campaigns: 'Campagnes Reduction',
      contracts: 'Contrats à signer',
      house_contract_requests: 'Contrats habitation',
      insurers: 'Compagnies',
      life_procedures: 'Procédures Vie',
      messages: 'SMS',
      motorbike_contract_requests: 'Contrats moto',
      pdf_procedures: 'Procédures PDF',
      sepa_mandates: 'Mandats SEPA',
      targets: 'Targets',
      terminations: 'Renons',
      transfers: 'Transferts',
      unpaids: 'Impayés',
      wins: 'Wins',
      companies: 'Entreprises'
    },
    tabs: {
      actions: 'Suivi & Actions',
      consultation: 'Consultation',
      contracts: 'Leads & Contrats',
      home: 'Home',
      tools: 'Outils',
      b2b: 'B2B'
    }
  }
}
