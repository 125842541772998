import {
  createApp,
  // eslint-disable-next-line no-unused-vars
  h
} from 'vue'
import './style/element-variables.scss'
import withElement from './plugins/element'
import withFontAwesome from './plugins/fontawesome'
import withMoment from './plugins/moment'
import withSentry from './plugins/sentry'
import { withStore } from './store'
import withI18n from './locales'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import VueConfetti from 'vue-confetti'
import App from '@/App.vue'
let app = createApp({
  render: () => h(App)
})

app.use(VueConfetti)
app.use(router)
app = withI18n(app)
app = withElement(app)
app = withFontAwesome(app)
app = withMoment(app)
app = withSentry(app)
app = withStore(app)
app.config.productionTip = false

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mount('#app')
