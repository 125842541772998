import initialState from '../_helpers/initialState'
import api from '@/services/api_v2'

export const teams_colors = [
  '#3B5EEB',
  '#C93BEB',
  '#4F3BEB',
  '#3B96EB',
  '#04B4CF',
  '#893BEB',
  '#DBA544',
  '#DE201F',
  '#DB8044',
  '#EB78CE'
]

export default {
  namespaced: true,
  state: {
    ...initialState,
    teams: [],
    roles: [],
    expertises: []
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_OPERATORS(state, payload) {
      state.data = payload
    },
    SET_TEAMS(state, payload) {
      state.teams = payload
    },
    SET_ROLES(state, payload) {
      state.roles = payload
    },
    SET_EXPERTISES(state, payload) {
      state.expertises = payload
    },
    SET_OPERATOR_TEAM_AND_ROLE(state, payload) {
      state.data = state.data.map((data) =>
        data.email === payload.email ? { ...data, team_id: payload.team_id, team_role: payload.role } : data
      )
    },
    SET_OPERATOR_ROLE(state, data) {
      state.data = state.data.map((operator) =>
        operator.uuid === data.operatorId ? { ...operator, team_role: data.payload.role } : operator
      )
    },
    SET_OPERATOR_EXPERTISES(state, data) {
      state.data = state.data.map((operator) =>
        operator.email === data.email ? { ...operator, expertise: data.expertise } : operator
      )
    }
  },
  actions: {
    async fetchOperators({ commit }) {
      const response = await api.getOperators()
      commit('SET_LOADING', true)
      if (response.data) commit('SET_OPERATORS', response.data)
      commit('SET_LOADING', false)
    },
    async fetchTeams({ commit }) {
      const response = await api.getTeams()
      if (response.data) commit('SET_TEAMS', response.data)
    },
    async fetchRoles({ commit }) {
      const response = await api.getTeamRoles()
      if (response.data) commit('SET_ROLES', response.data)
    },
    async fetchExpertises({ commit }) {
      const response = await api.getExpertises()
      if (response.data) commit('SET_EXPERTISES', response.data)
    },
    async postOperatorTeam({ commit }, data) {
      const response = await api.registerOperatorTeam(data.payload)
      if (response.data) commit('SET_OPERATOR_TEAM_AND_ROLE', data.payload)
      return response
    },
    async postOperatorRole({ commit }, data) {
      const response = await api.changeOperatorRole(data)
      if (response.data) commit('SET_OPERATOR_ROLE', data)
      return response
    },
    async postOperatorExpertises({ commit }, data) {
      const response = await api.changeOperatorExpertises(data)
      if (response.data) commit('SET_OPERATOR_EXPERTISES', data)
      return response
    }
  }
}
