export default {
  age_category: 'Bouwjaar',
  annex_bigger_than_twenty_square_meters: 'Aanbouw >20m²',
  bathrooms: 'Badkamers',
  bedrooms: 'Slaapkamers',
  cellar: 'Kelder',
  commercial_ground_floor: 'Commerciële gelijkvloers',
  facades: 'Gevels',
  forms: {
    select_options: {
      age_categories: [
        {
          label: 'Recent gebouw (minder dan 10 jaar oud)',
          value: 'less_than_10_years'
        },
        {
          label: 'Gebouw 11-20 jaar oud',
          value: 'between_10_and_20_years'
        },
        {
          label: 'Gebouw 21-50 jaar oud',
          value: 'between_21_and_50_years'
        },
        {
          label: 'Oud gebouw (meer dan 50 jaar oud)',
          value: 'more_than_50_years'
        },
        {
          label: 'Gebouw in aanbouw',
          value: 'under_construction'
        },
        {
          label: 'Gebouw wordt gerenoveerd',
          value: 'under_renovation'
        },
        {
          label: 'Ik weet het niet',
          value: 'unknown'
        }
      ],
      residence_types: [
        {
          label: 'Huis',
          value: 'house'
        },
        {
          label: 'Appartement',
          value: 'flat'
        },
        {
          label: 'Opbrengsteigendom',
          value: 'investment_property'
        },
        {
          label: 'Mede-eigendom',
          value: 'co_ownership'
        }
      ],
      solar_panels_placements: [
        {
          label: 'Grond',
          value: 'ground'
        },
        {
          label: 'Dak',
          value: 'roof'
        }
      ]
    }
  },
  game_room: 'Speelkamer',
  garages: 'Garages',
  garden: 'Tuin',
  housing_units: 'Woonunits',
  living_rooms: 'Woonkamers',
  office_room: 'Kantoor',
  remaining_rooms: 'Overige kamers',
  rental_house: 'Huurwoning',
  residence_type: 'Type woning',
  residence_types: {
    co_ownership: 'Mede-eigendom',
    flat: 'Appartement',
    house: 'Huis',
    investment_property: 'Opbrengsteigendom'
  },
  second_home: 'Tweede woning',
  solar_panels: 'Zonnepanelen',
  solar_panels_placement: 'Plaatsing',
  solar_panels_value: 'Aankoopwaarde zonnepanelen',
  swimming_pool: 'Zwembad',
  title: 'Woningdetails',
  unconverted_attic: 'Niet ingerichte zolder',
  veranda: 'Veranda',
  volume: 'Volume'
}
