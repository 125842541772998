export default {
  buttons: {
    add_contract: "Ajouter un contrat à l'offre",
    copy_clipboard: 'Copier le lien',
    eligible: 'Éligible client complet',
    generate_mail: 'Générer un mail',
    not_eligible: 'Non-éligible client complet',
    reactivate: "Réactiver l'offre",
    request_review: 'Demander une review',
    submit_argument: "Soumettre l'argumentaire",
    view_offer: "Voir l'offre multi-contrat"
  },
  client_comment_default_text:
    'En étant client complet chez Yago, pas de franchise en habitation et omnium auto pour 1 sinistre par an (si vos dégâts sont supérieurs aux montants de la franchise)',
  labels: {
    client_argument: 'Argumentaire client',
    client_comment_textarea: 'Argumentaire client (avantages client complet) 💬',
    email: 'Mail envoyé au client'
  },
  messages: {
    comment_added: "Argumentaire client complet ajouté sur l'offre",
    comment_deleted: "Argumentaire client complet supprimé l'offre"
  },
  tags: {
    accepted: 'Offre multi-contrat acceptée par le client !',
    incomplete:
      "Attention, cette offre n'est pas complète. Vérifiez la présence des dates de prise d'effet, des primes, des produits et des commentaires client !",
    email: 'offer-fpb'
  },
  tooltips: {
    client_comment:
      'Conditions pour bénéficier des avantages <br> clients complets des compagnies: avoir<br><b>(1)</b> une voiture, <b>(2)</b> une maison et <b>(3)</b> une RC familiale'
  }
}
