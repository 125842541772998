export default {
  button: {
    new: 'Nieuwe win {user_name}',
    search: 'Zoek de wins'
  },
  contract_request: {
    message: 'Gelieve een bestaande contractaanvraag te creëren / selecteren'
  },
  contract_type: {
    label: 'Type contract',
    new: 'Nieuw',
    transfer: 'Overdracht',
    endorsement: 'Wijziging',
    other: 'Anders'
  },
  type: {
    label: 'Contracttype',
    individual: 'Particulier',
    self_employed: 'Zelfstandige & KMO',
    corporate: 'Grote onderneming & Corporate'
  },
  form: {
    audit: 'Audit',
    placeholder: 'Voornaam en achternaam van de bizdev',
    title: {
      edit: 'Win bewerken',
      new: 'Een win opslaan'
    },
    tooltip:
      "Het kiezen van een simulatie zal slechts bepaalde velden van het auditdeel correct invullen, en zal de simulatie niet 'koppelen' aan de win."
  },
  switch: {
    all_wins: 'Al mijn wins',
    contracts_to_encode: 'In te geven contracten'
  },
  table: {
    columns: {
      tags: {
        danger: 'Niet ingegeven',
        late: 'Te laat',
        success: 'In orde',
        warning: 'Audit incompleet'
      }
    },
    operator_not_found: 'Geen win gevonden voor {operator}',
    operator_wins: 'Wins van {operator}'
  },
  target_disclaimer:
    'Alleen bizdevs die in de targets staan worden getoond in de lijst, maar het is mogelijk om bizdevs te zoeken die er niet in staan',
  title: 'Wins'
}
