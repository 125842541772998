import initialState from '../_helpers/initialState'
import { SET_LOADING, SET_PRODUCTS } from './constants'
import { products } from './services'
const { VUE_APP_DEFAULT_LOCALE } = import.meta.env

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {
    formattedData(state) {
      return state.data
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_PRODUCTS](state, payload) {
      state.data = payload
    }
  },
  actions: {
    async fetch({ state, commit }, { productsLocale }) {
      const locale = productsLocale || VUE_APP_DEFAULT_LOCALE
      commit(SET_LOADING, true)
      products.index(locale).then(({ data }) => {
        commit(SET_PRODUCTS, data)
        commit(SET_LOADING, false)
      })
    }
  }
}
