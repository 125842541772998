import API from '@/services/api'

export const fullPackageBundle = {
  show({ userUuid }) {
    return API.get(`/sw/full_package_bundles/${userUuid}`)
  },
  update(data) {
    return API.put(`/sw/full_package_bundles/${data.userUuid}`, data)
  },
  requestReview({ userUuid, data }) {
    return API.post(`/sw/full_package_bundles/${userUuid}/request_review`, data)
  }
}
