export default {
  attention_aid: 'Concentratie assitentie',
  automatic_emergency_braking: 'Automatisch noodremsysteem',
  brand: 'Merk',
  catalogue_value: 'Cataloguswaarde',
  chassis_number: 'Chassisnummer',
  convertible: 'Cabriolet',
  entry_into_circulation: '1ste inschrijving',
  febiac_id: 'Febiac ID',
  fuel_type: 'Brandstoftype',
  fuel_types: {
    diesel: 'Diesel',
    electric: 'Elektrisch',
    hybrid_petrol_electric: 'Hybride benzine-elektrisch',
    lpg: 'LPG',
    null: '-',
    petrol: 'Benzine',
    unknown: 'Onbekend'
  },
  invoice_value: 'Factuurprijs',
  jeep: 'Jeep',
  lane_departure: 'Rijstrookassistent',
  licence_plate: 'Kenteken',
  model: 'Model',
  oldtimer: 'Oldtimer (+30 jaar)',
  parking_aid: 'Parkeerhulp',
  blind_spot_monitoring: 'Dodehoekwaarschuwing',
  adaptative_cruise_control: 'Adaptieve cruise control',
  pedestrian_detection: 'Voetganger- en fietserdetectie',
  power: 'Vermogen',
  power_categories: {
    '50_to_75_kw': '50kW - 75kW',
    '76_to_100_kw': '76kW - 100kW',
    less_than_50_kw: 'Minder dan 50kW',
    more_than_100_kw: 'Meer dan 100kW',
    null: '-',
    unknown: 'Onbekend'
  },
  power_category: 'Vermogenscategorie',
  seats: 'Aantal zitplaatsen (exclusief bestuurder)',
  sport: 'Sportwagen',
  submodel: 'Submodel',
  title: 'Voertuigdetails',
  version: 'Versie'
}
