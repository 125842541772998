export default {
  digital_bag: {
    label: 'Digitale apparaten',
    covers: {
      omnium: 'Omnium'
    }
  },
  van: {
    label: 'Bestelwagen',
    covers: {
      assistance_to_people: 'Bijstand aan personen',
      assistance: 'Voertuighulp',
      full_omnium: 'Full omnium',
      small_omnium: 'Kleine omnium',
      legal_expenses: 'Rechtsbijstand',
      pj: 'Rechtsbijstand',
      civil_liabilities: 'Burgerlijke Aansprakelijkheid',
      rc: 'Burgerlijke Aansprakelijkheid',
      driver_safety: 'Bestuurdersverzekering'
    }
  },
  family: {
    label: 'Familie',
    covers: {
      pet: 'Verzekering voor hond en kat',
      driver: 'Verzekering verkeersongevallen',
      heritage: 'Successieverzekering',
      civil_liabilities: 'Familiale verzekering',
      rc: 'Familiale verzekering',
      outpatient_fee: 'Verzekering ambulante kosten',
      baby_sitting: 'Kindzorgverzekering',
      hospitalization: 'Hospitalisatieverzekering',
      funeral: 'Uitvaartverzekering',
      guaranteed_income: 'Verzekering gewaarborgd inkomen',
      dental_care: 'Verzekering tandzorg',
      e_reputation: 'E-reputation',
      legal_expenses: 'Familiale rechtsbijstand',
      pj: 'Familiale rechtsbijstand',
      boat_civil_liability: 'Burgerlijke aansprakelijkheid boot'
    }
  },
  house_rental: {
    label: 'Woning (huur)',
    covers: {
      assistance: 'Bijstand woning',
      content: 'Inboedel',
      legal_expenses: 'Rechtsbijstand woning',
      pj: 'Rechtsbijstand woning',
      tenant_civil_liability: 'Wettelijke aansprakelijkheid huurders',
      theft: 'Diefstal',
      jewerly: 'Juwelen'
    }
  },
  house: {
    label: 'Woning (eigenaar)',
    covers: {
      waiver_of_recourse: 'Afstand van verhaal',
      assistance: 'Bijstand woning',
      indirect_losses: 'Indirecte verliezen',
      mortgage: 'Schuldsaldoverzekering',
      construction: "Alle bouwplaats risico's (ABR)",
      content: 'Inboedel',
      residence: 'Woningverzekering',
      rent_guarantee: 'Huurwaarborg',
      garden: 'Tuinpakket',
      legal_expenses: 'Rechtsbijstand',
      pj: 'Rechtsbijstand',
      rent_guarantee_legal_expenses: 'Rechtsbijstand huurwaarborg',
      theft: 'Diefstal',
      jewerly: 'Juwelen'
    }
  },
  independent: {
    label: 'Professioneel',
    covers: {
      work_accident: 'Werkongevallen',
      hospitalization: 'Hospitalisatieverzekering',
      construction: "Alle bouwplaats risico's (ABR)",
      e_reputation: 'E-reputatie',
      pj_professional: 'Rechtsbijstand beroep',
      rc_exploitation: 'BA exploitatie',
      rc_professional: 'BA beroep',
      post_delivery_civil_liability: "BA 'na levering'",
      business_manager_civil_liability: 'Bedrijfsleidersverzekering',
      entrusted_goods_civil_liability: 'Verzekering toevertrouwde voorwerpen',
      events_civil_liability: 'BA evenementen',
      objective_liability: 'Objectieve aansprakelijkheid',
      business_interruption: 'Bedrijfschade',
      volontary_liablity: 'Vrijwillige aansprakelijkheid',
      machinery_breakdown: 'Machineschade',
      decennial_liability: '10-jarige BA',
      credit_insurance: 'Kredietverzekering',
      own_account_transport: 'Vervoer Eigen Rekening',
      third_party_transport: 'Vervoer rekening derden - CMR',
      container_liability: 'Container- en traileraansprakelijkheid',
      cyber: 'Cyberverzekering',
      group_hospitalization: 'Collectieve ziekenhuisverzekering'
    }
  },
  motorbike: {
    label: 'Motor',
    covers: {
      assistance_to_people: 'Bijstand aan personen',
      assistance: 'Pechbijstand',
      full_omnium: 'Full omnium',
      small_omnium: 'Kleine omnium',
      legal_expenses: 'Rechtsbijstand',
      pj: 'Rechtsbijstand',
      civil_liabilities: 'Burgerlijke Aansprakelijkheid',
      rc: 'Burgerlijke Aansprakelijkheid',
      driver_safety: 'Bestuurdersverzekering',
      motorcyclist_equipment: 'Uitrusting van de motorrijder'
    }
  },
  motorhome: {
    label: 'Camper',
    covers: {
      assistance_to_people: 'Bijstand aan personen',
      assistance: 'Pechbijstand',
      full_omnium: 'Full omnium',
      small_omnium: 'Kleine omnium',
      legal_expenses: 'Rechtsbijstand',
      pj: 'Rechtsbijstand',
      civil_liabilities: 'Burgerlijke Aansprakelijkheid',
      rc: 'Burgerlijke Aansprakelijkheid',
      driver_safety: 'Bestuurdersverzekering'
    }
  },
  pension: {
    label: 'Pensioen',
    covers: {
      life_group: 'Groepsverzekering leven',
      pension_agreement_self_employed: 'Vrij Aanvullend Pensioen voor Zelfstandigen (VAPZ)',
      individual_pension_commitment: 'Individuele Pensioentoezegging (IPT)',
      non_tax_savings: 'Niet-fiscaal sparen',
      pension_savings: 'Pensioensparen',
      long_term_savings: 'Lange termijnsparen',
      free_supplementary_pension: 'Bijkomend sparen',
      inami: 'Riziv',
      bonus_plan: 'Bonus Plan'
    }
  },
  personal: {
    label: 'Actief leven',
    covers: {
      foresight: 'Ongevallenverzekering',
      incident: 'Verzekering lichamelijke ongevallen',
      hunting_legal_expenses: 'Rechtsbijstand Jacht',
      hunting_civil_liability: 'Burgerlijke aansprakelijkheidsverzekering Jacht',
      organizer_civil_liability: 'BA evenementorganisatoren'
    }
  },
  car: {
    label: 'Auto',
    covers: {
      assistance_to_people: 'Bijstand aan personen',
      assistance: 'Pechbijstand',
      full_omnium: 'Full omnium',
      small_omnium: 'Kleine omnium',
      legal_expenses: 'Rechtsbijstand',
      pj: 'Rechtsbijstand',
      civil_liabilities: 'Burgerlijke Aansprakelijkheid',
      rc: 'Burgerlijke Aansprakelijkheid',
      driver_safety: 'Bestuurdersverzekering',
      glass_breakage: 'Glasbreuk'
    }
  },
  travel: {
    label: 'Reizen',
    covers: {
      assistance: 'Reisbijstand',
      accident: 'Reisongevallen verzekering',
      cancellation: 'Annulatieverzekering reizen',
      luggage: 'Bagageverzekering'
    }
  },
  bicycle: {
    label: 'Fiets',
    covers: {
      assistance: 'Pechbijstand',
      damages: 'Verzekering materiële schade',
      incident: 'Individuele ongevallenverzekering',
      theft: 'Diefstalverzekering'
    }
  },
  boat: {
    label: 'Boot',
    covers: {
      assistance: 'Pechbijstand',
      full_omnium: 'Full omnium',
      legal_expenses: 'Rechtsbijstand',
      pj: 'Rechtsbijstand',
      civil_liabilities: 'Burgerlijke Aansprakelijkheid',
      rc: 'Burgerlijke Aansprakelijkheid'
    }
  },
  'push-scooter': {
    label: 'Step',
    covers: {
      assistance: 'Pechbijstand',
      full_omnium: 'Full omnium',
      legal_expenses: 'Rechtsbijstand',
      pj: 'Rechtsbijstand',
      civil_liabilities: 'Burgerlijke Aansprakelijkheid',
      rc: 'Burgerlijke Aansprakelijkheid',
      driver_safety: 'Bestuurdersverzekering'
    }
  }
}
