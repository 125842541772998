<template>
  <div class="p-20" v-loading="meta.loading">
    <h1>{{ $t('gamification.title') }}</h1>
    <br />
    <el-col :span="12">
      <GamificationMedals @updateMedals="handleModiferChange"></GamificationMedals>
      <hr />
      <GamificationLevel :medal-scores="medalScores" v-if="medalScores"></GamificationLevel>
      <hr />
      <GamificationBadges></GamificationBadges>
    </el-col>
    <el-col :span="12">
      <GamificationCharts></GamificationCharts>
    </el-col>
  </div>
</template>
<script>
import GamificationBadges from '@/components/gamification/GamificationBadges'
import GamificationLevel from '@/components/gamification/GamificationLevel'
import GamificationMedals from '@/components/gamification/GamificationMedals'
import GamificationCharts from '@/components/gamification/GamificationCharts'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    GamificationBadges,
    GamificationLevel,
    GamificationMedals,
    GamificationCharts
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      medalScores: null
    }
  },
  computed: {
    ...mapState('gamification', ['meta'])
  },
  created() {
    this.fetch()
  },
  methods: {
    ...mapActions('gamification', ['fetch']),
    handleModiferChange(medalScores) {
      this.medalScores = medalScores
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  font-weight: bold;
}
</style>
