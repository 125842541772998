export default {
  build_year: 'Bouwjaar:',
  chassis_number: 'Chassisnummer:',
  engine_displacement: 'Cilinderinhoud:',
  entry_into_circulation: '1ste inschrijving:',
  licence_plate: 'Kentekenplaat:',
  manufacturer: 'Merk:',
  missing_vehicle: 'Motorfietsdetails onbekend',
  model: 'Model:',
  motorbike_formula: 'Formule motor:',
  motorbike_type: {
    oldtimer: 'Oldtimer',
    scooter: 'Scooter',
    standard: 'Standaard',
    unspecified: 'Niet gespecificeerd'
  },
  pre_tax_price: 'Prijs exclusief BTW:',
  second_hand: 'Tweedehands:',
  seniority_formula: 'Leeftijdsformule:',
  title: 'Motorfietsdetails',
  type: 'Type motorfiets:',
  usage: {
    null: '',
    private: 'Privé',
    private_and_go_to_work: 'Privé en werkverkeer',
    professional: 'Professioneel',
    unspecified: 'Niet gespecificeerd'
  }
}
