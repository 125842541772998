export default {
  age_category: "L'année de construction",
  annex_bigger_than_twenty_square_meters: 'Annexe >20m²',
  bathrooms: 'Salles de bains',
  bedrooms: 'Chambres à coucher',
  cellar: 'Cave',
  commercial_ground_floor: 'Rez-de-chaussée commercial',
  facades: 'Façades',
  forms: {
    select_options: {
      age_categories: [
        {
          label: 'Bâtiment récent (moins de 10 ans)',
          value: 'less_than_10_years'
        },
        {
          label: 'Bâtiment 11-20 ans',
          value: 'between_10_and_20_years'
        },
        {
          label: 'Bâtiment 21-50 ans',
          value: 'between_21_and_50_years'
        },
        {
          label: 'Bâtiment ancien (plus de 50 ans)',
          value: 'more_than_50_years'
        },
        {
          label: 'Bâtiment en construction',
          value: 'under_construction'
        },
        {
          label: 'Bâtiment en rénovation',
          value: 'under_renovation'
        },
        {
          label: 'Je ne sais pas',
          value: 'unknown'
        }
      ],
      residence_types: [
        {
          label: 'Maison',
          value: 'house'
        },
        {
          label: 'Appartement',
          value: 'flat'
        },
        {
          label: 'Immeuble de rapport',
          value: 'investment_property'
        },
        {
          label: 'Co-propriété',
          value: 'co_ownership'
        }
      ],
      solar_panels_placements: [
        {
          label: 'Sol',
          value: 'ground'
        },
        {
          label: 'Toit',
          value: 'roof'
        }
      ]
    }
  },
  game_room: 'Salle de jeu',
  garages: 'Garages',
  garden: 'Jardin',
  housing_units: 'Unités de logement',
  living_rooms: 'Pièces de vie',
  office_room: 'Bureau',
  remaining_rooms: 'Autres pièces',
  rental_house: 'Bien en location',
  residence_type: 'Type de résidence',
  residence_types: {
    co_ownership: 'Co-propriété',
    flat: 'Appartement',
    house: 'Maison',
    investment_property: 'Immeuble de rapport'
  },
  second_home: 'Résidence secondaire',
  solar_panels: 'Panneaux solaires',
  solar_panels_placement: 'Placement',
  solar_panels_value: "Leur valeur d'achat",
  swimming_pool: 'Piscine',
  title: "Détails de l'habitation",
  unconverted_attic: 'Grenier non aménagé',
  veranda: 'Véranda',
  volume: 'Volume'
}
