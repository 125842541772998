export default {
  actions: {
    cancel: 'Annuler',
    confirm: 'Êtes-vous sûr de cette opération ? Elle sera irréversible.',
    confirm_button: 'Confirmer',
    edit_covers: 'Modifer les garanties',
    house_details: "Détails de l'habitation",
    load_message: 'Opération en cours...',
    reasons_for_ineligibility_of_offer: "Motifs d'inéligibilité",
    remove_from_full_package_bundle: "Retirer de l'offre",
    resume_purchase: "Reprendre l'achat",
    save_contract: 'Enregistrer',
    success_message: 'Opération effectuée avec succès',
    vehicle_details: 'Détails du véhicule'
  },
  bad_risk_disclaimer: '⚠️ Client fiché / Profil à risque, Contacter la compagnie pour acceptation.',
  card_titles: {
    client: 'Client',
    contract: 'Contrat',
    licence_plate: "Plaque d'immatriculation",
    private_savings: 'Epargne privée',
    self_employed_savings: 'Epargne indépendant',
    termination: 'Renon',
    usage: 'Usage du véhicule',
    vehicle: 'Véhicule'
  },
  new_partner: 'Nouvelle simulation {partner} 🏡',
  client_comments: {
    coupled_as_beneficiary:
      "\n- Vous bénéficiez d'une réduction de votre prime d'assurance suite à un couplage avec d'autres contrats. Vous comprenez que si vous veniez à résilier l'un de ces contrats sans le remplacer, vous n'aurez plus accès à cette réduction."
  },
  elements: {
    additional_young_driver: 'Conducteur additionnel (-26ans)',
    already_has_licence_plate: 'Possède déjà une plaque:',
    already_insured: 'Déjà assuré',
    annual_invested_amount_after_fees: 'Montant annuel investit',
    annual_paid_amount: 'Montant annuel payé',
    baloise_car_pack_disclaimer: "Impossible de changer les prix et couvertures d'une simulation BCP.",
    birthdate: 'Date de naissance',
    client_comment: 'Commentaire client',
    comments: 'Commentaires',
    commercial_vehicle: 'Utilitaire',
    contract_completed: 'Complet',
    contract_non_completed: 'Incomplet',
    contracts: 'Contrats',
    coupled_as_beneficiary: 'Couplé en tant que bénéficiaire',
    current_company_employment_start_date: 'Date de début dans la société actuelle',
    current_licence_plate: 'Plaque actuelle:',
    dealer_name: 'Destinataire',
    death_benefit: "Montant de l'assurance vie",
    death_benefit_cost: "Tarif de l'assurance vie",
    default_comment: 'Commentaire par défaut',
    details: 'Détails',
    driver_age_category: 'Age du conducteur:',
    driver_claims_at_fault: 'Sinistre en tort | Sinistre en tort | Sinistres en tort',
    driver_driving_licence_date: 'Date du permis:',
    driver_insured_years: 'Assuré depuis',
    driver_last_claim_at_fault: 'Dernier sinistre en tort',
    driver_no_claim_bonus: 'Bonus Malus',
    false: 'Non',
    final_capital: 'Capital à terme',
    gross_premium: 'Prix € / An',
    independent_cover_disclaimer: "Les garanties indépendantes n'ont qu'un seul prix pour le total des couvertures.",
    licence_plate_delivery_address: 'Adresse de livraison:',
    limited_drive: 'Moins de 10.000km/an',
    long_term_savings: 'Epargne long terme',
    max_deposit_for_current_year: 'Montant maximum première année',
    mortgage: 'Crédit hypothécaire dans les 6 prochains mois',
    motorbike_disclaimer: "Impossible de changer les prix et couvertures d'une simulation moto Baloise.",
    no_claim_bonus: 'Bonus Malus retenu',
    not_insured: 'Pas assuré',
    null: '-',
    odometer_mileage: 'Kilomètres/an',
    odometer_reading_date: "Date d'observation",
    omnium: 'Désire une omnium',
    pension_savings: 'Epargne pension',
    products: 'Produits',
    professional_usage_types: "Type(s) d'utilisation professionel",
    real_annual_cost: 'Coût annuel réel',
    retirement_age: 'Age à la retraite',
    secured_garage: 'Garage sécurisé',
    self_employment_start_date: 'Date de début de carrière (indépendant)',
    smoker: 'Fumeur',
    start_date: 'Date de prise d’effet',
    termination_contract_number: 'Référence du contrat renoncé:',
    termination_insurer: 'Compagnie renoncée:',
    termination_policy_end_date: "Date d'échéance du renon:",
    termination_price_increase: 'Augmentation tarifaire:',
    total: 'Total',
    total_cost: 'Coût total',
    total_final_capital: 'Capital à terme total',
    total_net_gain_amount: 'Bénéfice net',
    total_price: 'Prix total',
    total_tax_deductible: "Déduction d'impôt totale",
    trailer: 'Remorque (+750kg)',
    true: 'Oui',
    uncomplete_risk_object_disclaimer: "Attention, les informations sur l'objet de risque ne sont pas complètes.",
    usage: 'Utilisation',
    vehicle_registered_with_existing_plate: 'Véhicule déjà immatriculé avec cette plaque:',
    yearly_salary: 'Salaire annuel',
    yearly_tax_deductible: "Déduction d'impôt par an",
    years_of_contributions: "Nombre d'années de cotisations"
  },
  faq: 'Des questions sur le process moto ? Consultez la <a target="_blank" href="https://seraphin.slite.com/api/s/note/SmgYjwYZcaxZwN6jsSoCao/FAQ">FAQ</a> à ce sujet.',
  ineligibility_reasons: {
    modal_title:
      "Cette simulation n'a pas donné lieu à une page de sélection et un calcul de prix,<br>pour les raisons suivantes:"
  },
  links: {
    markdown: 'Aide de mise en forme'
  },
  modal: {
    disclaimer: "Pour ce faire, veuillez compléter le processus d'achat en ligne jusqu'au bout",
    radio: {
      assistance: 'Assistance',
      driver_safety: 'Sécurité du conducteur',
      legal_expenses: 'Protection juridique'
    },
    switch: {
      independent_cover: 'Garantie indépendante'
    },
    titles: {
      choose_contract_covers: 'Choisir les garanties d’un contrat',
      choose_pre_existing_contract_request: 'Choisir une demande de contrat existante',
      edit_covers: 'Modifier les garanties'
    }
  },
  professional_usage_types: {
    goods_delivery: 'Transport de marchandises',
    people_delivery: 'Transport de personnes',
    standard: 'Standard'
  },
  titles: {
    cars: '🚗  Contrats auto',
    houses: '🏠 Contrats habitation',
    motorbikes: '🏍️  Contrats moto'
  },
  types: {
    aedes_car_cares: '🚗 Auto',
    aedes_car_p: '🚗 Auto',
    ag_life_eip: '💼 Epargne indépendant (EIP)',
    ag_life_plci: '💼 Epargne indépendant (PLCI)',
    ag_life_savings_branch_21: '👴 Epargne privée',
    axa_car_confort: '🚗 Auto',
    baloise_car_mobility_safe1: '🚗 Auto',
    baloise_house_select: '🏠 Habitation',
    baloise_moto_mobility_safe1: '🏍️ Moto',
    seraphin_car_pack: '🚗 Auto',
    vivium_car_auto: '🚗 Auto'
  },
  usages: {
    private: 'Privée',
    professional: 'Professionnelle'
  }
}
