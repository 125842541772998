export default {
  actions: {
    cancel: 'Annuleren',
    confirm: 'Weet je zeker dat je deze handeling wilt uitvoeren? Deze actie is onomkeerbaar.',
    confirm_button: 'Bevestigen',
    edit_covers: 'Dekkingen wijzigen',
    house_details: 'Details van de woning',
    load_message: 'Bezig met verwerken...',
    reasons_for_ineligibility_of_offer: 'Redenen van niet in aanmerking komen',
    remove_from_full_package_bundle: 'Verwijderen uit het voorstel',
    resume_purchase: 'Aankoop hervatten',
    save_contract: 'Opslaan',
    success_message: 'Handeling succesvol uitgevoerd',
    vehicle_details: 'Voertuigdetails'
  },
  bad_risk_disclaimer: '⚠️ Klant gemeld / Risicoprofiel, Neem contact op met de maatschappij voor goedkeuring.',
  card_titles: {
    client: 'Klant',
    contract: 'Contract',
    licence_plate: 'Kenteken',
    private_savings: 'Sparen privé',
    self_employed_savings: 'Sparen zelfstandige',
    termination: 'Opzeg',
    usage: 'Gebruik van het voertuig',
    vehicle: 'Voertuig'
  },
  new_partner: 'Nieuwe simulatie {partner} 🏡',
  client_comments: {
    coupled_as_beneficiary:
      '\n- Je profiteert van een korting op je verzekeringspremie door een koppeling met andere contracten. Indien je een van deze contracten opzegt zonder vervanging, verlies je het recht op deze korting.'
  },
  elements: {
    additional_young_driver: 'Extra jonge bestuurder (-26 jaar)',
    already_has_licence_plate: 'Heeft al een kenteken:',
    already_insured: 'Al verzekerd',
    annual_invested_amount_after_fees: 'Jaarlijks belegd bedrag',
    annual_paid_amount: 'Jaarlijks betaald bedrag',
    baloise_car_pack_disclaimer: 'Het is niet mogelijk om prijzen en dekkingen van een BCP-simulatie te wijzigen.',
    birthdate: 'Geboortedatum',
    client_comment: 'Klantopmerking',
    comments: 'Opmerkingen',
    commercial_vehicle: 'Bedrijfsvoertuig',
    contract_completed: 'Voltooid',
    contract_non_completed: 'Niet voltooid',
    contracts: 'Contracten',
    coupled_as_beneficiary: 'Gekoppeld als begunstigde',
    current_company_employment_start_date: 'Startdatum huidige in dienst treding',
    current_licence_plate: 'Huidig kenteken:',
    dealer_name: 'Ontvanger',
    death_benefit: 'Bedrag levensverzekering',
    death_benefit_cost: 'Kosten levensverzekering',
    default_comment: 'Standaardopmerking',
    details: 'Details',
    driver_age_category: 'Leeftijd van de bestuurder:',
    driver_claims_at_fault: 'Schadegevallen in fout | Schadegevallen in fout | Schadegevallen in fout',
    driver_driving_licence_date: 'Datum rijbewijs:',
    driver_insured_years: 'Verzekerd sinds',
    driver_last_claim_at_fault: 'Laatste ongeval in fout',
    driver_no_claim_bonus: 'Bonus-malus',
    false: 'Nee',
    final_capital: 'Eindkapitaal',
    gross_premium: 'Premie € / Jaar',
    independent_cover_disclaimer: 'Onafhankelijke dekkingen hebben slechts één premie voor de totale dekking.',
    licence_plate_delivery_address: 'Leveradres:',
    limited_drive: 'Minder dan 10.000 km/jaar',
    long_term_savings: 'Langetermijnsparen',
    max_deposit_for_current_year: 'Maximum bedrag eerste jaar',
    mortgage: 'Hypotheek binnen 6 maanden',
    motorbike_disclaimer: 'Het is niet mogelijk om prijzen en dekkingen van een Baloise motor-simulatie te wijzigen.',
    no_claim_bonus: 'Gebruikte bonus-malus',
    not_insured: 'Niet verzekerd',
    null: '-',
    odometer_mileage: 'Kilometers/jaar',
    odometer_reading_date: 'Datum kilometerstand',
    omnium: 'Wenst omnium',
    pension_savings: 'Pensioensparen',
    products: 'Producten',
    professional_usage_types: 'Type(s) van professioneel gebruik',
    real_annual_cost: 'Werkelijke jaarlijkse kosten',
    retirement_age: 'Pensioenleeftijd',
    secured_garage: 'Beveiligde garage',
    self_employment_start_date: 'Startdatum zelfstandige activiteit',
    smoker: 'Roker',
    start_date: 'Ingangsdatum',
    termination_contract_number: 'Referentie van opgezegd contract:',
    termination_insurer: 'Opgezegde verzekeraar:',
    termination_policy_end_date: 'Einddatum opzeg:',
    termination_price_increase: 'Tariefverhoging:',
    total: 'Totaal',
    total_cost: 'Totale kosten',
    total_final_capital: 'Totaal eindkapitaal',
    total_net_gain_amount: 'Netto winst',
    total_price: 'Totale prijs',
    total_tax_deductible: 'Totale fiscale aftrekbaarheid',
    trailer: 'Aanhangwagen (+750kg)',
    true: 'Ja',
    uncomplete_risk_object_disclaimer: 'Let op, de informatie over het risico-object is niet volledig.',
    usage: 'Gebruik',
    vehicle_registered_with_existing_plate: 'Voertuig al ingeschreven met dit kenteken:',
    yearly_salary: 'Jaarlijks salaris',
    yearly_tax_deductible: 'Jaarlijkse fiscale aftrekbaarheid',
    years_of_contributions: 'Aantal gestorte jaren'
  },
  faq: 'Vragen over het motorproces? Raadpleeg de <a target="_blank" href="https://seraphin.slite.com/api/s/note/SmgYjwYZcaxZwN6jsSoCao/FAQ">FAQ</a> hierover.',
  ineligibility_reasons: {
    modal_title:
      'Deze simulatie heeft niet geleid tot een selectiepagina en een prijsberekening,<br>om de volgende redenen:'
  },
  links: {
    markdown: 'Opmaakhulp'
  },
  modal: {
    disclaimer: 'Om dit te doen, voltooi het online aankoopproces volledig',
    radio: {
      assistance: 'Bijstand',
      driver_safety: 'Bestuurdersverzekering',
      legal_expenses: 'Rechtsbijstand'
    },
    switch: {
      independent_cover: 'Onafhankelijke waarborg'
    },
    titles: {
      choose_contract_covers: 'Kies dekkingen voor een contract',
      choose_pre_existing_contract_request: 'Kies een bestaande contractaanvraag',
      edit_covers: 'Dekkingen wijzigen'
    }
  },
  professional_usage_types: {
    goods_delivery: 'Goederenvervoer',
    people_delivery: 'Personenvervoer',
    standard: 'Standaard'
  },
  titles: {
    cars: '🚗  Autocontracten',
    houses: '🏠 Wooncontracten',
    motorbikes: '🏍️  Motorcontracten'
  },
  types: {
    aedes_car_cares: '🚗 Auto',
    aedes_car_p: '🚗 Auto',
    ag_life_eip: '💼 Fiscaal sparen zelfstandige (EIP)',
    ag_life_plci: '💼 Fiscaal sparen zelfstandige (PLCI)',
    ag_life_savings_branch_21: '👴 Fiscaal sparen privé',
    axa_car_confort: '🚗 Auto',
    baloise_car_mobility_safe1: '🚗 Auto',
    baloise_house_select: '🏠 Wonen',
    baloise_moto_mobility_safe1: '🏍️ Moto',
    seraphin_car_pack: '🚗 Auto',
    vivium_car_auto: '🚗 Auto'
  },
  usages: {
    private: 'Privé',
    professional: 'Professioneel'
  }
}
