<template>
  <span>
    <el-button :icon="Service" @click="handleOpen" circle="circle"></el-button>
    <el-dialog v-model="visible" :title="$t('layout.helpdesk.title')" center="center" width="35%" append-to-body>
      <p v-html="$t('layout.helpdesk.unavailable')"></p>
    </el-dialog>
  </span>
</template>

<script>
import { handlers } from '@/mixins/handlers'
import { Service } from '@element-plus/icons-vue'

export default {
  name: 'NewBug',
  mixins: [handlers],
  data() {
    return {
      visible: false
    }
  },
  computed: {
    Service() {
      return Service
    }
  },
  methods: {
    handleOpen() {
      this.visible = true
    }
  }
}
</script>
