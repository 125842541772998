export default {
  see_green_card: "Voir le certificat d'assurance",
  title: "Certificat d'assurance",
  statuses: {
    definitive: 'Définitive',
    temporary: 'Temporaire'
  },
  validity: {
    future: 'Future',
    archived: 'Expirée',
    valid: 'Valide'
  }
}
