<template>
  <el-row class="charts" v-loading="loading">
    <line-chart :class="chart" :chartdata="winsChartData" :options="chartOptions" />
    <hr />
    <horizontal-bar-chart :class="chart" :chartdata="contractsChartData" :options="chartOptions" />
  </el-row>
</template>

<script>
import { mapState } from 'vuex'
import { calculator } from '@/mixins/calculator'
import LineChart from './Charts/LineChart.js'
import HorizontalBarChart from './Charts/HorizontalBarChart.js'

export default {
  name: 'GamificationCharts',
  components: { LineChart, HorizontalBarChart },
  mixins: [calculator],
  data() {
    return {
      chart: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true
              },
              ticks: {
                min: 0
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapState('gamification', [
      'contracts',
      'bicycleContracts',
      'carContracts',
      'familyContracts',
      'houseContracts',
      'houseRentalContracts',
      'independentContracts',
      'personalContracts',
      'pensionContracts',
      'motorbikeContracts'
    ]),
    ...mapState('wins', ['data', 'loading']),
    winsChartData() {
      return {
        labels: this.range(1, 52).map((week) => `semaine ${week}`),
        datasets: [
          {
            label: `Mes wins | Semaine: ${this.currentWeek}/52 `,
            backgroundColor: '#ddf3f2',
            borderColor: '#4bc0c0',
            data: this.weeklyAggregatedWins
          }
        ]
      }
    },
    contractsChartData() {
      return {
        labels: ['#Vélo', '#Voiture', '#Famille', '#Maison', '#Indépendant', '#Personel', '#Pension', '#Moto'],
        datasets: [
          {
            label: 'Mes contrats',
            backgroundColor: ['#ffe1e6', '#ffecdb', '#daf5f5', '#fff5df', '#daecfc', '#ebe0ff', '#ffeaf9', '#f4ffe5'],
            borderColor: ['#ff6284', '#ff9f40', '#4bc0c0', '#ffcd56', '#36a2eb', '#9966fe', '#ff77f3', '#07d237'],
            borderWidth: 1,
            barPercentage: 0.9,
            categoryPercentage: 0.8,
            data: [
              this.bicycleContracts,
              this.carContracts,
              this.familyContracts,
              this.houseContracts + this.houseRentalContracts,
              this.independentContracts,
              this.personalContracts,
              this.pensionContracts,
              this.motorbikeContracts
            ]
          }
        ]
      }
    },
    winsTimestamps() {
      return this.data.map((win) => win.timestamp)
    },
    oneYearOfWeeklydWinsTimestamps() {
      // For the time being the wins statistics are only computed for the current year.
      // TODO: Create the logic to dynamicly display the wins from the first week.

      const weeklyWins = this.winsTimestamps.map((win) => this.$date(parseInt(win)).format('ww'))
      const weeks = [...this.range(1, 9).map((w) => `0${w}`), ...this.range(10, 52).map((w) => w.toString())]
      const completedWeeklyWins = []

      weeks.forEach((week) => {
        weeklyWins.includes(week) ? completedWeeklyWins.push(week) : completedWeeklyWins.push('')
      })

      return completedWeeklyWins
    },
    weeklyAggregatedWins() {
      const weeklyWinsCount = []

      this.oneYearOfWeeklydWinsTimestamps.forEach((week) => {
        let weeklyWins = 0
        this.winsTimestamps.forEach((win) => {
          if (this.$date(parseInt(win)).format('ww') === week) weeklyWins += 1
        })
        weeklyWinsCount.push(weeklyWins)
      })
      return weeklyWinsCount
    },
    currentWeek() {
      return this.$date(parseInt(Date.now())).format('ww')
    }
  }
}
</script>

<style lang="css" scoped>
.charts {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  height: 80%;
  border-left: 1px solid #eeeeee;
}

.chart {
  height: 40%;
}
</style>
