import API from '@/services/api'

export const wins = {
  index(data) {
    return API.get('/wins', data)
  },
  create(data) {
    return API.post('/wins', data)
  },
  update(data) {
    return API.put('/wins', data)
  },
  destroy(data) {
    return API.delete('/wins', data)
  }
}

export const targets = {
  index() {
    return API.get('/wins/targets')
  }
}
