export const castStringBooleans = (data) => {
  const stringToBool = (e) => {
    if (e === 'true') return true
    if (e === 'false') return false
    return e
  }

  const castObject = (object) => {
    Object.entries(object).forEach(([key, value]) => {
      object[key] = stringToBool(value)
    })
  }
  data.length ? data.forEach((object) => castObject(object)) : castObject(data)

  return data
}
