import { SET_LOADING, SET_BUNDLED_PAYMENT_BUNDLE } from './constants'
import { bundledPayment } from './services'
import initialState from '../_helpers/initialState'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_BUNDLED_PAYMENT_BUNDLE](state, payload) {
      state.data = payload
    }
  },
  actions: {
    async send({ state, commit }, params) {
      commit(SET_LOADING, true)
      const { data, success, message } = await bundledPayment.post(params)
      commit(SET_BUNDLED_PAYMENT_BUNDLE, data)
      commit(SET_LOADING, false)
      return { success, message }
    },
    async save({ state, commit }, params) {
      commit(SET_LOADING, true)
      const { data, success, message } = await bundledPayment.put(params)
      commit(SET_BUNDLED_PAYMENT_BUNDLE, data)
      commit(SET_LOADING, false)
      return { success, message }
    },
    async archive({ state, commit }, data) {
      const { success, message } = await bundledPayment.archive(data)
      return { success, message }
    }
  }
}
