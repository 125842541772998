export default {
  title: 'Sinistres',
  card_titles: {
    context: 'Circonstances',
    general_info: 'Déclaration du client',
    documents: 'Documents'
  },
  elements: {
    incident_date: 'Date du sinistre:',
    damage_cause: 'Raison du sinistre:',
    incident_location: 'Lieu du sinistre:',
    comments: 'Remarques:',
    damage_description: 'Quels sont les dommages matériels subis ?',
    damage_document_urls: 'Documents du sinistres (Constat, photos du sinistre)',
    repair_quote_url: 'Devis de réparation',
    weather_event: 'Causé par un événement climatique ?',
    police_report_filed: 'Un procès verbal a-t-il été établi par la police ?',
    police_report_number: 'Numéro du procès-verbal',
    inspection_garage_details: "Quel est le nom et l'adresse du garage où votre véhicule va pouvoir être expertisé ?",
    licence_plate: 'Quel est le numéro de plaque de votre véhicule?',
    vin: 'Quel est le numéro de châssis de votre véhicule ?',
    self_assessed_liability: 'Responsabilité sur le sinistre :',
    vat_deducted: 'Avez-vous acheté votre véhicule en déduisant la TVA ?',
    vat_deduction_percentage: 'Quel pourcentage de TVA avez-vous déduit ?',
    housing_status: 'Êtes vous propriétaire ou locataire ?',
    repair_estimate_available: 'Avez-vous déjà un devis pour la réparation des dommages ?',
    injured: 'Avez-vous été blessé ?',
    injury_description: 'Quelles blessures avez-vous ?',
    injury_to_others: "D'autres personnes ont-elles été blessées ?",
    witness: 'Y avait-il des témoins ?',
    witness_information: 'Informations sur le témoin'
  },
  damage_causes: {
    water: 'Dégâts des eaux (eau, mazout, champignons)',
    vehicle_collision: 'Collision entre véhicules',
    theft: 'Vol, vandalisme, autres',
    legal_protection: 'Protection juridique',
    glass_breakage: 'Bris de vitre',
    other: 'Autres',
    unknown: 'Inconnu',
    weather: 'Événement climatique',
    object_collision_or_loss_of_control: 'Collision avec un objet, perte de contrôle',
    collision: 'Collision avec un objet',
    glass: 'Bris de vitre'
  },
  self_assessed_liabilities: {
    '50_50': '50-50: les torts sont partagés',
    in_right: 'En droit',
    at_fault: 'En tort',
    unknown: 'Je ne sais pas'
  }
}
