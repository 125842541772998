import API from '@/services/api'

export const transfers = {
  index(params) {
    const userUuid = params ? params.user_uuid : ''
    return API.get(`/transfers/${userUuid}`)
  },
  create(data) {
    return API.post('/transfers', data)
  },
  update(data) {
    return API.put('/transfers', data)
  }
}
