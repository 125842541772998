export default {
  multi_products: {
    accident_record: 'ik wacht nog steeds op je schadeattest. Kan je dit zo snel mogelijk aan mij doorgeven?',
    address:
      'ik was je contract aan het opstellen, maar ik zie dat ik je postadres (straat, nummer, bus, postcode en stad) niet heb. Kan je mij dit per SMS beantwoorden?',
    documents: 'ik wacht nog steeds op je documenten om je contract snel op te zetten. Kan je mij deze doorsturen?',
    driver_record: 'ik wacht nog steeds op je schadeattesten. Kan je dit zo snel mogelijk aan mij doorgeven?',
    iban: 'ik was je contract aan het opstellen, maar ik zie dat ik je IBAN nog niet heb. Kan je mij dit per SMS beantwoorden?',
    offer_reminder_today:
      'ik heb je zojuist het voorstel voor je verzekeringen per mail gestuurd. Kan ik je vanmiddag bellen om dit te bespreken?',
    offer_reminder_tomorrow:
      'ik heb je zojuist het voorstel voor je verzekeringen per mail gestuurd. Kan ik je morgenochtend bellen om dit te bespreken?',
    offer_when_can_we_call_day_1:
      'ik heb je zojuist gebeld in verband met het voorstel voor je verzekering. Wanneer schikt het jou om dit te bespreken? Fijne dag verder!',
    offer_when_can_we_call_day_5:
      'bent je geïnteresseerd in het verzeleringsvoorstel? Wanneer kan ik je bellen om dit te bespreken? Fijne dag verder!',
    sepa_mandate: 'ik wacht nog steeds op het ondertekende SEPA-mandaat. Kan je mij dit zo snel mogelijk doorgeven?',
    unpaid: 'heb je de tijd gehad om de overschrijving voor je verzekering te doen, zoals besproken?'
  },
  single_product: {
    case_closed:
      'ik probeerde je net te bellen, maar je bleek niet bereikbaar te zijn. Ik zal je aanvraag voor {type} verzekering zonder gevolg plaatsen. Neem gerust contact met mij op via +32 2 808 14 00 als je nog steeds geïnteresseerd bent in een {type} verzekering bij Yago.',
    offer_reminder_today:
      'ik heb je zojuist gebeld over je {type} verzekering. Mag ik je vanmiddag na 17 uur terugbellen?',
    offer_reminder_tomorrow:
      'ik heb je zojuist gebeld over je {type} verzekering. Mag ik je morgenochtend voor 11 uur terugbellen?',
    offer_when_can_we_call:
      'ik heb geprobeerd je te bereiken. Kunt je mij laten weten wanneer ik kan terugbellen om over je {type} verzekering te praten?',
    offer_when_can_we_call_multiple_attempts:
      'ik heb meerdere pogingen gedaan om je te bereiken om te praten over je {type} verzekering zonder succes. Kunt je mij laten weten wanneer ik kan terugbellen?'
  },
  management: {
    amendment_form:
      'naar aanleiding van ons gesprek, stuur ik de link naar het formulier om de contractwijziging aan te vragen: https://amendments.yago.be/form. Je kan dit ook direct doen vanuit je MyYago klantaccount via de knop "Mijn contract wijzigen".',
    redirection_ycbm_to_form:
      'we hebben je zojuist een belangrijke email gestuurd in verband met je verzoek voor een telefonische afspraak, kan je deze bekijken?'
  },
  text: 'Hallo {honorific}, {template} Bedankt, {operatorFirstName} van Yago.',
  types: {
    car: 'auto',
    house: 'woning',
    motorbike: 'motor'
  }
}
