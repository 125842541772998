import initialState from '../_helpers/initialState'
import { SET_LOADING, SET_ACCIDENT_RECORDS, ADD_ACCIDENT_RECORD, DELETE_ACCIDENT_RECORD } from './constants'
import { accidentRecords } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_ACCIDENT_RECORDS](state, payload) {
      state.data = payload
    },
    [ADD_ACCIDENT_RECORD](state, payload) {
      const index = state.data.findIndex((d) => d.uuid === payload.uuid)
      if (index !== -1) {
        state.data.splice(index, 1, payload)
      } else {
        state.data.push(payload)
      }
    },
    [DELETE_ACCIDENT_RECORD](state, payload) {
      state.data.splice(state.data.map((accidentRecord) => accidentRecord.uuid).indexOf(payload.uuid), 1)
    }
  },
  actions: {
    async fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      const { success, message, data } = await accidentRecords.index(params)
      if (success) {
        commit(SET_ACCIDENT_RECORDS, data)
        commit(SET_LOADING, false)
      }
      return { success, message }
    },
    async save({ state, commit }, { isNew, ...payload }) {
      const { success, message, data } = await (isNew
        ? accidentRecords.create(payload)
        : accidentRecords.update(payload))
      if (success) commit(ADD_ACCIDENT_RECORD, data)
      return { success, message }
    },
    async destroy({ commit }, params) {
      const { success, message } = await accidentRecords.destroy(params)
      if (success) commit(DELETE_ACCIDENT_RECORD, params)
      return { success, message }
    }
  }
}
