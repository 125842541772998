export default {
  build_year: 'Année de construction:',
  chassis_number: 'Numéro de châssis:',
  engine_displacement: 'Cylindrée:',
  entry_into_circulation: 'Entrée en circulation:',
  licence_plate: "Plaque d'immatriculation:",
  manufacturer: 'Marque:',
  missing_vehicle: 'Détails de la moto inconnu',
  model: 'Modèle:',
  motorbike_formula: 'Formule moto:',
  motorbike_type: {
    oldtimer: 'Ancêtre',
    scooter: 'Scooter',
    standard: 'Standard',
    unspecified: 'Non spécifié'
  },
  pre_tax_price: 'Prix HTVA:',
  second_hand: 'Deuxième main:',
  seniority_formula: 'Formule âge:',
  title: 'Détails de la moto',
  type: 'Type de moto:',
  usage: {
    null: '',
    private: 'Privé',
    private_and_go_to_work: 'Privé et chemin du travail',
    professional: 'Professionnel',
    unspecified: 'Non spécifié'
  }
}
