export default {
  data: {
    aedes_disclaimer:
      '⚠️ Je staat op het punt een SEPA-mandaat voor Aedes in te voeren. Voor dit bedrijf is het SEPA-mandaat gekoppeld aan een klant en niet aan een contract. Er wordt daarom meestal maar één SEPA-mandaat per klant uitgevoerd.',
    axa_disclaimer:
      "⚠️ Let op, SEPA-mandaten bij AXA zijn alleen toegestaan als de verzekeringsnemer verschilt van de rekeninghouder! Voor meer informatie zie <a href='https://seraphin.slite.com/app/channels/9m_7Hhsvyy/notes/58tHefAfzw#5b548feb'>hier</a>.",
    company_tag: 'SEPA-mandaat op naam van een rechtspersoon',
    disclaimer:
      'Merk op dat alle velden hieronder ingevuld moeten worden om de creatie van dit SEPA-mandaat te valideren',
    title: 'SEPA-mandaat voor:  '
  },
  family_plan_confirm:
    'Bevestig dat je het familieplan en budgetplan in MyBaloise hebt aangemaakt en dat de contracten jaarlijks zijn uitgegeven. Door de domiciliëring te ondertekenen, zal de klant maandelijkse kunnen betalen.',
  new_sepa_mandate: '💳 Nieuw SEPA-mandaat',
  title: 'SEPA-mandaten',
  baloise_splitting_disclaimer: {
    monthly:
      'De betalingssplitsing is maandelijks, er moet een budgetplan op MyBaloise voor dit contract worden gemaakt.',
    trimestrial:
      'De splitsing is per kwartaal, het SEPA-mandaat zal worden verstuurd voor een kwartaaldomiciliatie. Als dit een fout is, wijzig dan METEEN het contract voordat je het mandaat aanmaakt.',
    yearly:
      'De splitsing is jaarlijks, het SEPA-mandaat zal worden verstuurd voor een jaarlijkse domiciliatie. Als dit een fout is, wijzig dan METEEN het contract voordat je het mandaat aanmaakt.'
  }
}
