export default {
  meta: {
    loading: false,
    success: false,
    error: ''
  },
  unpaids: 0,
  terminations: 0,
  sepaMandates: 0,
  transfers: 0,
  wins: 0,
  contracts: 0,
  carContracts: 0,
  motorbikeContracts: 0,
  independentContracts: 0,
  houseContracts: 0,
  houseRentalContracts: 0,
  personalContracts: 0,
  travelContracts: 0,
  pensionContracts: 0,
  bicycleContracts: 0,
  vanContracts: 0,
  familyContracts: 0,
  logins: 0,
  smsMessages: 0,
  bugs: 0,
  amendments: 0,
  stoppedContracts: 0,
  accidentRecords: 0
}
