export default {
  meta: {
    loading: false,
    success: false,
    actions: {},
    error: ''
  },
  availableActions: [],
  chargebeeSubscriptionId: null,
  contract_document_status: null,
  contract_number: null,
  coupling: {
    coupled: null,
    formula: null,
    role: null
  },
  covers: [],
  company: null,
  details: null,
  documents: {
    unsigned: null,
    signed: null
  },
  grossPremium: null,
  insurer: {
    id: null,
    slug: null
  },
  origin: null,
  policyStartDate: null,
  productReference: null,
  splitting: null,
  status: null,
  termination: {
    insurer: null,
    contract_number: null,
    policy_end_date: null,
    price_increase: false
  },
  timestamps: {
    validated_at: 0,
    verified_at: 0,
    rejected_at: 0,
    detailed_at: 0,
    paid_at: 0
  },
  user: {
    name: null,
    uuid: null
  },
  uuid: null,
  wonAt: null
}
