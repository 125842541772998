import API from '@/services/api'

export const bundledPayment = {
  post({ data = {}, notifyUser }) {
    return API.post('/sw/bundled_payments/', { data, notifyUser })
  },
  put({ uuid, data = {}, notifyUser }) {
    return API.put(`/sw/bundled_payments/${uuid}`, { data, notifyUser })
  },
  archive({ uuid, data = {}, notifyUser }) {
    return API.post(`/sw/bundled_payments/${uuid}/archive`, { data, notifyUser })
  }
}
