export default {
  data: {
    aedes_disclaimer:
      "⚠️ Vous êtes sur le point d'encoder un mandat SEPA auprès de Aedes. Pour cette compagnie, le mandat SEPA est relié à un client et non pas à un contrat. On ne fait donc généralement qu'un seul mandat SEPA par client.",
    axa_disclaimer:
      "⚠️ Attention, les mandats SEPA chez AXA ne sont autorisés que dans le cas ou le preneur d'assurance est différent du titulaire du compte bancaire! Pour plus d'info voir <a href='https://seraphin.slite.com/app/channels/9m_7Hhsvyy/notes/58tHefAfzw#5b548feb'>ici</a>.",
    company_tag: "Mandat SEPA au nom d'une personne morale",
    disclaimer: 'Notez que tous les champs ci-dessous doivent être remplis pour valider la création de ce mandat SEPA',
    title: 'Mandat SEPA pour:  '
  },
  family_plan_confirm:
    'Veuillez confirmer que vous avez bien créé le plan famille et plan budget dans MyBaloise et que les contrats ont bien été émis en annuel. Le client, en signant la domiciliation, aura alors accès à un paiement mensuel.',
  new_sepa_mandate: '💳 Nouveau Mandat SEPA',
  title: 'Mandats SEPA',
  baloise_splitting_disclaimer: {
    monthly: 'Le fractionnement du contrat est mensuel, il faut réaliser un plan budget sur MyBaloise pour ce contrat.',
    trimestrial:
      'Le fractionnement est trimestriel, le mandat SEPA sera envoyé pour une domiciliation trimestrielle. Si c’est une erreur, merci de modifier directement le contrat.',
    yearly:
      "Le fractionnement est annuel, le mandat SEPA sera envoyé pour une domiciliation annuelle. Si c'est une erreur, merci de modifier directement le contrat AVANT de créer le mandat SEPA."
  }
}
