export default {
  accidents: {
    add: 'Ajouter un sinistre',
    at_fault: 'Sinistre en <b>tort</b>',
    finalized: {
      false: 'pas encore finalisé',
      true: 'finalisé'
    },
    not_at_fault: 'Sinistre en <b>droit</b>',
    radio: {
      at_fault: 'En tort',
      not_at_fault: 'En droit',
      undetermined: 'Non déterminé'
    },
    remove: 'Enlever le sinistre',
    undetermined: 'Sinistre à responsabilité <b>indéterminée</b>'
  },
  delete: 'Supprimer',
  edit: "Modifier l'attestation de sinistralité",
  five_latest_years: "{accidentRecordYearUploadedTotal} ans d'attestations uploadées pour les 5 dernières années ",
  label: {
    accident_record: '✅ Sinistralité',
    sworn_statement: "⚠️ Sur l'honneur"
  },
  new_accident_record: 'Encoder une attestation de sinistralité',
  new_sworn_statement: "Encoder une attestation sur l'honneur",
  no_accident: 'Pas de sinistre sur la période',
  no_attestation: '<b>Attestation manquante pour cette période ({diffInMonth} mois)</b>',
  request: {
    title: 'Demander attestations de sinistralité au client',
    yearsInsured: {
      contract: "Combien d'années de sinistralité prouvées a été indiqué dans le contrat ?",
      missing: "Combien d'années sont manquantes ?",
      proved: "Combien d'années sont déjà prouvées ?"
    }
  }
}
