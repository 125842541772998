<template>
  <el-footer>{{ $t('layout.footer', { year: currentYear }) }}</el-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
