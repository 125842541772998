import API from '@/services/api'

export const insurers = {
  index() {
    return API.get('/insurers')
  },
  update(data) {
    return API.put(`/insurers/${data.id}`, data)
  }
}
