<template>
  <div id="app">
    <div v-if="displayLoginPage">
      <el-container class="full-height">
        <Login></Login>
      </el-container>
    </div>
    <div v-else>
      <el-container class="full-height">
        <AppMenu></AppMenu>
        <el-container class="is-vertical">
          <AppHeader></AppHeader>
          <el-main style="padding: 50px" data-test="nav_main">
            <router-view :key="$route.path"></router-view>
          </el-main>
          <AppFooter></AppFooter>
        </el-container>
      </el-container>
    </div>
  </div>
</template>

<script>
import { isLoggedIn, operatorEmail } from '@/services/auth'
import AppMenu from './components/shared/AppMenu'
import AppHeader from './components/shared/AppHeader'
import AppFooter from './components/shared/AppFooter'

import Login from './pages/Login'
import { mapActions } from 'vuex'
import * as Sentry from '@sentry/vue'

export default {
  name: 'App',
  components: {
    AppMenu,
    AppHeader,
    AppFooter,
    Login
  },
  computed: {
    displayLoginPage() {
      return this.$route.name !== 'Callback' && !isLoggedIn() && this.routerInitialized()
    }
  },
  methods: {
    ...mapActions('operator', ['fetchOperator']),
    ...mapActions('operators', ['fetchOperators']),
    routerInitialized() {
      return this.$route.name !== undefined
    }
  },
  created() {
    document.title = import.meta.env.VUE_APP_TITLE
    Sentry.setUser({ email: operatorEmail() })
  },
  async mounted() {
    await this.fetchOperator({ email: operatorEmail() })
    await this.fetchOperators()
  }
}
</script>

<style lang="scss">
@import '@/style/main.scss';
</style>
