export default {
  meta: {
    loading: false
  },
  availableActions: [],
  category: null,
  description: null,
  details: {
    data: {
      newMotorbikeVehicle: {
        alarmInstalled: null,
        chassisNumber: null,
        engineDisplacement: null,
        entryIntoCirculation: null,
        modelLabel: null,
        motorbikeManufacturer: null,
        motorbikeType: null,
        preTaxPrice: null,
        secondHand: null,
        usage: null
      },
      oldMotorbikeVehicle: {
        alarmInstalled: null,
        chassisNumber: null,
        engineDisplacement: null,
        entryIntoCirculation: null,
        modelLabel: null,
        motorbikeManufacturer: null,
        motorbikeType: null,
        preTaxPrice: null,
        secondHand: null,
        usage: null
      }
    },
    requestDescription: null,
    product: null,
    productCategory: null,
    subcategory: null
  },
  documents: {
    signed: null,
    unsigned: null
  },
  enforcedAt: null,
  grossPremiumChange: false,
  number: null,
  operatorEmail: null,
  status: {
    label: null,
    value: null
  },
  uuid: null
}
