export default {
  buttons: {
    add_contract: 'Een contract aan het voorstel toevoegen',
    copy_clipboard: 'Kopieer de link',
    eligible: 'In aanmerking voor complete klant',
    generate_mail: 'Maak e-mail aan',
    not_eligible: 'Niet in aanmerking voor complete klant',
    reactivate: 'Het voorstel opnieuw activeren',
    request_review: 'Vraag een review aan',
    submit_argument: 'Dien de argumentatie in',
    view_offer: 'Bekijk het multi-contractvoorstel'
  },
  client_comment_default_text:
    'Als volledige klant bij Yago, geen vrijstelling voor woning en omnium auto voor 1 claim per jaar (als je schade hoger is dan het bedrag van de vrijstelling)',
  labels: {
    client_argument: 'Sales argumenten',
    client_comment_textarea: 'Sales argumenten (voordelen complete klant) 💬',
    email: 'E-mail verstuurd naar de klant'
  },
  messages: {
    comment_added: 'Argumentatie toegevoegd aan het aanbod',
    comment_deleted: 'Argumentatie verwijderd uit het aanbod'
  },
  tags: {
    accepted: 'Multi-contractvoorsel geaccepteerd door de klant!',
    incomplete:
      'Let op, dit voorstel is niet compleet. Controleer de aanvangsdata, premies, producten en klantcommentaren!',
    email: 'offer-fpb'
  },
  tooltips: {
    client_comment:
      'Voorwaarden om te profiteren van de complete klantvoordelen van de maatschappijen: <br><b>(1)</b> een auto, <b>(2)</b> een huis en <b>(3)</b> een familiale verzekering hebben'
  }
}
