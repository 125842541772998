export const SET_LOADING = 'SET_LOADING'
export const SET_CONTRACT = 'SET_CONTRACT'

export const ONGOING_PURCHASE = [
  'waiting_for_selection',
  'waiting_for_completion',
  'waiting_for_confirmation',
  'waiting_for_payment',
  'waiting_for_validation',
  'waiting_for_verification',
  'waiting_for_details_and_verification',
  'waiting_for_validation_and_verification',
  'waiting_for_details'
]
export const WAITING_FOR_SIGNATURE = 'waiting_for_signature'
export const PROCESSED = 'processed'
export const ARCHIVED = 'archived'
export const FINISHED = ['cancelled', 'terminated', 'refunded', 'suspended', 'voided']
