import API from '@/services/api'

export const lifeProcedures = {
  index(params) {
    const userUuid = params ? params.user_uuid : ''
    return API.get(`/life-procedures/${userUuid}`)
  },
  create(data) {
    return API.post('/life-procedures', data)
  },
  update(data) {
    return API.put('/life-procedures', data)
  }
}
