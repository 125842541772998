export default {
  categories: {
    belonging_change: "Changement d'objet de risque",
    cover_change: 'Changement de garantie(s)',
    encoding_correction: "Correction d'encodage",
    encoding_correction_client: "Correction d'encodage (Client)",
    encoding_correction_insurer: "Correction d'encodage (Assureur)",
    encoding_correction_yago: "Correction d'encodage (Yago)",
    encoding_correction_seraphin: "Correction d'encodage (Yago)", // to catch cases with legacy-status amendments
    exclusive_driver_clause: 'Ajout de la clause conducteur exclusif',
    gross_premium_change: 'Changement de la prime',
    other: 'Autre',
    policy_owner_data_change: 'Changement coordonnées',
    premium_change: 'Changement de la prime' // to catch cases with legacy-status amendments
  },
  contractually_frozen:
    "Ce client a d'autres contrats, veillez a donc bien enregistrer des avenants pour chaque contrat impacté par le changement.",
  description: 'Description:',
  description_placeholder:
    "Décrire ici en quelques phrases l'objet de l'avenant, afin que le client comprenne à quel changement dans son contrat cet avenant fait référence. Ce texte sera affiché dans l'espace perso ainsi que dans le Devoir d'Information; attention donc au soin apporté à la correctitude et l'orthographe de cette description.",
  details: {
    description: 'Demande initiale du client:',
    elements: {
      alarm_installed: 'Alarme:',
      birthdate: 'Date de naissance:',
      chassis_number: 'Numéro de chassis:',
      city: 'Ville:',
      engine_displacement: 'Cylindrée:',
      entry_into_circulation: 'Entrée en circulation:',
      first_name: 'Prénom:',
      is_company: 'Société:',
      last_name: 'Nom de famille:',
      legal_name: "Nom légal de l'entreprise:",
      licence_plate: "Plaque d'immatriculation:",
      model_label: 'Modèle:',
      motorbike_manufacturer: 'Marque:',
      motorbike_type: 'Type:',
      owner_title: "Fonction au sein de l'entreprise",
      pre_tax_price: 'Prix HTVA:',
      second_hand: 'Seconde main:',
      street_box: 'Boîte:',
      street_name: 'Rue:',
      street_number: 'Numéro:',
      usage: 'Usage:',
      vat_number: 'Numéro de TVA:',
      zip_code: 'Code postal:'
    },
    new_motorbike_vehicle: 'Nouveau véhicule',
    new_policy_owner: "Nouveau preneur d'assurance",
    old_motorbike_vehicle: 'Ancien véhicule',
    old_policy_owner: "Ancien preneur d'assurance"
  },
  edit: "Modifier l'avenant",
  forms: {
    select_options: {
      categories: [
        {
          label: 'Changement coordonnées',
          value: 'policy_owner_data_change'
        },
        {
          label: 'Changement de la prime',
          value: 'gross_premium_change'
        },
        {
          label: "Correction d'encodage (Assureur)",
          value: 'encoding_correction_insurer'
        },
        {
          label: "Correction d'encodage (Client)",
          value: 'encoding_correction_client'
        },
        {
          label: "Correction d'encodage (Yago)",
          value: 'encoding_correction_yago'
        },
        {
          label: "Changement d'objet de risque",
          value: 'belonging_change'
        },
        {
          label: 'Changement de garantie(s)',
          value: 'cover_change'
        },
        {
          label: 'Autre',
          value: 'other'
        }
      ]
    },
    warning: "Instructions et précautions d'usage (À lire attentivement avant première utilisation du formulaire)"
  },
  broker_billing_warning: {
    title: 'Le contrat est en encaissement YAGO',
    text: 'Si vous réalisez un avenant qui change la prime d’assurance, vous allez devoir émettre une nouvelle facture encaissement'
  },
  gross_premium_change_warning:
    'Cet avenant entraine un changement de prix. Il ne peut donc pas être validé automatiquement. Merci de consulter la team IT pour traiter cette situation.',
  instructions:
    "Ce formulaire permet la creation d'un nouvel avenant ou la modification d'un avenant existant. Lors de la creation d'un nouvel avenant, le client sera notifié par e-mail (uniquement lors de la creation et non lors de la modification de l'avenant). Dans cet email, il sera invité à aller signer son avenant en ligne. Le document uploadé par l'opérateur back office sera complété par un devoir d'information généré automatiquement par la plateforme. Ce dernier contiendra entre-autres le numéro de l'avenant, sa date de prise d'effet ainsi que la description. Il est à noter qu'un avenant ne sera modifiable que tant que celui-ci n'a pas encore été signé par le client. En cas de modification, il n'est pas nécessaire de re-uploader un fichier si celui-ci n'a pas changé.",
  modifications_guidance:
    "Un avenant implique la plupart du temps des modifications au niveau du contrat,<br>des objets de risque et/ou du profil client. Merci d'enregistrer ces modifications via<br>les bouttons ci-dessous avant d'enregistrer l'avenant.",
  no_validation_warning:
    "Cet avenant n'est pas lié à une moto Baloise, il ne peut donc pas être validé automatiquement. Merci de consulter la team IT pour traiter cette situation.",
  see_amendment: "Voir l'avenant",
  title: 'Avenants'
}
